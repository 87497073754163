import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

const Select = styled.select`
  line-height: 40px;
  padding-left: 7px;
  height: 40px;
  font-size: 12px;
  background-color: ${color.white};
  color: ${color.blueGray};
  width: 100%;
  border: 1px solid ${color.grayDark};
  border-radius: 5px;
  outline: none;
  appearance: none;
  cursor: pointer;
  background-image: linear-gradient(45deg, transparent 50%, ${color.gray} 50%),
    linear-gradient(135deg, ${color.gray} 50%, transparent 50%),
    linear-gradient(${color.white}, ${color.white}, ${color.white});
  background-position: calc(100% - 20px) calc(1em + 4px),
    calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;

export default ({ options, ...props }) => {
  return (
    <Select {...props}>
      {options.map((o, i) => (
        <option key={i} value={o.value}>
          {o.label}
        </option>
      ))}
    </Select>
  );
};

// options sample
// options = [
//   { label: "選択肢1", value: "1" },
//   { label: "選択肢2", value: "2" }
// ]
