import React from "react";
import { Link, withRouter } from "react-router-dom";

import route from "../../utils/route";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Note from "../atoms/Note";
import InputLabel from "../molecules/InputLabel";

class StaffShowAgency extends React.Component {
  state = { name: "", email: "" };

  componentDidMount() {
    const agency = this.props.location.state.agency;
    this.setState({ name: agency.name, email: agency.email });
  }

  render() {
    const { name, email } = this.state;

    return (
      <Wrap padding="20px">
        <Wrap width="600px" margin="auto">
          <Wrap margin="0 0 15px">
            <Link to={route.agencyIndex}>
              <Button>一覧へ</Button>
            </Link>
          </Wrap>
          <TitleBox title="代理店情報">
            <Wrap margin="auto" width="70%">
              <InputLabel label="代理店名" name="name" value={name} disabled />
              <InputLabel
                label="メールアドレス"
                name="email"
                value={email}
                disabled
              />
            </Wrap>
            <Note style={{ marginTop: 20, textAlign: "center" }}>
              代理店アカウントの削除をご希望の方は運営までお問い合わせください。
            </Note>
          </TitleBox>
        </Wrap>
      </Wrap>
    );
  }
}

export default withRouter(StaffShowAgency);
