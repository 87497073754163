import React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";

import mousedownEventListener from "../../utils/mousedownEventListener";

const BlackOverlay = styled.div`
  width: calc(100vw - 200px);
  height: calc(100vh - 55px);
  background: ${color.blackGradient};
  position: fixed;
  top: 55px;
  left: 200px;
  justify-content: center;
  align-items: center;
  z-index: 10;

  display: ${(props) => (props.show ? "flex" : "none")};
`;

export default ({ show, title, children, close, scroll }) => {
  const ref = React.useRef();
  const noAction = () => false;
  mousedownEventListener(ref, close || noAction);

  return (
    <BlackOverlay show={show} ref={ref} onClick={() => close && close}>
      <Wrap
        width="90%"
        height="90%"
        onClick={(e) => e.stopPropagation()}
      >
        <TitleBox title={title}>
          <Wrap style={{ overflow: scroll && "scroll" }}>
            {children}
          </Wrap>
        </TitleBox>
      </Wrap>
    </BlackOverlay>
  );
};
