export default (content, maxLength) => {
  if (content.length < maxLength) {
    return content;
  }

  const ommittedContent = content
    .split("")
    .filter((_, i) => i < maxLength)
    .join("");

  return `${ommittedContent}...`;
};
