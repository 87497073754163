import React from "react";
import styled from "styled-components";

import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";

const ProgressWrap = styled.div`
  width: calc(100vw - 200px);
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 7px 7px 0 0;
  position: fixed;
  bottom: 0;
  z-index: 10;
`

export default ({ children }) => {
  return (
    <ProgressWrap>
      <Wrap height="100%" justifyContent="center" alignItems="center">
        <Typography color="white"> {children} </Typography>
      </Wrap>
    </ProgressWrap>
  )
}
