import React from "react";
import _ from "lodash";
import Contacts from "../templates/Contacts";
import api from "../../api";

export default class extends React.Component {
  state = { contacts: [], loading: true };

  fetchContacts = async () => {
    const { contacts } = this.state;
    const createdAt =
      contacts.length > 0 ? contacts.slice(-1)[0].created_at : "";
    const res = await api.contact.index(createdAt).catch(e => console.log(e));

    Array.prototype.push.apply(contacts, res.contacts);

    this.setState({ contacts, loading: false });
  };

  componentDidMount() {
    this.fetchContacts();
    window.addEventListener("scroll", this.handleScroll, true);
  }

  handleScroll = _.throttle(
    e =>
      e.path[0].scrollHeight - window.innerHeight < e.path[0].scrollTop &&
      this.fetchContacts(),
    500
  );

  render() {
    return <Contacts {...this.state} />;
  }
}
