import React from "react";

import api from "../../api";
import { isAgency } from "../../utils/localStorage";

import SettingStore from "../templates/SettingStore";

export default class extends React.Component {
  state = {
    id: "",
    name: "",
    email: "",
    tel: "",
    accessKey: "",
    message: ""
  };

  componentDidMount() {
    this.fetchStore();
    isAgency() && this.fetchAccessKey();
  }

  fetchStore = async () => {
    const store = await api.stores.current().catch(e => {
      console.log(e);
    });

    this.setState({
      id: store.id,
      name: store.name,
      email: store.email,
      tel: store.tel
    });
  };

  fetchAccessKey = async () => {
    const { access_key } = await api.agencies
      .accessKey()
      .catch(e => console.log(e));
    this.setState({ accessKey: access_key });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  update = () => {
    const { name, email, tel } = this.state;
    api.stores
      .update(name, email, tel)
      .then(() => this.setState({ message: "更新しました。" }))
      .catch(e => {
        console.log(e);
        this.setState({ message: "更新に失敗しました。" });
      });
  };

  render() {
    return (
      <SettingStore
        {...this.state}
        onChange={this.onChange}
        update={this.update}
      />
    );
  }
}
