import React from 'react';

import TitleBox from '../atoms/TitleBox';
import Wrap from '../atoms/Wrap';
import Button from '../atoms/Button';
import { Notice } from '../atoms/Notice';
import SeoTagForms from './SeoTagForm';


export default ({
  submit,
  messages,
  ...props
}) => {
  
  return (
    <Wrap padding='20px'>
      <Wrap width='600px' margin='auto'>
        {
          messages.map(msg => {
            return (
              <Notice
                color={msg.isSuccess ? 'green' : 'red'}
                margin='0 0 10px 0'
              >
                {msg.content}
              </Notice>)
          })
        }
        <TitleBox title='OGPタグデフォルト値設定'>
          <Wrap justifyContent='flex-end'>
            <Button onClick={submit}>更新</Button>
          </Wrap>
          <SeoTagForms 
            {...props}
          />
        </TitleBox>
      </Wrap>
    </Wrap>
  )
}