import React from "react";
import api from "../../api";

import MediaIndex from "../templates/MediaIndex";

export default class extends React.Component {
  state = {
    media: [],
    loading: true,
    paginate: {
      total: 0,
      active: 1
    }
  };

  componentDidMount = async () => {
    const { paginate } = this.state;
    const res = await api.media.index().catch(e => {
      e.json().then(err => console.log(err));
    });
    const [media, total] = [res.images, res.total];
    this.setState({ media, loading: false, paginate: { ...paginate, total } });
  };

  handleClickPagenate = async pageCount => {
    const { paginate } = this.state;
    const res = await api.media.index(pageCount).catch(e => {
      e.json().then(err => console.log(err));
    });
    const media = res.images;
    this.setState({ media, paginate: { ...paginate, active: pageCount } });
  };

  handleClickDelete = async id => {
    const { active } = this.state.paginate;

    await api.media
      .delete(id)
      .then(() => console.log("ok"))
      .catch(e => console.log(e));
    this.handleClickPagenate(active);
  };

  render() {
    const { media } = this.state;

    return (
      <MediaIndex
        mediaPaginated={media}
        {...this.state}
        handleClickPagenate={this.handleClickPagenate}
        handleClickDelete={this.handleClickDelete}
      />
    );
  }
}
