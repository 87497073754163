import React from "react";

import route from "../../utils/route";
import api from "../../api";

import Notification from "../templates/Notification";

export default class extends React.Component {
  state = {
    title: "",
    content: "",
    errorMessage: ""
  };

  create = () => {
    const { title, content } = this.state;

    if (!title) {
      return this.setState({ errorMessage: "タイトルを入力してください" });
    }
    if (!content) {
      return this.setState({ errorMessage: "本文を入力してください" });
    }

    console.log(title, content);
    api.notification
      .create(title, content)
      .then(() => {
        window.location.href = route.notificationsIndex;
      })
      .catch(e => {
        console.error(e);
      });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <Notification
        {...this.state}
        create={this.create}
        handleChange={this.handleChange}
        pageType="new"
      />
    );
  }
}
