import React from "react";

import api from "../../api";

import PostIndex from "../templates/PostIndex";

export default class extends React.Component {
  state = {
    articles: [],
    templateExist: false,
    loading: true,
    showModal: false,
    showApplyTemplateModal: false,
  };

  fetchTemplate = () => {
    api.template
      .show()
      .then(template => {
        template.article_template.content &&
          this.setState({ templateExist: true, loading: false });
      })
      .catch(e => {
        console.error(e);
        this.setState({ loading: false });
      });
  };

  fetchArticles = () => {
    api.article
      .index()
      .then(articles => {
        console.log(articles);
        this.setState({
          articles: articles && articles.articles
        });
      })
      .catch(e => {
        e.json().then(err => console.log(err));
      });
  };

  applyTemplateToAllPost = async () => {
    let message;
    await api.applyArticleTemplate
      .apply()
      .catch(e => {
        console.log(e)
        message = "テンプレートの適用に失敗しました"
      })
      .finally(() => {
        // すでにメッセージがある場合, エラー
        // そうでない場合は成功とみなす
        message = message || "テンプレートを適用しました"
      })

    return message;
  }

  componentDidMount() {
    this.fetchTemplate();
    this.fetchArticles();
  }

  render() {
    const { articles } = this.state;

    return (
      <PostIndex
        isArticle
        posts={articles}
        applyTemplateToAllPost={this.applyTemplateToAllPost}
        {...this.state}
      />
    );
  }
}
