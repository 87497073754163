import React from "react";

import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";
import color from "../../utils/color";

import Wrap from "../atoms/Wrap";
import LinkText from "../atoms/LinkText";
import Typography from "../atoms/Typography";
import Loading from "../atoms/Loading";

export default ({ optionName, loading }) => {
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Wrap justifyContent="center">
          <Typography>
            現在、{optionName}をご利用いただけません。
            <br />
            ご利用になるには
            <LinkText
              to={
                isAgency()
                  ? route.store(storeId()).settingSite
                  : route.settingSite
              }
              style={{ color: color.blue, textDecoration: "underline" }}>
              各種設定
            </LinkText>
            のオプション設定から有効化してください。
          </Typography>
        </Wrap>
      )}
    </>
  );
};
