import styled from "styled-components";
import color from "../../utils/color";

export default styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: ${color.white};
  padding: 0 40px;
  box-sizing: border-box;
  border-bottom: 1px solid ${color.gray};
`;
