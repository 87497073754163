import React from "react";

import agencyList from "../../utils/agencyList.json";

import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";
import Button from "../atoms/Button";
import Separator from "../atoms/Separator";
import Loading from "../atoms/Loading";
import InputLabel from "../molecules/InputLabel";
import CheckBoxLabel from "../molecules/CheckBoxLabel";

const disableCheckAccessAnalysis = false;

export default ({
  accountName,
  name,
  staffName,
  staffEmail,
  email,
  password,
  password_confirmation,
  use_sitest,
  use_recruit,
  handleChange,
  handleCheck,
  createStore,
  errorMessage,
  loading,
  loadingMessage
}) => {
  const isAgencyNew = accountName === "代理店";
  const hostname = window.location.hostname;

  return (
    <Wrap width="450px" margin="auto">
      {loading ? (
        <Loading description={loadingMessage} />
      ) : (
        <TitleBox title={`新規${accountName}作成`}>
          <form onSubmit={e => e.preventDefault()}>
            <div>
              <InputLabel
                label={`${accountName}名`}
                name="name"
                value={name}
                onChange={handleChange}
                errorMessage={errorMessage.name}
                required
              />
              {isAgencyNew && (
                <>
                  <InputLabel
                    label="代理店メールアドレス"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    errorMessage={errorMessage.email}
                    required
                  />
                  <Separator margin="30px 0" />
                </>
              )}
              <InputLabel
                label="スタッフ名"
                name="staffName"
                value={staffName}
                onChange={handleChange}
                errorMessage={errorMessage.staffName}
                required
              />
              <InputLabel
                label="スタッフメールアドレス"
                name="staffEmail"
                value={staffEmail}
                onChange={handleChange}
                errorMessage={errorMessage.staffEmail}
                required
              />
              <InputLabel
                type="password"
                name="password"
                label="パスワード"
                value={password}
                onChange={handleChange}
                errorMessage={errorMessage.password}
                required
              />
              <InputLabel
                type="password"
                name="password_confirmation"
                label="パスワード（再入力）"
                value={password_confirmation}
                onChange={handleChange}
                errorMessage={errorMessage.password_confirmation}
                required
              />
              {!isAgencyNew && (
                <div>
                  <Separator margin="30px 0" />
                  {agencyList[hostname].options.sitest && (
                    <div
                      style={{
                        display: disableCheckAccessAnalysis ? "none" : ""
                      }}>
                      <CheckBoxLabel
                        name="use_sitest"
                        checked={use_sitest}
                        label="アクセス解析機能を利用する"
                        onClick={() => handleCheck("use_sitest")}
                      />
                    </div>
                  )}
                  {agencyList[hostname].options.recruit && (
                    <CheckBoxLabel
                      name="use_recruit"
                      checked={use_recruit}
                      label="求人管理機能を利用する"
                      onClick={() => handleCheck("use_recruit")}
                    />
                  )}
                </div>
              )}
            </div>
            <Wrap justifyContent="center" margin="10px 0 0">
              <Button onClick={createStore}>作成</Button>
            </Wrap>
          </form>
        </TitleBox>
      )}
    </Wrap>
  );
};
