import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import color from "../../utils/color";
import { storeId, isAgency } from "../../utils/localStorage";
import api from "../../api";
import sidebarPath from "../../utils/sidebarPath";

import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import SidebarList from "../molecules/SidebarList";

const Nav = styled.nav`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  background-color: ${color.black};
  box-sizing: border-box;
  transition: 0.3s;
  overflow-y: scroll;
  padding-bottom: 80px;
`;

const StoreWrap = styled.div`
  color: ${color.white};
  padding: 20px 0;
  border-bottom: 1px solid ${color.typoLight};
`;

const StoreName = styled.div`
  font-size: 13px;
  font-weight: bold;
  padding: 0 20px;
`;

const StoreId = styled.div`
  color: ${color.white};
  padding: 0 20px;
  font-size: 12px;
  margin-top: 5px;
`;

const Sidebar = () => {
  const [storeName, setStoreName] = React.useState("");
  const [path, setPath] = React.useState([]);
  const [displayStoreId, setDisplayStoreId] = React.useState([]);
  const [hpDomain, setHpDomain] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchStoreAgency = async () => {
      const store = await api.stores.current().catch(e => {
        e && e.json().then(err => console.log(err));
      });

      if (store) {
        setDisplayStoreId(storeId());
        setStoreName(store.name);
        setHpDomain(store.aws_resource.hp_domain);
      }
    };

    const fetchStoreStore = async () => {
      const store = await api.user.me().catch(e => {
        e && e.json().then(err => console.log(err));
      });

      if (store) {
        setDisplayStoreId(store.affiliation.id);
        setStoreName(store.affiliation.name);
        setHpDomain(store.aws_resource.hp_domain);
      }
    };
    isAgency() ? fetchStoreAgency() : fetchStoreStore();

    const fetchSidebarPath = () => {
      const path = sidebarPath(isAgency(), storeId());
      setPath(path);
    };
    fetchSidebarPath();
    setLoading(false);
  }, []);

  return (
    <Nav>
      <StoreWrap>
        <StoreName>{storeName}</StoreName>
        <StoreId>店舗ID: {displayStoreId}</StoreId>
        {!loading && hpDomain && (
          <Wrap justifyContent="center" margin="1rem 0 0 0">
            <Button onClick={() => window.open(`https://${hpDomain}`)}>
              公開中のサイトを開く
            </Button>
          </Wrap>
        )}
      </StoreWrap>
      <ul>
        {path.map((nav, i) => (
          <SidebarList key={i} nav={nav} />
        ))}
      </ul>
    </Nav>
  );
};

export default withRouter(Sidebar);
