import React from "react";

const DateInput = ({ value, handleChange, max }) => (
  <input
    type="date"
    value={value}
    placeholder="2020-06-12"
    onChange={(e) => handleChange(e.target.value)}
    max={max}
  />
);

export default DateInput;
