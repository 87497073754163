export default {
  // === users ===
  passwordResetRequest: "/users/reset_password",
  passwordReset: "/users/reset_password/:token",
  signInAgency: "/users/sign_in/agency",
  signInStore: "/users/sign_in/store",

  // === agency ===
  passwordUpdateAgency: "/agency/password_update",
  storeIndex: "/stores/index",
  storeNew: "/stores/new",
  agencyIndex: "/agencies/index",
  agencyNew: "/agencies/new",
  agencyShow: "/agencies/show",
  settingAccountAgency: "/setting/account/agency",
  agencyStaffIndex: "/agencies/staff/index",
  agencyStaffNew: "/agencies/staff/new",
  agencyStaffShow: "/agencies/staff/show",
  notificationsIndex: "/agencies/notifications/index",
  notificationsNew: "/agencies/notifications/new",
  notificationsEdit: "/agencies/notifications/edit",

  store: (id = ":id") => ({
    dashboard: `/dashboard/store/${id}`,
    versionsIndex: `/dashboard/store/${id}/versions/index`,
    versionsNew: `/dashboard/store/${id}/versions/new`,
    versionsShow: `/dashboard/store/${id}/versions/show`,
    sourceEdit: (object_hash = ":object_hash") =>
      `/dashboard/store/${id}/source/edit/${object_hash}`,
    mediaIndex: `/dashboard/store/${id}/media/index`,
    mediaNew: `/dashboard/store/${id}/media/new`,
    articleIndex: `/dashboard/store/${id}/article/index`,
    articleEdit: `/dashboard/store/${id}/article/edit`,
    articleNew: `/dashboard/store/${id}/article/new`,
    articleCategory: `/dashboard/store/${id}/article/category`,
    articleTemplate: `/dashboard/store/${id}/article/template`,
    newsIndex: `/dashboard/store/${id}/news/index`,
    newsEdit: `/dashboard/store/${id}/news/edit`,
    newsNew: `/dashboard/store/${id}/news/new`,
    newsCategory: `/dashboard/store/${id}/news/category`,
    newsTemplate: `/dashboard/store/${id}/news/template`,
    domainCheck: `/dashboard/store/${id}/domain/check`,
    domainRegister: `/dashboard/store/${id}/domain/register`,
    settingStore: `/dashboard/store/${id}/setting/store`,
    settingSite: `/dashboard/store/${id}/setting/site`,
    contacts: `/dashboard/store/${id}/contacts`,
    formIndex: `/dashboard/store/${id}/form/index`,
    formNew: `/dashboard/store/${id}/form/new`,
    formEdit: `/dashboard/store/${id}/form/edit`,
    applicants: `/dashboard/store/${id}/applicants`,
    jobOfferIndex: `/dashboard/store/${id}/jobOffer/index`,
    jobOfferNew: `/dashboard/store/${id}/jobOffer/new`,
    jobOfferEdit: `/dashboard/store/${id}/jobOffer/edit`,
    analysis: `/dashboard/store/${id}/analysis`,
    notificationsShow: `/dashboard/store/${id}/notifications/show`,
    seoPreference: `/dashboard/store/${id}/seo_preference`,
    externalDomain: `/dashboard/store/${id}/external_domain`,
    externalDnsRecordset: `/dashboard/store/${id}/external_dns_recordset`,
    sitemap: `/dashboard/store/${id}/sitemap`
  }),

  // === store ===
  passwordUpdateStore: "/dashboard/password_update",
  dashboard: `/dashboard`,
  versionsIndex: "/dashboard/versions/index",
  versionsNew: "/dashboard/versions/new",
  versionsShow: `/dashboard/versions/show`,
  sourceEdit: (object_hash = ":object_hash") =>
    `/dashboard/source/edit/${object_hash}`,
  newsIndex: "/dashboard/news/index",
  newsEdit: "/dashboard/news/edit",
  newsNew: "/dashboard/news/new",
  newsCategory: "/dashboard/news/category",
  newsTemplate: "/dashboard/news/template",
  mediaIndex: "/dashboard/media/index",
  mediaNew: "/dashboard/media/new",
  articleIndex: "/dashboard/article/index",
  articleEdit: "/dashboard/article/edit",
  articleNew: "/dashboard/article/new",
  articleCategory: "/dashboard/article/category",
  articleTemplate: "/dashboard/article/template",
  settingAccountStore: "/dashboard/setting/account",
  settingStore: "/dashboard/setting/store",
  settingSite: `/dashboard/setting/site`,
  analysis: "/dashboard/analysis",
  contacts: "/dashboard/contacts",
  formIndex: "/dashboard/form/index",
  formNew: "/dashboard/form/new",
  formEdit: "/dashboard/form/edit",
  applicants: `/dashboard/applicants`,
  jobOfferIndex: `/dashboard/jobOffer/index`,
  jobOfferNew: `/dashboard/jobOffer/new`,
  jobOfferEdit: `/dashboard/jobOffer/edit`,
  notificationsShow: "/dashboard/notifications/show",
  seoPreference: `/dashboard/seo_preference`
};
