import React from "react";

import Wrap from "../atoms/Wrap";

import SitemapNew from "../organisms/SitemapNew";
import SitemapSubmit from "../organisms/SitemapSubmit";

export default ({
  ...props
}) => {

  return (
    <Wrap padding="20px">
      <Wrap margin="20px 0 0">
        <SitemapNew
          {...props}
        />
      </Wrap>
      <Wrap margin="20px 0 0">
        <SitemapSubmit
          {...props}
        />
      </Wrap>
    </Wrap>
  )
}
