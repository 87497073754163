import React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import { unixtimeToDate } from "../../utils/common";
import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";
import Button from "../atoms/Button";
import Textarea from "../atoms/Textarea";
import Loading from "../atoms/Loading";
import Table from "../molecules/Table";
import Modal from "../molecules/Modal";

const tableDatas = (contacts, setModal, setContact) => {
  return (
    contacts &&
    contacts.map((c, i) => [
      i + 1,
      unixtimeToDate(c.created_at),
      <Button
        onClick={() => {
          setContact(c);
          setModal(true);
        }}
      >
        受信内容を見る
      </Button>
    ])
  );
};

const mailContents = contents => {
  return contents && Object.keys(contents).map(k => `${k}: ${contents[k]}\n\n`);
};

const ShowContentModal = ({ close, contact, ...props }) => {
  return (
    <Modal title="問い合わせ内容" {...props} close={close}>
      <Textarea
        style={{ minHeight: 200 }}
        value={mailContents(contact.contents)}
        readOnly
      />
      <Wrap justifyContent="center" margin="15px 0 0">
        <Button onClick={close}>閉じる</Button>
      </Wrap>
    </Modal>
  );
};

const ContactLength = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: ${color.blue};
`;

const Unit = styled.span`
  font-size: 14px;
  margin-left: 5px;
`;

const FilterRow = ({ length }) => {
  return (
    <Wrap justifyContent="flex-start" margin="0 0 10px">
      <div>
        <ContactLength>{length}</ContactLength>
        <Unit>件</Unit>
      </div>
    </Wrap>
  );
};

export default ({ contacts, loading, type }) => {
  const [showModal, setModal] = React.useState(false);
  const [contact, setContact] = React.useState({});
  const isApplicants = type === "applicants";

  return loading ? (
    <Loading label={`${isApplicants ? "応募者" : "問い合わせ"}一覧を取得中`} />
  ) : (
    <Wrap padding="20px">
      <ShowContentModal
        show={showModal}
        close={() => setModal(false)}
        contact={contact}
      />
      <FilterRow length={contacts.length} />
      <TitleBox title={`${isApplicants ? "応募者" : "問い合わせ"}一覧`}>
        <Table
          colWidths={["20%", "auto", "30%"]}
          tableHeads={["ID", "受信日", "操作"]}
          tableDatas={tableDatas(contacts, setModal, setContact)}
        />
      </TitleBox>
    </Wrap>
  );
};
