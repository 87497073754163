export default (url, shop_id, user_id) => {
  const form  = document.createElement('form');
  form.target = 'analysis';
  form.action = url;
  form.method = 'post';

  const input1  = document.createElement('input');
  input1.type   = 'hidden';
  input1.name   = 'shop_id';
  input1.value  = shop_id;
  const input2  = document.createElement('input');
  input2.type   = 'hidden';
  input2.name   = 'user_id';
  input2.value  = user_id;

  form.appendChild(input1);
  form.appendChild(input2);
  document.body.appendChild(form);

  form.submit();
}