import route from "../utils/route";
import { storeId } from "../utils/localStorage";

export const recruitComponentPath = isAgency =>
  isAgency
    ? [
        route.store(storeId()).applicants,
        route.store(storeId()).jobOfferIndex,
        route.store(storeId()).jobOfferNew,
        route.store(storeId()).jobOfferEdit
      ]
    : [
        route.applicants,
        route.jobOfferIndex,
        route.jobOfferNew,
        route.jobOfferEdit
      ];

export const sitestComponentPath = isAgency =>
  isAgency ? [route.store(storeId()).analysis] : [route.analysis];

export const contactComponentPath = isAgency =>
  isAgency
    ? [
        route.store(storeId()).contacts,
        route.store(storeId()).formIndex,
        route.store(storeId()).formNew,
        route.store(storeId()).formEdit
      ]
    : [route.contacts, route.formIndex, route.formNew, route.formEdit];
