const errorMessages = {
  doc_seo: {
    title: "titleは50文字以内にする必要があります",
    description: "descriptionは150文字以内にする必要があります",
    keywords: "keywordsは50文字以内にする必要があります"
  },
  ogp_seo: {
    title: "og:titleは50文字以内にする必要があります",
    description: "og:descriptionは150文字以内にする必要があります"
  },
  twitter: {
    title: "twitter:titleは50文字以内にする必要があります",
    description: "twitter:descriptionは150文字以内にする必要があります"
  }
};

export default {
  getErrorMessages: (errors) => {
    let messages = [];
    Object.keys(errors).forEach( key => {
      if(key.includes('.')) {
        const [type, property] = key.split('.')
        messages.push(errorMessages[type][property])
      }
    });
    return messages;
  }
};