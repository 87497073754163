import React from "react";
import styled from "styled-components";

import color from "../../utils/color";

const ColoredBox = styled.div`
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 12px;
  border-radius: 3px;

  margin: ${props => props.margin};
  background-color: ${props => props.color.bg};
  color: ${props => props.color.font};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const colorDict = {
  green: {
    font: color.green,
    bg: color.greenLight
  },
  yellow: {
    font: color.yellowDark,
    bg: color.yellowLight
  },
  red: {
    font: color.redDark,
    bg: color.redLight
  }
};

const Notice = ({ color, margin, children }) => {
  return (
    <ColoredBox color={colorDict[color]} margin={margin}>
      {children}
    </ColoredBox>
  );
};

const DomainVerifyNotice = () => (
  <Notice color="red">
    ドメインが未設定です。ドメイン設定後にサイトを公開することができます。
  </Notice>
);

const SiteUploadingNotice = () => (
  <Notice color="green">
    サイト公開準備中です。しばらく経ってからアクセスして下さい。
  </Notice>
);

const ArticleNotice = ({ days, ...props }) => {
  return (
    <Notice color="yellow" {...props}>
      最後に記事の更新を行ってから<Bold>{days}日</Bold>が経過しています。
    </Notice>
  );
};

const NewsNotice = ({ days, ...props }) => {
  return (
    <Notice color="red" {...props}>
      最後にお知らせの更新を行ってから<Bold>{days}日</Bold>が経過しています。
    </Notice>
  );
};

const ContactNotice = ({ count, ...props }) => {
  return (
    <Notice color="yellow" {...props}>
      未読のお問い合わせが<Bold>{count}件</Bold>届いています。
    </Notice>
  );
};

export default ({ name, days, color, margin }) => {
  return (
    <ColoredBox color={colorDict[color]} margin={margin}>
      最後に{name}の更新を行ってから<Bold>{days}日</Bold>が経過しています。
    </ColoredBox>
  );
};

export {
  Notice,
  ArticleNotice,
  NewsNotice,
  ContactNotice,
  DomainVerifyNotice,
  SiteUploadingNotice
};
