import React from "react";

import api from "../../api";
import route from "../../utils/route";
import { setToken, setStoreId, token } from "../../utils/localStorage";
import { noInputCheck, validEmailCheck } from "../../utils/inputCheck";

import SignIn from "../templates/SignIn";

export default class extends React.Component {
  state = {
    email: "",
    password: "",

    errorMessage: {
      email: "",
      password: ""
    },
    userType: ""
  };

  componentDidMount() {
    if (this.props.location.pathname === route.signInAgency) {
      this.setState({ userType: "agency" });
    } else {
      this.setState({ userType: "store" });
    }
    if (token("agency")) {
      this.props.history.push(route.storeIndex);
    }
    if (token("store")) {
      this.props.history.push(route.versionsIndex);
    }
  }

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  toggleUserType = () => {
    const { userType } = this.state;
    userType === "agency"
      ? this.setState({ userType: "store" })
      : this.setState({ userType: "agency" });
  };

  setStoreData = async () => {
    const store = await api.user.me().catch(e => {
      e.json().then(err => console.log(err));
    });
    setStoreId(store.affiliation.id);
  };

  signIn = () => {
    const setErrorMessage = this.setErrorMessage;
    const { email, password, userType } = this.state;

    try {
      noInputCheck(email, setErrorMessage, "email");
      validEmailCheck(email, setErrorMessage, "email");
      noInputCheck(password, setErrorMessage, "password");
    } catch (e) {
      return false;
    }

    api.user
      .signIn(userType, email, password)
      .then(res => {
        if (userType === "agency") {
          setToken("agency", res.token);
          this.props.history.push(route.storeIndex);
        } else {
          setToken("store", res.token);
          // this.setStoreData();
          this.props.history.push(route.dashboard);
        }
      })
      .catch(e =>
        e.json().then(err => {
          const message = "メールアドレスまたはパスワードが違います。";
          this.setState({
            errorMessage: {
              email: message,
              password: message
            }
          });
          console.log(err);
        })
      );
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <SignIn
        toggleUserType={this.toggleUserType}
        handleChange={this.handleChange}
        signIn={this.signIn}
        {...this.state}
      />
    );
  }
}
