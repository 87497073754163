import React from "react";

import roles from "../../utils/roles";
import route from "../../utils/route";
import { isAgency } from "../../utils/localStorage";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import { Notice } from "../atoms/Notice";
import LinkText from "../atoms/LinkText";
import InputLabel from "../molecules/InputLabel";
import SelectBoxLabel from "../molecules/SelectBoxLabel";

export default ({ name, email, role, update, handleChange, message }) => {
  return (
    <Wrap padding="20px">
      <Wrap width="500px" margin="auto">
        {message.success && (
          <Notice margin="0 0 10px" color="green">
            {message.success}
          </Notice>
        )}
        <TitleBox title="アカウント情報設定">
          <form onSubmit={e => e.preventDefault()}>
            <InputLabel
              name="name"
              label="ユーザー名"
              onChange={handleChange}
              value={name}
            />
            <InputLabel
              name="email"
              label="メールアドレス"
              onChange={handleChange}
              value={email}
            />
            {role && (
              <SelectBoxLabel
                label="権限"
                options={roles}
                value={role}
                disabled
              />
            )}
          </form>
          <LinkText
            to={
              isAgency()
                ? route.passwordUpdateAgency
                : route.passwordUpdateStore
            }>
            パスワード変更はこちら
          </LinkText>
            <Wrap margin="20px 0 0" justifyContent="center">
              <Button onClick={update}>更新</Button>
            </Wrap>
        </TitleBox>
      </Wrap>
    </Wrap>
  );
};
