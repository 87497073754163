import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { fadeIn } from "../../utils/keyframes";
import color from "../../utils/color";
import Wrap from "../atoms/Wrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  font-size: 13px;
  cursor: pointer;
  position: relative;
  width: 300px;
  text-align: center;
  z-index: 100;
`;

const DropdownBox = styled.ul`
  width: 155px;
  height: ${props => `calc(41px * ${props.linksLength} - 1px)`};
  position: absolute;
  right: -27px;
  top: 32px;
  border: 1px solid ${color.gray};
  z-index: 10000;
`;

const InnerButton = styled.button`
  width: 100%;
  line-height: 40px;
  background-color: ${color.white};
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  animation: ${fadeIn} 0.2s;
  box-sizing: border-box;

  :hover {
    background-color: ${color.grayLight};
  }
`;

const ListItem = styled.li`
  .button {
    border: none;
  }

  :not(:last-child) {
    .button {
      border-bottom: 1px solid ${color.gray};
    }
  }
`;

const Label = styled.div`
  display: inline-block;
  word-wrap: break-word;
  width: 300px;
  text-align: right;
`;

export default ({ useRef, label, onClick, links, show }) => {
  return (
    <Container ref={useRef} onClick={onClick}>
      <Wrap alignItems="center" justifyContent="flex-end">
        <Label>{label}</Label>
        <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: 10 }} />
        {links && show && (
          <DropdownBox linksLength={links.length}>
            {links.map((l, i) => (
              <ListItem key={i} onClick={l.logout}>
                <Link to={l.path}>
                  <InnerButton className="button">{l.label}</InnerButton>
                </Link>
              </ListItem>
            ))}
          </DropdownBox>
        )}
      </Wrap>
    </Container>
  );
};
