import React from "react";
import styled from "styled-components";
import _ from "lodash";
import color from "../../utils/color";

import Wrap from "../atoms/Wrap";

const PageText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 10px 0;
  color: ${props => (props.active ? color.white : color.blueGray)};
  border: 1px solid ${color.gray};
  cursor: pointer;
  background-color: ${props =>
    props.active ? color.blue : props.arrow ? color.grayLight : color.white};
`;

export default ({ total, active, onClick }) => {
  return (
    <Wrap justifyContent="center" style={{ display: !total && "none" }}>
      <PageText arrow onClick={() => onClick(1)}>
        ≪
      </PageText>
      <PageText
        arrow
        onClick={() => onClick(active === 1 ? active : active - 1)}>
        {"<"}
      </PageText>
      {_.times(total, index => {
        const pageCount = index + 1;
        return (
          <PageText
            key={index}
            onClick={() => onClick(pageCount)}
            active={pageCount === active}>
            {pageCount}
          </PageText>
        );
      })}
      <PageText
        arrow
        onClick={() => onClick(active === total ? active : active + 1)}>
        {">"}
      </PageText>
      <PageText arrow onClick={() => onClick(total)}>
        ≫
      </PageText>
    </Wrap>
  );
};
