import React from "react";
import LargeModal from "../molecules/LargeModal";

import Iframe from "../atoms/Iframe";
import Button from "../atoms/Button";

const PostTypeEnum = {
  article: "ARTICLE",
  news: "NEWS"
}

const getDetailClass = typeEnum => {
  return `CMS-${typeEnum}-DETAIL`
}
const getTitleClass = typeEnum => {
  return `CMS-${typeEnum}-TITLE`
}

const getContentClass = typeEnum => {
  return `CMS-${typeEnum}-CONTENT`
}


const generatePostHtmlElement = (postType, template, title, content) => {
  const detailClass = getDetailClass(postType);
  const titleClass = getTitleClass(postType);
  const contentClass = getContentClass(postType);

  const domParser = new DOMParser()
  let postHTMLDocument = domParser.parseFromString(template, "text/html");
  let details = postHTMLDocument.getElementsByClassName(detailClass);
  [...details].forEach( detail => {
    let titles = detail.getElementsByClassName(titleClass)
    Array.from(titles).forEach( titleDom => {
      titleDom.innerText = title
    });
    let contents = detail.getElementsByClassName(contentClass)
    Array.from(contents).forEach( contentDom => {
      contentDom.innerHTML = content
    });
  });

  return postHTMLDocument.documentElement.outerHTML;
}

export const ArticlePreviewModal = ({ show, closeHandler, template, title, contentString }) => {
  const htmlElement = generatePostHtmlElement(PostTypeEnum.article, template, title, contentString);
  return (
    <LargeModal title="プレビュー" show={show}>
      <Button onClick={closeHandler}>閉じる</Button>
      <Iframe srcDoc={htmlElement}/>
    </LargeModal>
  )
}

export const NewsPreviewModal = ({ show, closeHandler, template, title, contentString }) => {
  const htmlElement = generatePostHtmlElement(PostTypeEnum.news, template, title, contentString);

  return (
    <LargeModal title="プレビュー" show={show}>
      <Button onClick={closeHandler}>閉じる</Button>
      <Iframe
        srcDoc={htmlElement}
      />
    </LargeModal>
  )
}
