import React from "react";
import Iframe from "../atoms/Iframe";

export default ({ html }) => {
  return (
    <Iframe
      title="サイトプレビュー"
      id="iframe"
      srcDoc={html}
      onBlur={e => {
        console.log(e.currentTarget.textContent);
      }}
    ></Iframe>
  );
};
