import React from "react";
import { Link } from "react-router-dom";

import api from "../../api";
import route from "../../utils/route";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import Loading from "../atoms/Loading";
import IconButton from "../atoms/IconButton";
import AccountIndex from "../templates/AccountIndex";

export default class extends React.Component {
  state = {
    agencies: [],
    loading: true,
    paginate: {
      total: 0,
      active: 1
    }
  };

  fetchAgencies = async () => {
    const { paginate } = this.state;
    const res = await api.agencies.index().catch(e => {
      console.log(e);
    });

    const total = res && res.total;

    const agencies =
      res &&
      res.agencies.map(agency => [
        agency.id,
        agency.name,
        agency.email,
        new Date(agency.updated_at).toLocaleDateString(),
        <Link
          to={{
            pathname: route.agencyShow,
            state: { agency }
          }}>
          <IconButton icon={faEye} tip="この代理店の情報を見る" />
        </Link>
      ]);

    this.setState({
      agencies,
      loading: false,
      paginate: { ...paginate, total }
    });
  };

  componentDidMount = () => {
    this.fetchAgencies();
  };

  handleClickPagenate = async pageCount => {
    const { paginate } = this.state;
    const res = await api.agencies.index(pageCount).catch(e => {
      console.log(e);
    });

    const agencies =
      res &&
      res.agencies.map(agency => [
        agency.id,
        agency.name,
        agency.email,
        new Date(agency.updated_at).toLocaleDateString(),
        <Link
          to={{
            pathname: route.agencyShow,
            state: { agency }
          }}>
          <IconButton icon={faEye} tip="この代理店の情報を見る" />
        </Link>
      ]);

    this.setState({ agencies, paginate: { ...paginate, active: pageCount } });
  };

  render() {
    const { agencies, loading, paginate } = this.state;

    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <AccountIndex
            accountType="agency"
            colWidths={["50px", "auto", "auto", "auto", "80px"]}
            tableHeads={[
              "代理店ID",
              "代理店名",
              "メールアドレス",
              "最終更新日",
              ""
            ]}
            tableDatas={agencies}
            paginate={paginate}
            handleClickPagenate={this.handleClickPagenate}
          />
        )}
      </>
    );
  }
}
