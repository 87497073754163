import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

const TableBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  background-color: ${color.white};

  height: ${props => props.scrollY && "600px"};
  overflow-y: ${props => props.scrollY && "scroll"};
`;

const Table = styled.table`
  border-collapse: collapse;

  th,
  td {
    text-align: left;
    white-space: nowrap;
    font-size: ${props => (props.small ? "12px" : "13px")};
  }
  th {
    border-top: 2px solid ${color.gray};
    border-bottom: 2px solid ${color.gray};
    padding: ${props => (props.small ? "5px 10px" : "15px 10px")};
  }

  td {
    border-top: 1px solid ${color.gray};
    padding: 14px 10px;
    height: ${props => (props.small ? "auto" : "45px")};
  }

  tbody tr {
    transition: 0.2s;

    :nth-child(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;

export default ({ colWidths, tableHeads, tableDatas, scrollY, small }) => {
  return (
    <TableBox scrollY={scrollY}>
      <Table style={{ width: "100%" }} small={small}>
        {colWidths && (
          <colgroup>
            {colWidths.map((width, i) => (
              <col key={i} style={{ width }} />
            ))}
          </colgroup>
        )}
        <thead>
          <tr>
            {tableHeads && tableHeads.map((h, i) => <th key={i}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {tableDatas &&
            tableDatas.map((r, i) => (
              <tr key={i}>{r && r.map((d, j) => <td key={j}>{d}</td>)}</tr>
            ))}
        </tbody>
      </Table>
    </TableBox>
  );
};
