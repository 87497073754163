import React from "react";
import color from "../../utils/color";
import styled from "styled-components";
import InputLabel from "../molecules/InputLabel";
import Typography from "../atoms/Typography";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import TextareaLabel from "../molecules/TextareaLabel";

const Heading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  font-weight: bold;
`;

const makeTrackingCode = (propertyId) => {
  const trackingCode = 
`<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=${propertyId}"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${propertyId}');
</script>
`
  return trackingCode
}

export default ({gaResource, createGaProject}) => {
  return (
    <Wrap margin="30px auto">
      <Wrap margin="20px auto">
        <Heading>Google Analytics</Heading>
      </Wrap>
      { gaResource ? (
        <>
          <InputLabel
            label="view id"
            value={gaResource.view_id}
            readOnly
          />
          <InputLabel
            label="account id"
            value={gaResource.account_id}
            readOnly
          />
          <InputLabel
            label="property id"
            value={gaResource.property_id}
            readOnly
          />
          <TextareaLabel
            label="トラッキングコード"
            value={makeTrackingCode(gaResource.property_id)}
            readOnly
          />
          <Typography>データを収集したいページのhead内の一番に貼り付けてください</Typography>
        </>
      ) : (
        <Wrap margin="20px auto">
          <Typography color={color.red}>Google Analyticsによるサイト解析を行うにはGAプロジェクトを作成する必要があります</Typography>
          <Wrap justifyContent="center" margin="20px auto">
            <Button onClick={createGaProject}>GAプロジェクトを作成</Button>
          </Wrap>
        </Wrap>
      )}
    </Wrap>
  )
}