import React from "react";

import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";
import Typography from "../atoms/Typography";

import color from "../../utils/color";
import Button from "../atoms/Button";
import Loading from "../atoms/Loading";
import Modal from "../molecules/Modal"

const textColor = success => {
  return success ? color.green : color.red
}

export default ({
  submitLoading,
  submitted,
  submit,
  isShownSubmitModal,
  closeModal
}) => {
  const submitResultModal = () => {
    const submitMessageText = submitted
      ? `サイトマップをGoogleSearchConsoleに提出しました．`
      : `サイトマップのGoogleSearchConsoleへの提出に失敗しました．以下を確認してください.`

    const submitMessage = (
      <Typography
        margin="0 0 20px 0"
        size="18px"
        color={textColor(submitted)}
      >
        {submitMessageText}
       </Typography>
    )
    const confirmList = [
      "1. Google Search Consoleにウェブサイトを登録している．(https://search.google.com/search-console)",
      "2. Google認証から認証を行なっている.",
    ].map((msg, idx) => {
      return (
        <li key={`submit-modal-msg-${idx.toString()}`}><Typography size="14px">{msg}</Typography></li>
      )
    })

    const hintArea = (
      <>
        <Typography>Google認証を再度行うことで，SearchConsoleへの提出ができることがあります．</Typography>
        <Typography>
          <a href="https://search.google.com/search-console">https://search.google.com/search-console</a>からサイトマップを提出することも可能です．
        </Typography>
        <Wrap margin="10px 0 0 0">
          <Typography size="12px">
            参考: 
            <Typography color={color.blue} size="12px">
              <a color={color.blue} href="https://support.google.com/webmasters/answer/7451001">サイトマップの管理</a>
            </Typography>
            (項目: サイトマップの送信)
          </Typography>
        </Wrap>
      </>
    )

    return (
      <Modal show={isShownSubmitModal}>
        { submitMessage }
        { confirmList }
        <Wrap margin="10px auto">
          { hintArea }
        </Wrap>
        <Wrap justifyContent="center" margin="20px 0 0 0">
          <Button onClick={closeModal}>OK</Button>
        </Wrap>
      </Modal>
    )
  }

  return (
    <TitleBox title="サイトマップの提出">
      <Wrap>
        { submitLoading ? (<Loading/>) : null }
        { submitResultModal() }
        <Wrap alignItems="center" justifyContent="space-between">
          <Typography size="14px" color={color.red}>
            あらかじめGoogleSearchConsoleでウェブサイトの登録と，Google連携を行う必要があります．
          </Typography>
          <Button onClick={submit}>サイトマップを提出</Button>
        </Wrap>
      </Wrap>
    </TitleBox>
  )
}