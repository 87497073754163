import React from "react";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import SelectBoxLabel from "../molecules/SelectBoxLabel";
import InputLabel from "../molecules/InputLabel";
import Button from "../atoms/Button";
import { Notice } from '../atoms/Notice';
import Note from "../atoms/Note";


const messageFormatter = (status, messages) => {
  console.log(status)
  console.log(messages)
  const keys = Object.keys(messages)
  const color = status === 'ok' ? 'green' : 'red'
  const message = status === 'ok' ? 'ドメインを登録しました' : `${keys.toString()}が無効です`
  return (
    <Notice color={color} margin='0 0 10px'>
      {message}
    </Notice>
  )
}

export default ({
  haveRegistered,
  registerDomainAction,
  handleDomainChange,
  handleChange,
  registrar_type,
  registrarTypes,
  domain,
  hp_domain,
  messages,
  status,
}) => {
  return (
    <div>
      <Wrap padding="20px">
        <Wrap width="500px" margin="auto">
          {status !== null && (
            messageFormatter(status, messages)
          )}
          <TitleBox title="ドメイン登録">
            {haveRegistered && (
              <Note>ドメイン登録は完了しています</Note>
            )}
            <SelectBoxLabel
              name="registrar_type"
              options={registrarTypes}
              label="外部レジストラの種類"
              value={registrar_type}
              onChange={handleChange}
              readOnly={haveRegistered}
            />
            <InputLabel
              required
              name="domain"
              placeholder="example.com"
              label="ドメイン"
              value={domain}
              onChange={handleDomainChange}
              readOnly={haveRegistered}
            />
            <InputLabel
              name="hp_domain"
              placeholder="www.example.com"
              label="ホームページに使うドメイン"
              value={hp_domain}
              onChange={handleChange}
              readOnly={haveRegistered}
            />
            <Wrap justifyContent="center" margin="20px 0 0">
              <Button disabled={haveRegistered || status === 'ok'} onClick={registerDomainAction}>登録</Button>
            </Wrap>
          </TitleBox>
        </Wrap>
      </Wrap>
    </div>
  )
}