import React from "react";
import { Route, Redirect } from "react-router-dom";
import CheckValidOptions from "./CheckValidOptions";

export const isAuthorized = key => localStorage.getItem(key);

export default (key, loginPath) => ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authorized = isAuthorized(key);
        return authorized ? (
          <CheckValidOptions
            component={component}
            path={rest.location.pathname}
            tokenType={key}
          />
        ) : (
          <Redirect to={loginPath} />
        );
      }}
    />
  );
};
