import React from "react";
import { Link } from "react-router-dom";

import color from "../../utils/color";
import route from "../../utils/route";
import { storeId } from "../../utils/localStorage";

import TitleBox from "../atoms/TitleBox";

import Wrap from "../atoms/Wrap";
import Loading from "../atoms/Loading";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import InputLabel from "../molecules/InputLabel";
import LinkText from "../atoms/LinkText";

const ExternalDomainLink = () => {
  return (
    <div>
      <LinkText to={route.store(storeId()).externalDomain}>
        すでにドメインをお持ちの場合はこちら
      </LinkText>
    </div>
  );
};

const DomainSection = ({ domain }) => {
  return (
    <div>
      <Typography color={color.black}>
        {domain}は使用することができます。
      </Typography>
      <Wrap justifyContent="center">
        <Link
          to={{
            pathname: route.store(storeId()).domainRegister,
            state: { domain }
          }}>
          <Button style={{ marginTop: 20 }}>ドメインの取得に進む</Button>
        </Link>
      </Wrap>
    </div>
  );
};

export default ({
  domain,
  hp_domain,
  domainAvailable,
  handleChange,
  domainCheck,
  loading,
  domainPreview,
  disabled
}) => {
  return (
    <div>
      <Wrap padding="20px">
        <Wrap width="500px" margin="auto">
          <TitleBox title="ドメイン取得">
            <Typography color={color.black}>
              {!disabled
                ? "取得したいドメインを入力してください"
                : "取得したドメイン"}
            </Typography>
            <Wrap justifyContent="space-between" alignItems="center">
              <InputLabel
                name="domain"
                placeholder="（例）example.com"
                value={domain}
                onChange={handleChange}
                width="calc(100% - 100px)"
                disabled={disabled}
              />
             {!disabled && <Button onClick={domainCheck}>検索</Button>}
            </Wrap>
            { disabled &&
              <>
                <Typography color={color.black}>公開用ドメイン</Typography>
                <Wrap justifyContent="space-between" alignItems="center">
                  <InputLabel
                    name="hp_domain"
                    placeholder="（例）example.com"
                    value={hp_domain}
                    onChange={handleChange}
                    width="calc(100% - 100px)"
                    disabled={disabled}
                  />
                </Wrap>
              </>
            }
 
            {!disabled && (
              <>
                <Wrap justifyContent="flex-end">{ExternalDomainLink()}</Wrap>
                <div style={{ borderTop: `1px solid ${color.gray}` }} />
                {loading ? (
                  <Loading />
                ) : (
                  <Wrap padding="20px 0">
                    {domainAvailable !== "" &&
                      (domainAvailable ? (
                        <DomainSection storeId={storeId()} domain={domain} />
                      ) : (
                        <Typography color={color.red}>
                          {domainPreview}は既に使用されています。
                        </Typography>
                      ))}
                  </Wrap>
                )}
              </>
            )}
          </TitleBox>
        </Wrap>
      </Wrap>
    </div>
  );
};
