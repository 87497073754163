import styled from "styled-components";
import color from "../../utils/color";

export default styled.div`
  color: ${props => props.color || color.typoLight};
  font-size: ${props => props.size || "14px"};
  font-weight: ${props => props.bold && "bold"};
  text-align: ${props => props.textAlign};

  margin: ${props => props.margin};
  opacity: ${props => props.opacity};
`;
