import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import color from "../../utils/color";
import Typography from "../atoms/Typography";
import Button from "../atoms/Button";

import copyDom from "../../utils/copyDom";

const Grid = styled.div`
  display: grid;
  grid-template-rows: 175px 175px 175px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 15px;
`;

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid ${color.gray};
  height: 175px;
  box-sizing: border-box;
  border-radius: 3px;
`;

const CardMainWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 15px 0 15px;
`;

const ContactNum = styled.div`
  color: ${color.black};
  font-size: 14px;

  > span {
    font-weight: bold;
    font-size: 18px;
  }
`;

const UnderBar = styled.div`
  margin: 15px 0;
  width: 15%;
  height: 3px;
  border-radius: 1.5px;
  background-color: ${props => props.color};
`;

const FormName = styled.div`
  margin: 10px 0 5px;
  font-weight: bold;
  font-size: 12px;
  color: ${color.black};
`;

const ToDetail = styled.div`
  width: 100%;
  border-top: 1px solid ${color.grayLight};
  font-size: 12px;
  box-sizing: border-box;
  padding: 10px 15px;
  text-align: right;
  color: ${color.typoLight};
  display: flex;
  justify-content: space-between;
  transition: 0.15s;

  :hover {
    background-color: ${color.grayLight};
  }
`;

const Card = ({ to, num, color, name, dom }) => {
  return (
    <CardWrap>
      <CardMainWrap>
        {num && (
          <ContactNum>
            お問い合わせ <span>{num}</span> 件
          </ContactNum>
        )}
        <FormName>{name}</FormName>
        <UnderBar color={color} />
        <Button
          size="tiny"
          style={{ marginTop: 15 }}
          onClick={() => copyDom(dom)}>
          HTMLコピー
        </Button>
      </CardMainWrap>
      <Link to={to} style={{ width: "100%" }}>
        <ToDetail>
          フォーム詳細へ <div>></div>
        </ToDetail>
      </Link>
    </CardWrap>
  );
};

const colors = [
  color.green,
  color.red,
  color.yellow,
  color.purple,
  color.blueGray
];

export default ({ forms, editPath }) => {
  return forms ? (
    <Grid>
      {forms.map((f, i) => (
        <Card
          key={i}
          to={{
            pathname: editPath,
            state: { forms: f, isEdit: true, formId: f.id }
          }}
          color={colors[i % colors.length]}
          dom={f.dom}
          // TODO: 各フォームに何件お問い合わせがきているか表示すると良いかも
          // num={3}
          name={f.name || `フォーム ${f.id}`}
        />
      ))}
    </Grid>
  ) : (
    <Typography textAlign="center" margin="30px 0 0">
      新しくフォームを作成しましょう。
    </Typography>
  );
};
