export const storeId = () => localStorage.getItem("storeId");

export const setStoreId = storeId => {
  localStorage.removeItem("storeId");
  localStorage.setItem("storeId", storeId);
};

export const isAgency = () => {
  return !!localStorage.getItem("agencyToken");
};

export const token = userType => {
  return localStorage.getItem(`${userType}Token`);
};

export const setToken = (userType, token) => {
  if (userType === "agency") {
    localStorage.removeItem("storeToken");
    localStorage.setItem("agencyToken", token);
  } else {
    localStorage.removeItem("agencyToken");
    localStorage.setItem("storeToken", token);
  }
};
