import React from "react";
import styled from "styled-components";

import color from "../../utils/color";

import {
  faTwitterSquare,
  faFacebookSquare
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import Button from "../atoms/Button";

export default ({ socialAccountInfo, authUri, kind, unLinkSns, loading }) => {
  const icon =
    kind === "twitter"
      ? faTwitterSquare
      : kind === "facebook"
      ? faFacebookSquare
      : null;

  const kind_display = kind.charAt(0).toUpperCase() + kind.slice(1);
  return (
    <Wrap justifyContent="space-between" margin="10px 0 30px 0">
      <Wrap alignItems="center">
        <FontAwesomeIcon
          icon={icon}
          color={color[kind]}
          style={{ marginRight: 10 }}
          size="3x"
        />
        <Typography color={color.blueGray} size="30px">
          {kind_display}
        </Typography>
      </Wrap>
      <Wrap alignItems="flex-end">
        {!loading && socialAccountInfo ? (
          <>
            <Typography color={color.blueGray}>連携アカウント：</Typography>

            {socialAccountInfo.profile_link ? (
              <OuterLinkText
                href={socialAccountInfo.profile_link}
                target="_blank"
                rel="noopener noreferrer">
                {kind === "twitter" && "@"}
                {socialAccountInfo.name}
              </OuterLinkText>
            ) : (
              <Typography color={color.blueGray} margin="5px 0">
                {socialAccountInfo.name}
              </Typography>
            )}
            <Button
              onClick={() => unLinkSns(socialAccountInfo.id)}
              style={{ marginLeft: 10 }}
              backgroundColor={color.red}>
              連携解除
            </Button>
          </>
        ) : (
          <a href={authUri}>
            <Button style={{ backgroundColor: color[kind] }}>
              {kind_display}連携
            </Button>
          </a>
        )}
      </Wrap>
    </Wrap>
  );
};

const OuterLinkText = styled.a`
  color: ${color.blue};
  font-size: 13px;
  margin 5px 0;
  :hover {
  text-decoration: underline;
}`;
