import React from "react";
import Button from "../atoms/Button";
import FileDropArea from "../molecules/FileDropArea";
import Typography from "../atoms/Typography";
import color from "../../utils/color";

export default props => {
  const { handleChangeFavicon, updateFavicon, faviconMessage } = props;
  return (
    <>
      <FileDropArea isFavicon dropChange={handleChangeFavicon} />
      <div style={{ textAlign: "center", marginTop: "3rem" }}>
        <Button onClick={updateFavicon} style={{ marginBottom: "1rem" }}>
          送信
        </Button>
        <Typography
          color={faviconMessage.type === "error" ? color.red : color.blue}
        >
          {faviconMessage.message}
        </Typography>
      </div>
    </>
  );
};
