import React from "react";
import Button from "../atoms/Button";
import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import FileImg from "../atoms/FileImg";
import Modal from "../molecules/Modal";

export default ({ medias, close, show, src, setSrc, replaceImage }) => {
  return (
    <Modal title="画像選択" show={show} close={close}>
      <Typography margin="0 0 10px">
        変更する画像を選択してください。
      </Typography>
      <Wrap maxHeight="50vh" style={{ overflowX: "scroll" }}>
        {medias
          ? medias.map((m) => (
              <FileImg
                key={m.id}
                src={m.url}
                selected={m.url === src}
                onClick={() => setSrc(m.url)}
              />
            ))
          : "画像がありません。メディア管理で画像を追加してください。"}
      </Wrap>
      <Wrap justifyContent="flex-end" margin="10px 0 0">
        <Button onClick={close} style={{ marginRight: 10 }}>
          キャンセル
        </Button>
        <Button
          onClick={() => {
            replaceImage(src);
            close();
          }}
        >
          確定
        </Button>
      </Wrap>
    </Modal>
  );
};
