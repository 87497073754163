import React from "react";
import { Link } from "react-router-dom";

import route from "../../utils/route";
import { storeId } from "../../utils/localStorage";
import color from "../../utils/color";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import InputLabel from "../molecules/InputLabel";
import SelectBoxLabel from "../molecules/SelectBoxLabel";
import Typography from "../atoms/Typography";
import Loading from "../atoms/Loading";
import Note from "../atoms/Note";

const Description = ({ email }) => {
  return (
    <Wrap>
      <Typography>
        ご登録いただいた{email}に確認のメールを送信しております。
        <br />
        メール本文中の認証用リンクをクリックし、認証を完了してください。
      </Typography>
      <Typography color={color.red} margin="0 0 20px 0">
        （※
        認証を完了していない場合、サイトが公開されなくなります。必ず認証作業を行ってください。）
      </Typography>
      <Typography textAlign="center" size="16px" margin="0 0 10px 0" bold>
        メールが届かない場合...
      </Typography>
      <Typography textAlign="left" style={{ padding: "0 20%" }}>
        ・登録したメールアドレスに誤りがないか確認する
        <br />
        ・迷惑メールBOXを確認する
        <br />
        ・サービス運営者または代理店に連絡する
      </Typography>
    </Wrap>
  );
};

export default ({
  handleChange,
  contactDomain,
  domain,
  first_name,
  last_name,
  organization_name,
  address_line_1,
  address_line_2,
  city,
  state,
  zip_code,
  phone_number,
  email,
  domainContactOk,
  errorMessage,
  stateOptions,
  loading
}) => {
  return (
    <Wrap justifyContent="center" margin="auto" padding="50px">
      <Wrap width="100%" maxWidth="700px">
        <Link to={route.store(storeId()).domainCheck}>
          <Button style={{ marginBottom: 15 }}>戻る</Button>
        </Link>
        <TitleBox title="ドメイン登録">
          {domainContactOk ? (
            <Description email={email} />
          ) : (
            <>
              {loading ? (
                <Loading
                  label="ドメイン取得中..."
                  description={
                    "ドメインの取得には最大10分かかります。\nしばらくお待ちください。"
                  }
                />
              ) : (
                <form onSubmit={e => e.preventDefault()}>
                  <Wrap justifyContent="space-around">
                    <Wrap width="50%">
                      <InputLabel
                        required
                        label="ドメイン名"
                        value={domain}
                        disabled
                        note="※この項目は変更できません。"
                      />
                      <Wrap alignItems="center">
                        <InputLabel
                          required
                          name="first_name"
                          label="名字"
                          value={first_name}
                          onChange={handleChange}
                          placeholder="山田"
                          width="calc(50% - 5px)"
                          margin="0"
                          errorMessage={errorMessage.first_name}
                        />
                        <InputLabel
                          required
                          name="last_name"
                          label="名前"
                          value={last_name}
                          onChange={handleChange}
                          style={{ marginLeft: 10 }}
                          placeholder="太郎"
                          width="calc(50% - 5px)"
                          margin="0"
                          errorMessage={errorMessage.last_name}
                        />
                      </Wrap>
                      <InputLabel
                        required
                        name="organization_name"
                        label="会社名"
                        value={organization_name}
                        onChange={handleChange}
                        placeholder="株式会社xx"
                        errorMessage={errorMessage.organization_name}
                      />
                      <InputLabel
                        type="tel"
                        required
                        name="phone_number"
                        label="電話番号"
                        value={phone_number}
                        onChange={handleChange}
                        placeholder="000-0000-0000"
                        errorMessage={errorMessage.phone_number}
                        style={{ marginBottom: 22 }}
                      />
                      <InputLabel
                        required
                        name="email"
                        label="メールアドレス"
                        value={email}
                        onChange={handleChange}
                        placeholder="info@example.com"
                        errorMessage={errorMessage.email}
                      />
                    </Wrap>
                    <Wrap width="50%" margin="0 0 0 20px">
                      <InputLabel
                        required
                        type="tel"
                        pattern="\d{3}-\d{4}"
                        name="zip_code"
                        label="郵便番号"
                        value={zip_code}
                        onChange={handleChange}
                        placeholder="0000000"
                        errorMessage={errorMessage.zip_code}
                        note="※ハイフン（-）なしで入力してください"
                      />
                      <SelectBoxLabel
                        required
                        name="state"
                        label="都道府県"
                        onChange={handleChange}
                        options={stateOptions}
                        value={state}
                        placeholder="東京都"
                      />
                      <InputLabel
                        required
                        name="city"
                        label="市町村"
                        value={city}
                        onChange={handleChange}
                        placeholder="渋谷区"
                        errorMessage={errorMessage.city}
                      />
                      <InputLabel
                        required
                        name="address_line_1"
                        label="住所1"
                        value={address_line_1}
                        onChange={handleChange}
                        placeholder="原宿1-1"
                        errorMessage={errorMessage.address_line_1}
                        note="必ず番地を含めてください。"
                      />
                      <InputLabel
                        name="address_line_2"
                        label="住所2"
                        value={address_line_2}
                        onChange={handleChange}
                        placeholder="竹下通り"
                        errorMessage={errorMessage.address_line_2}
                      />
                    </Wrap>
                  </Wrap>
                  <Note style={{ textAlign: "center" }}>
                    ※ ドメイン契約は1年毎に自動更新されます。
                  </Note>
                  <Wrap justifyContent="center" margin="20px 0 0">
                    <Button onClick={contactDomain}>登録</Button>
                  </Wrap>
                </form>
              )}
            </>
          )}
        </TitleBox>
      </Wrap>
    </Wrap>
  );
};
