import React from "react";
import { Link } from "react-router-dom";

import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";

import {
  faPlus,
  faArrowDown,
  faArrowUp,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import TitleBox from "../atoms/TitleBox";
import IconButton from "../atoms/IconButton";
import Typography from "../atoms/Typography";
import CheckBox from "../atoms/CheckBox";

import Table from "../molecules/Table";
import Modal from "../molecules/Modal";
import FormSelectType from "../molecules/FormSelectType";
import FormInputMetaData from "../molecules/FormInputMetaData";
import InputLabel from "../molecules/InputLabel";

import color from "../../utils/color";

const OperateButtons = ({ index, swap, formLength, remove }) => {
  return (
    <Wrap justifyContent="flex-start">
      <IconButton
        icon={faArrowUp}
        margin="0 0 0 5px"
        onClick={() => index !== 0 && swap(index, index - 1)}
      />
      <IconButton
        icon={faArrowDown}
        margin="0 0 0 5px"
        onClick={() => index !== formLength - 1 && swap(index, index + 1)}
      />
      <IconButton
        icon={faTrash}
        margin="0 0 0 5px"
        onClick={() => remove(index)}
      />
    </Wrap>
  );
};

const typeDict = {
  text: "一行テキスト",
  textarea: "複数行テキスト",
  email: "メールアドレス",
  selectbox: "セレクトボックス",
  radio: "ラジオボタン",
  date: "日付",
  checkbox: "チェックボックス"
  // toggle: "トグルチェック",
  // number: "数値",
  // tel: "電話番号",
};

const tableDatas = ({ forms, editValue, handleClickCheck, ...props }) => {
  return forms.map(
    (f, i) =>
      f && [
        i + 1,
        <InputLabel
          name="label"
          value={f.label}
          onChange={e => editValue(e, i)}
        />,
        typeDict[f.type],
        <CheckBox checked={f.required} onClick={() => handleClickCheck(i)} />,
        <OperateButtons index={i} formLength={forms.length} {...props} />
      ]
  );
};

export default ({
  forms,
  add,
  handleChange,
  type,
  create,
  update,
  deleteForm,
  swap,
  remove,
  editValue,
  isEditPage,
  code,
  name,
  modalTitle,
  pub_email,
  resetNewFormParams,
  redirect_to,
  errorMessage,
  handleClickCheck,
  ...props
}) => {
  const [showModal, setModalDisplay] = React.useState(false);
  const closeModal = () => {
    resetNewFormParams();
    setModalDisplay(false);
  };

  const indexPath = isAgency()
    ? route.store(storeId()).formIndex
    : route.formIndex;

  return (
    <Wrap padding="20px">
      <Modal
        title={modalTitle ? `フォーム追加 - ${modalTitle}` : "フォーム追加"}
        show={showModal}
        close={closeModal}
      >
        {type ? (
          <FormInputMetaData
            handleChange={handleChange}
            resetNewFormParams={resetNewFormParams}
            add={() => add(closeModal)}
            type={type}
            {...props}
            errorMessage={errorMessage}
            closeModal={closeModal}
          />
        ) : (
          <FormSelectType handleChange={handleChange} closeModal={closeModal} />
        )}
      </Modal>
      <Wrap margin="0 0 15px">
        <Link to={indexPath}>
          <Button>フォーム一覧へ</Button>
        </Link>
      </Wrap>
      <TitleBox title={`問い合わせフォーム${isEditPage ? "詳細" : "新規作成"}`}>
        <form onSubmit={e => e.preventDefault(e)}>
          <InputLabel
            name="name"
            width="300px"
            label="フォーム名"
            value={name}
            onChange={handleChange}
            placeholder="お問い合わせフォーム, 採用フォームなど"
          />
          <InputLabel
            name="pub_email"
            width="300px"
            required
            label="受信用メールアドレス"
            value={pub_email}
            onChange={handleChange}
            placeholder="contact@mail.com"
            errorMessage={errorMessage.pub_email}
          />
          <InputLabel
            name="redirect_to"
            width="300px"
            required
            label="リダイレクト先URL"
            value={redirect_to}
            onChange={handleChange}
            placeholder="https://sample.com"
            errorMessage={errorMessage.redirect_to}
          />
          <Wrap padding="20px" style={{ border: `1px solid #ddd` }}>
            {forms.length === 0 ? (
              <Typography size="20px" textAlign="center" margin="60px 0 30px">
                フォームを追加してください
              </Typography>
            ) : (
              <Table
                colWidths={["30px"]}
                tableHeads={["番号", "ラベル", "種類", "必須項目", "操作"]}
                tableDatas={tableDatas({
                  forms,
                  swap,
                  remove,
                  handleClickCheck,
                  editValue
                })}
              />
            )}
            <Wrap justifyContent="center" margin="15px 0">
              <Button
                icon={faPlus}
                onClick={() => setModalDisplay(true)}
                size="small"
                backgroundColor={color.yellow}
              >
                フォーム追加
              </Button>
            </Wrap>
          </Wrap>
          <Wrap margin="35px 0 0">
            {isEditPage ? (
              <>
                <Wrap justifyContent="center">
                  <Button onClick={update}>上書き保存</Button>
                </Wrap>
                <Wrap justifyContent="flex-end" margin="10px 0 0">
                  <Button
                    size="small"
                    onClick={deleteForm}
                    backgroundColor={color.red}
                  >
                    フォーム削除
                  </Button>
                </Wrap>
              </>
            ) : (
              <Wrap justifyContent="center">
                <Button onClick={create}>作成</Button>
              </Wrap>
            )}
          </Wrap>
        </form>
      </TitleBox>
    </Wrap>
  );
};
