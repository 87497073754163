import React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Square = styled.span`
  display: inline-block;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  color: ${color.white};
  border-style: solid;
  border-width: 1px;
  border-color: ${color.gray};

  :hover {
    border-color: ${props => !props.checked && color.green};
  }

  border-color: ${props => props.checked && color.green};
  background-color: ${props => (props.checked ? color.green : color.white)};
`;

export default ({ checked, onClick, margin }) => {
  return (
    <div style={{ height: 22, margin: margin || 0 }}>
      <label
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        <input type="checkbox" style={{ display: "none" }} />
        <Square checked={checked}>
          {checked && <FontAwesomeIcon icon={faCheck} />}
        </Square>
      </label>
    </div>
  );
};
