import React from 'react';

import styled from "styled-components";
import color from '../../utils/color'
import NoImage from "../../img/no-image.png";

import Note from '../atoms/Note';
import Wrap from '../atoms/Wrap';
import InputLabel from '../molecules/InputLabel';
import Button from '../atoms/Button';
import TextareaLabel from '../molecules/TextareaLabel';
import Label from '../atoms/Label';
import SelectBoxLabel from '../molecules/SelectBoxLabel';
import Loading from '../atoms/Loading';
import Typography from "../atoms/Typography";

import Modal from '../molecules/Modal';

const FileImg = styled.img`
  height: 120px;
  object-fit: contain;
  margin: 5px;
  border: ${props =>
    `3px solid ${props.selected ? color.blue : color.grayLight}`};
`;

const Img = styled.img`
  width: 40%;
  height: 40%;
  object-fit: contain;
  border: 1px solid ${ color.grayLight};
  display: block;
  margin-bottom: 10px;
`

const SelectOgImageModal = ({
  loading,
  medias,
  close,
  imageUrl,
  handleImageChange,
  ...props
}) => {
  return (
    <Modal {...props}>
      {loading ? (
        <Loading />
      ) : (
        <Wrap>
          <Wrap maxHeight="50vh" style={{ overflowX: "scroll" }}>
            {medias &&
              medias.map(m => (
                <FileImg
                  key={m.id}
                  src={m.url}
                  selected={m.url === imageUrl}
                  onClick={() => handleImageChange('og', m.url)}
                />
              ))}
            </Wrap>
            <Wrap justifyContent="flex-end" margin="20px 0 0">
              <Button onClick={close}>
                閉じる
              </Button>
            </Wrap>
          </Wrap>
        )}
    </Modal>
  )
}

const SelectTwitterImageModal = ({
  loading,
  medias,
  close,
  imageUrl,
  handleImageChange,
  ...props
}) => {
  return (
    <Modal
      {...props}
    >
      {loading ? (
        <Loading />
      ) : (
          <Wrap>
            <Wrap maxHeight="50vh" style={{ overflowX: "scroll" }}>
              {medias &&
                medias.map(m => (
                  <FileImg
                    key={m.id}
                    src={m.url}
                    selected={m.url === imageUrl}
                    onClick={() => handleImageChange('twitter', m.url)}
                  />
                ))}
            </Wrap>
            <Wrap justifyContent="flex-end" margin="20px 0 0">
              <Button onClick={close}>
                閉じる
              </Button>
            </Wrap>
          </Wrap>
        )}
    </Modal>
  )
}

export default ({
  seo_tag,
  handleChange,
  handleImageChange,
  loading,
  medias,
}) => {
  const [showOgImageModal, setShowOgImageModal] = React.useState(false);
  const [showTwitterImageModal, setShowTwitterImageModal] = React.useState(false);

  return (
    <>
      <SelectOgImageModal
        title='og:image'
        medias={medias}
        loading={loading}
        handleImageChange={handleImageChange}
        show={showOgImageModal}
        close={() => setShowOgImageModal(false)}
      />
      <SelectTwitterImageModal
        title='twitter:image'
        medias={medias}
        loading={loading}
        handleImageChange={handleImageChange}
        show={showTwitterImageModal}
        close={() => setShowTwitterImageModal(false)}
      />
      <InputLabel
        name='doc:title'
        label='title'
        value={seo_tag.doc.title}
        onChange={handleChange}
      />
      <TextareaLabel
        name='doc:description'
        label='description'
        value={seo_tag.doc.description}
        onChange={handleChange}
      />
      <InputLabel
        name='doc:keywords'
        label='keywords'
        value={seo_tag.doc.keywords}
        onChange={handleChange}
      />
      <Note>カンマ区切りで入力してください（例. いちご,レモン,果物）</Note>
      <Wrap height='40px' />

      <Typography>OGPタグ</Typography>
      <InputLabel
        name='og:title'
        label='og:title'
        value={seo_tag.og.title}
        onChange={handleChange}
      />
      <TextareaLabel
        name='og:description'
        label='og:description'
        value={seo_tag.og.description}
        onChange={handleChange}
      />
      <InputLabel
        name='og:site_name'
        label='og:site_name'
        value={seo_tag.og.site_name}
        onChange={handleChange}
      />
      <Label>og:image</Label>
      <Img
        src={seo_tag.og.image}
        onError={e => e.target.src = NoImage}
      />
      <Button onClick={() => setShowOgImageModal(true)}>画像を選択</Button>
      <Wrap height='40px' />

      <Typography>Twitterタグ</Typography>
      <InputLabel
        name='twitter:title'
        label='twitter:title'
        value={seo_tag.twitter.title}
        onChange={handleChange}
      />
      <TextareaLabel
        name='twitter:description'
        label='twitter:description'
        value={seo_tag.twitter.description}
        onChange={handleChange}
      />
      <SelectBoxLabel
        name='twitter:card'
        label='twitter:card'
        value={seo_tag.twitter.card}
        options={
          [
            { label: 'summary', value: 'summary' },
            { label: 'summary_large_image', value: 'summary_large_image' }
          ]
        }
        onChange={handleChange}
      />
      <Label>twitter:image</Label>
      <Img
        src={seo_tag.twitter.image}
        onError={e => e.target.src = NoImage}
      />
      <Button onClick={() => setShowTwitterImageModal(true)}>画像を選択</Button>
    </>
  )
}