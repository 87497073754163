import React from "react";
import { Link } from "react-router-dom";

import Wrap from "../atoms/Wrap";

export default ({ to, label }) => {
  return (
    <Link to={to} style={{ cursor: "pointer" }}>
      <div style={{ fontSize: 14, margin: "0 15px" }}>
        <Wrap alignItems="center">{label}</Wrap>
      </div>
    </Link>
  );
};
