import React from "react";

import api from "../../api";
import PostIndex from "../templates/PostIndex";

export default class extends React.Component {
  state = {
    news: [],
    templateExist: false,
    loading: true
  };

  fetchTemplate = () => {
    api.template
      .show()
      .then(template => {
        template.news_template.content &&
          this.setState({ templateExist: true, loading: false });
      })
      .catch(e => {
        console.error(e);
      });
  };

  fetchNews = () => {
    api.news
      .index()
      .then(news => {
        this.setState({ news: news && news.news, loading: false });
      })
      .catch(e => {
        e.json().then(err => console.log(err));
      });
};

  applyTemplateToAllPost = async () => {
    let message;
    await api.applyNewsTemplate
      .apply()
      .catch(e => {
        console.log(e)
        message = "テンプレートの適用に失敗しました"
      })
      .finally(() => {
        message = message || "テンプレートを適用しました"
      })

    return message;
  }

  componentDidMount() {
    this.fetchTemplate();
    this.fetchNews();
  }

  render() {
    const { news } = this.state;

    return (
      <PostIndex
        posts={news}
        applyTemplateToAllPost={this.applyTemplateToAllPost}
        {...this.state}
      />
    );
  }
}
