import React from "react";

import color from "../../utils/color";

import Wrap from "../atoms/Wrap";
import Label from "../atoms/Label";
import CheckBox from "../atoms/CheckBox";
import Typography from "../atoms/Typography";

export default ({ label, margin, note, ...props }) => {
  return (
    <Wrap margin={margin || "15px 0"}>
      <Wrap alignItems="center">
        <CheckBox {...props} />
        <Label style={{ marginLeft: 10 }}>{label}</Label>
      </Wrap>
      {note && (
        <Typography size="11px" color={color.red} margin="5px 0 0">
          {note}
        </Typography>
      )}
    </Wrap>
  );
};
