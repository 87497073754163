import styled from "styled-components";
import color from "../../utils/color";

export default styled.img`
  height: 120px;
  object-fit: contain;
  margin: 5px;
  border: ${(props) =>
    `3px solid ${props.selected ? color.blue : color.grayLight}`};
`;
