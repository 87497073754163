import route from "./route";

// common pages
import SettingAccount from "../components/pages/SettingAccount";
import PasswordUpdate from "../components/pages/PasswordUpdate";

// users
import SignIn from "../components/pages/SignIn";
import PasswordResetRequest from "../components/pages/PasswordResetRequest";
import PasswordReset from "../components/pages/PasswordReset";

// agency admin pages
import StoreIndex from "../components/pages/StoreIndex";
import StoreNew from "../components/pages/StoreNew";
import AgencyIndex from "../components/pages/AgencyIndex";
import AgencyNew from "../components/pages/AgencyNew";
import AgencyShow from "../components/pages/AgencyShow";
import StaffIndex from "../components/pages/StaffIndex";
import StaffNew from "../components/pages/StaffNew";
import StaffShow from "../components/pages/StaffShow";
import NotificationIndex from "../components/pages/NotificationIndex";
import NotificationNew from "../components/pages/NotificationNew";
import NotificationEdit from "../components/pages/NotificationEdit";
import NotificationShow from "../components/pages/NotificationShow";

// dashboard pages
import Dashboard from "../components/pages/Dashboard";
import VersionsIndex from "../components/pages/VersionsIndex";
import VersionsNew from "../components/pages/VersionsNew";
import VersionsShow from "../components/pages/VersionsShow";
import SourceEdit from "../components/pages/SourceEdit";
import MediaIndex from "../components/pages/MediaIndex";
import MediaNew from "../components/pages/MediaNew";
import NewsIndex from "../components/pages/NewsIndex";
import NewsEdit from "../components/pages/NewsEdit";
import NewsNew from "../components/pages/NewsNew";
import NewsCategory from "../components/pages/NewsCategory";
import NewsTemplate from "../components/pages/NewsTemplate";
import ArticleIndex from "../components/pages/ArticleIndex";
import ArticleEdit from "../components/pages/ArticleEdit";
import ArticleNew from "../components/pages/ArticleNew";
import ArticleCategory from "../components/pages/ArticleCategory";
import ArticleTemplate from "../components/pages/ArticleTemplate";
import SettingStore from "../components/pages/SettingStore";
import Analysis from "../components/pages/Analysis";
import Contacts from "../components/pages/Contacts";
import FormIndex from "../components/pages/FormIndex";
import FormDetail from "../components/pages/FormDetail";
import Applicants from "../components/pages/Applicants";
import JobOfferIndex from "../components/pages/JobOfferIndex";
import JobOfferDetail from "../components/pages/JobOfferDetail";
import DomainCheck from "../components/pages/DomainCheck";
import DomainRegister from "../components/pages/DomainRegister";
import SettingSite from "../components/pages/SettingSite";
import SeoPreference from "../components/pages/SeoPreference";
import ExternalDomainRegister from "../components/pages/ExternalDomainRegister";
import SettingSitemap from "../components/pages/SettingSitemap";

export default {
  users: [
    { component: StoreIndex, path: route.storeIndex },
    { component: SignIn, path: route.signInAgency },
    { component: SignIn, path: route.signInStore },
    { component: PasswordResetRequest, path: route.passwordResetRequest },
    { component: PasswordReset, path: route.passwordReset }
  ],

  agencyAdmin: [
    { component: StoreIndex, path: route.storeIndex },
    { component: StoreNew, path: route.storeNew },
    { component: AgencyIndex, path: route.agencyIndex },
    { component: AgencyNew, path: route.agencyNew },
    { component: AgencyShow, path: route.agencyShow },
    { component: StaffIndex, path: route.agencyStaffIndex },
    { component: StaffNew, path: route.agencyStaffNew },
    { component: StaffShow, path: route.agencyStaffShow },
    { component: SettingAccount, path: route.settingAccountAgency },
    { component: PasswordUpdate, path: route.passwordUpdateAgency },
    { component: NotificationIndex, path: route.notificationsIndex },
    { component: NotificationNew, path: route.notificationsNew },
    { component: NotificationEdit, path: route.notificationsEdit }
  ],

  agency: [
    { component: Dashboard, path: route.store().dashboard },
    { component: VersionsIndex, path: route.store().versionsIndex },
    { component: VersionsNew, path: route.store().versionsNew },
    { component: VersionsShow, path: route.store().versionsShow },
    { component: SourceEdit, path: route.store().sourceEdit() },
    { component: MediaIndex, path: route.store().mediaIndex },
    { component: MediaNew, path: route.store().mediaNew },
    { component: ArticleIndex, path: route.store().articleIndex },
    { component: ArticleEdit, path: route.store().articleEdit },
    { component: ArticleNew, path: route.store().articleNew },
    { component: ArticleTemplate, path: route.store().articleTemplate },
    { component: ArticleCategory, path: route.store().articleCategory },
    { component: NewsIndex, path: route.store().newsIndex },
    { component: NewsEdit, path: route.store().newsEdit },
    { component: NewsNew, path: route.store().newsNew },
    { component: NewsTemplate, path: route.store().newsTemplate },
    { component: NewsCategory, path: route.store().newsCategory },
    { component: SettingStore, path: route.store().settingStore },
    { component: DomainCheck, path: route.store().domainCheck },
    { component: DomainRegister, path: route.store().domainRegister },
    { component: SettingSite, path: route.store().settingSite },
    { component: Contacts, path: route.store().contacts },
    { component: FormIndex, path: route.store().formIndex },
    { component: FormDetail, path: route.store().formNew },
    { component: FormDetail, path: route.store().formEdit },
    { component: FormDetail, path: route.store().formEdit },
    { component: Applicants, path: route.store().applicants },
    { component: JobOfferIndex, path: route.store().jobOfferIndex },
    { component: JobOfferDetail, path: route.store().jobOfferNew },
    { component: JobOfferDetail, path: route.store().jobOfferEdit },
    { component: NotificationShow, path: route.store().notificationsShow },
    { component: Analysis, path: route.store().analysis },
    { component: SeoPreference, path: route.store().seoPreference },
    { component: ExternalDomainRegister, path: route.store().externalDomain },
    { component: SettingSitemap, path: route.store().sitemap }
  ],

  store: [
    { component: Dashboard, path: route.dashboard },
    { component: VersionsIndex, path: route.versionsIndex },
    { component: VersionsNew, path: route.versionsNew },
    { component: VersionsShow, path: route.versionsShow },
    { component: SourceEdit, path: route.sourceEdit() },
    { component: NewsIndex, path: route.newsIndex },
    { component: NewsEdit, path: route.newsEdit },
    { component: NewsNew, path: route.newsNew },
    { component: NewsTemplate, path: route.newsTemplate },
    { component: NewsCategory, path: route.newsCategory },
    { component: ArticleIndex, path: route.articleIndex },
    { component: ArticleEdit, path: route.articleEdit },
    { component: ArticleNew, path: route.articleNew },
    { component: ArticleTemplate, path: route.articleTemplate },
    { component: ArticleCategory, path: route.articleCategory },
    { component: MediaIndex, path: route.mediaIndex },
    { component: MediaNew, path: route.mediaNew },
    { component: SettingAccount, path: route.settingAccountStore },
    { component: SettingStore, path: route.settingStore },
    { component: SettingSite, path: route.settingSite },
    { component: Analysis, path: route.analysis },
    { component: Contacts, path: route.contacts },
    { component: FormIndex, path: route.formIndex },
    { component: FormDetail, path: route.formNew },
    { component: FormDetail, path: route.formEdit },
    { component: Applicants, path: route.applicants },
    { component: JobOfferIndex, path: route.jobOfferIndex },
    { component: JobOfferDetail, path: route.jobOfferNew },
    { component: JobOfferDetail, path: route.jobOfferEdit },
    { component: PasswordUpdate, path: route.passwordUpdateStore },
    { component: NotificationShow, path: route.notificationsShow },
    { component: SeoPreference, path: route.seoPreference }
  ]
};
