import React from "react";

import { isAgency } from "../../utils/localStorage";
import api from "../../api";
import { awsState, awsStatus } from "../../utils/awsState";

import { GridWrap, AppWrap, MainContentsWrap } from "../atoms/Wrap";
import { DomainVerifyNotice, SiteUploadingNotice } from "../atoms/Notice";
import Header from "../organisms/Header";
import Sidebar from "../organisms/Sidebar";

export const AgencyContainer = ({ children }) => {
  return (
    <>
      <Header />
      <MainContentsWrap>{children}</MainContentsWrap>
    </>
  );
};

export const DashboardContainer = ({ children }) => {
  const { noGetDomain, ready } = awsStatus;
  const [status, setStatus] = React.useState(awsState["setup_completed"]);

  React.useEffect(() => {
    verifyDomain();
    return setStatus(awsState["setup_completed"]);
  }, []);

  const verifyDomain = async () => {
    const store = isAgency()
      ? await api.stores.current().catch(e => {
          e.json().then(err => console.log(err));
        })
      : await api.user.me().catch(e => {
          e.json().then(err => console.log(err));
        });

    const currentAwsState =
      store && isAgency() ? store.aws_state : store.affiliation.aws_state;
    return setStatus(awsState[currentAwsState]);
  };

  return (
    <>
      <Header />
      <GridWrap showSidebar>
        <Sidebar />
        <AppWrap>
          {noGetDomain.includes(status) && <DomainVerifyNotice />}
          {ready.includes(status) && <SiteUploadingNotice />}
          {children}
        </AppWrap>
      </GridWrap>
    </>
  );
};

export default AgencyContainer;
