import React from "react";
import api from "../../api";

import MediaNew from "../templates/MediaNew";

const allowExtentionList = ["jpg", "jpeg", "png", "gif"];

export default class extends React.Component {
  state = {
    files: [],
    loading: false,
    status: 0,
    uploadErrorList: [],
    successMessage: ""
  };

  dropChange = files => this.setState({ files });

  uploadMedia = () => {
    const { files } = this.state;
    try {
      files.forEach(file => {
        if (
          !allowExtentionList.includes(
            file.name
              .split(".")
              .pop()
              .toLowerCase()
          )
        ) {
          this.setState({
            errorMessage: "対応していない形式のファイルが含まれています。"
          });
          throw new Error();
        }
      });
    } catch (e) {
      return false;
    }

    this.setState({
      loading: true,
      uploadErrorList: [],
      successMessage: "アップロードが完了しました。",
      errorMessage: ""
    });

    files.forEach(file => {
      const formData = new FormData();
      formData.append("image[file]", file);

      api.media
        .create(formData)
        .then(() => {
          files.length === this.state.status + 1
            ? this.setState({ files: [], loading: false, status: 0 })
            : this.setState({ status: this.state.status + 1 });
        })
        .catch(() => {
          files.length === this.state.status + 1
            ? this.setState({
                files: [],
                loading: false,
                status: 0,
                successMessage: "",
                uploadErrorList: [...this.state.uploadErrorList, file.name]
              })
            : this.setState({
                uploadErrorList: [...this.state.uploadErrorList, file.name],
                status: this.state.status + 1,
                successMessage: ""
              });
        });
    });
  };

  render() {
    return (
      <MediaNew
        {...this.state}
        dropChange={this.dropChange}
        uploadMedia={this.uploadMedia}
      />
    );
  }
}
