import route from "./route";
import agencyList from "./agencyList.json";

import {
  faTachometerAlt,
  faFileAlt,
  faImage,
  faGlobe,
  faCog,
  faPenNib,
  faBell,
  faAddressCard,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

export const agencySidebarPath = storeId => [
  {
    name: "ダッシュボード",
    icon: faTachometerAlt,
    to: route.store(storeId).dashboard
  },
  {
    name: "バージョン管理",
    icon: faFileAlt,
    subMenu: [
      {
        name: "バージョン一覧",
        to: route.store(storeId).versionsIndex
      },
      {
        name: "ソースアップロード",
        to: route.store(storeId).versionsNew
      }
    ]
  },
  {
    name: "お知らせ管理",
    icon: faBell,
    subMenu: [
      { name: "お知らせ一覧", to: route.store(storeId).newsIndex },
      {
        name: "テンプレート設定",
        to: route.store(storeId).newsTemplate
      },
      { name: "カテゴリ管理", to: route.store(storeId).newsCategory }
    ]
  },
  {
    name: "記事管理",
    icon: faPenNib,
    subMenu: [
      { name: "記事一覧", to: route.store(storeId).articleIndex },
      {
        name: "テンプレート設定",
        to: route.store(storeId).articleTemplate
      },
      { name: "カテゴリ管理", to: route.store(storeId).articleCategory }
    ]
  },
  {
    name: "メディア管理",
    icon: faImage,
    subMenu: [
      { name: "メディア一覧", to: route.store(storeId).mediaIndex },
      { name: "メディアアップロード", to: route.store(storeId).mediaNew }
    ]
  },
  {
    name: "サイト管理",
    icon: faGlobe,
    subMenu: [
      { name: "ドメイン取得", to: route.store(storeId).domainCheck },
      { name: "サイト解析", to: route.store(storeId).analysis },
      { name: "サイトマップ設定", to: route.store(storeId).sitemap },
      {
        name: "OGPデフォルト値設定",
        to: route.store(storeId).seoPreference
      },
      {
        name: "各種設定",
        to: route.store(storeId).settingSite
      }
    ]
  },
  {
    name: "問い合わせ管理",
    icon: faEnvelope,
    subMenu: [
      {
        name: "問い合わせ一覧",
        to: route.store(storeId).contacts
      },
      {
        name: "フォーム一覧",
        to: route.store(storeId).formIndex
      }
    ]
  },
  {
    name: "求人管理",
    icon: faAddressCard,
    subMenu: [
      {
        name: "応募者一覧",
        to: route.store(storeId).applicants
      },
      {
        name: "求人票一覧",
        to: route.store(storeId).jobOfferIndex
      }
    ]
  },
  {
    name: "店舗情報",
    to: route.store(storeId).settingStore,
    icon: faCog
  }
];

export const storeSidebarPath = () => [
  {
    name: "ダッシュボード",
    icon: faTachometerAlt,
    to: route.dashboard
  },
  {
    name: "バージョン管理",
    icon: faFileAlt,
    subMenu: [
      {
        name: "バージョン一覧",
        to: route.versionsIndex
      },
      {
        name: "ソースアップロード",
        to: route.versionsNew
      }
    ]
  },
  {
    name: "お知らせ管理",
    icon: faBell,
    subMenu: [
      { name: "お知らせ一覧", to: route.newsIndex },
      {
        name: "テンプレート設定",
        to: route.newsTemplate
      },
      { name: "カテゴリ管理", to: route.newsCategory }
    ]
  },
  {
    name: "記事管理",
    icon: faPenNib,
    subMenu: [
      { name: "記事一覧", to: route.articleIndex },
      {
        name: "テンプレート設定",
        to: route.articleTemplate
      },
      { name: "カテゴリ管理", to: route.articleCategory }
    ]
  },
  {
    name: "メディア管理",
    icon: faImage,
    subMenu: [
      { name: "メディア一覧", to: route.mediaIndex },
      { name: "メディアアップロード", to: route.mediaNew }
    ]
  },
  {
    name: "サイト管理",
    icon: faGlobe,
    subMenu: [
      { name: "サイト解析", to: route.analysis },
      {
        name: "OGPデフォルト値設定",
        to: route.seoPreference
      },
      {
        name: "各種設定",
        to: route.settingSite
      }
    ]
  },
  {
    name: "問い合わせ管理",
    icon: faGlobe,
    subMenu: [
      {
        name: "問い合わせ一覧",
        to: route.contacts
      },
      {
        name: "フォーム一覧",
        to: route.formIndex
      }
    ]
  },
  {
    name: "求人管理",
    icon: faAddressCard,
    subMenu: [
      {
        name: "応募者一覧",
        to: route.applicants
      },
      {
        name: "求人票一覧",
        to: route.jobOfferIndex
      }
    ]
  },
  {
    name: "店舗情報",
    to: route.settingStore,
    icon: faCog
  }
];

const checkOptions = sidebarPath => {
  const hostname = window.location.hostname;
  const useRecruit = agencyList[hostname].options.recruit;
  const useSitest = agencyList[hostname].options.sitest;
  // const useContact = agencyList[hostname].options.contact;

  if (!useRecruit) {
    sidebarPath = sidebarPath.filter(path => path.name !== "求人管理");
  }

  if (!useSitest) {
    sidebarPath = sidebarPath.map(path => {
      if (path.name === "サイト管理") {
        return {
          ...path,
          subMenu: path.subMenu.filter(p => p.name !== "サイト解析")
        };
      } else {
        return path;
      }
    });
  }

  // TODO: お問い合わせオプション化あとで
  // if (!useContact) {
  //   sidebarPath = sidebarPath.filter(path => path.name !== "問い合わせ管理");
  // }

  return sidebarPath;
};

export default (isAgency, storeId) => {
  let sidebarPath = isAgency ? agencySidebarPath(storeId) : storeSidebarPath();
  sidebarPath = checkOptions(sidebarPath);
  return sidebarPath;
};
