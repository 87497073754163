import React from "react";
import styled from "styled-components";

import route from "../../utils/route";

import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import Button from "../atoms/Button";
import LinkText from "../atoms/LinkText";
import InputLabel from "../molecules/InputLabel";
import WelcomePageBox from "../organisms/WelcomePageBox";

const Heading = styled.h1`
  font-size: 26px;
`;

export default ({
  toggleUserType,
  handleChange,
  userType,
  email,
  password,
  signIn,
  errorMessage
}) => {
  const isAgency = userType === "agency";

  return (
    <WelcomePageBox userType={userType}>
      <div>
        <form onSubmit={e => e.preventDefault()}>
          <Heading>{isAgency ? "代理店" : "店舗"}ログイン</Heading>
          <Typography margin="5px 0 0">Sign In to your account</Typography>
          <Wrap padding="20px 0">
            <InputLabel
              name="email"
              label="メールアドレス"
              value={email}
              onChange={handleChange}
              errorMessage={errorMessage.email}
            />
            <InputLabel
              name="password"
              type="password"
              label="パスワード"
              value={password}
              onChange={handleChange}
              errorMessage={errorMessage.password}
            />
            <Wrap justifyContent="flex-end" margin="5px 0">
              <LinkText
                to={isAgency ? route.signInStore : route.signInAgency}
                style={{ textAlign: "right" }}
                onClick={toggleUserType}>
                {isAgency ? "店舗" : "代理店"}の方はこちら
              </LinkText>
            </Wrap>
            <Wrap justifyContent="flex-end" margin="5px 0">
              <LinkText
                to={route.passwordResetRequest}
                style={{ textAlign: "right" }}>
                パスワードをお忘れの方
              </LinkText>
            </Wrap>
          </Wrap>

          <Wrap justifyContent="center">
            <Button onClick={signIn}>ログイン</Button>
          </Wrap>
        </form>
      </div>
    </WelcomePageBox>
  );
};
