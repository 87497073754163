import React from "react";

import color from "../../utils/color";
import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";
import TopColoredButton from "../atoms/TopColoredButton";

export default ({ newAccount }) => {
  return (
    <Wrap padding="20px">
      <TitleBox title="機能ショートカット">
        <TopColoredButton newAccount={newAccount}>サイト解析</TopColoredButton>
      </TitleBox>
    </Wrap>
  );
};
