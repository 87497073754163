import React from "react";
import styled from "styled-components";

import color from "../../utils/color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const SquareButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${color.typoLight};
  margin: ${(props) => props.margin};
  box-sizing: border-box;
  color: ${color.typoLight};
  transition: 0.15s;
  cursor: pointer;

  :hover {
    border-color: ${color.blue};
    color: ${color.blue};
  }
`;

export default ({ margin, tip, onClick, disabled, ...props }) => {
  return (
    <SquareButton
      margin={margin}
      data-tip={tip}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon size="lg" {...props} />
      <ReactTooltip effect="solid" place="top" delayShow={200} />
    </SquareButton>
  );
};
