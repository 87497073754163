import React from "react";
import { Link } from "react-router-dom";

import route from "../../utils/route";
import api from "../../api";

import { faEye } from "@fortawesome/free-solid-svg-icons";

import Loading from "../atoms/Loading";
import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import IconButton from "../atoms/IconButton";
import Table from "../molecules/Table";

export default class extends React.Component {
  state = { staffTableData: [], loading: true };

  componentDidMount() {
    api.staff.index().then((staff) => {
      const staffTableData =
        staff &&
        staff.map((s) => [
          s.id,
          s.name,
          s.email,
          s.role === "admin" ? "管理者" : "スタッフ",
          <Link to={{ pathname: route.agencyStaffShow, state: { staff: s } }}>
            <IconButton icon={faEye} tip="スタッフ情報を見る" />
          </Link>
        ]);

      this.setState({ staffTableData, loading: false });
    });
  }

  render() {
    const { staffTableData, loading } = this.state;

    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Wrap justifyContent="flex-end" margin="0 0 10px">
              <Link to={route.agencyStaffNew}>
                <Button>スタッフ新規作成</Button>
              </Link>
            </Wrap>
            <TitleBox title="代理店スタッフ一覧">
              <Table
                colWidths={["50px", "auto", "auto", "auto", "80px"]}
                tableHeads={[
                  <>
                    代理店
                    <br />
                    スタッフID
                  </>,
                  "名前",
                  "メールアドレス",
                  "権限",
                  "操作"
                ]}
                tableDatas={staffTableData}
              />
            </TitleBox>
          </div>
        )}
      </>
    );
  }
}
