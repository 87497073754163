import React from "react";
import PostCategory from "../templates/PostCategory";
import postCategoryActions from "../../utils/postCategoryActions";

export default class extends React.Component {
  state = {
    categories: [],
    category: "",
    message: {
      success: ""
    }
  };

  handleChangeCategoryName = (id, name) => {
    const { categories } = this.state;
    const changeedCategories = postCategoryActions().handleChangeCategoryName(
      categories,
      id,
      name
    );

    this.setState({
      categories: changeedCategories
    });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  fetch = async message => {
    const states = await postCategoryActions().fetch();
    this.setState({ ...states, message: { success: message } });
  };

  create = () => {
    const { category } = this.state;

    postCategoryActions()
      .create(category)
      .then(() => {
        this.fetch("新規カテゴリーが追加されました");
      });
  };

  update = (subcategory_name, subcategory_value) => {
    postCategoryActions()
      .update(subcategory_name, subcategory_value)
      .then(() => {
        this.fetch("カテゴリーが更新されました");
      });
  };

  destroy = subcategory_value => {
    postCategoryActions()
      .destroy(subcategory_value)
      .then(() => {
        this.fetch("1件のカテゴリーが削除されました");
      });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    return (
      <PostCategory
        {...this.state}
        create={this.create}
        update={this.update}
        destroy={this.destroy}
        handleChange={this.handleChange}
        handleChangeCategoryName={this.handleChangeCategoryName}
      />
    );
  }
}
