import React, { useState, useEffect } from "react";
import styled from "styled-components";
import color from "../../utils/color";
import { unixtimeToDate } from "../../utils/common";
import Wrap from "../atoms/Wrap";
import Textarea from "../atoms/Textarea";
import TitleBox from "../atoms/TitleBox";
import Typography from "../atoms/Typography";
import Loading from "../atoms/Loading";
import SelectBox from "../atoms/SelectBox";
import Button from "../atoms/Button";
import ErrorMessage from "../atoms/ErrorMessage";
import Table from "../molecules/Table";
import Modal from "../molecules/Modal";
import ApplicantsFilterRow from "../molecules/ApplicantsFilterRow";
// import NoImage from "../../img/no-image.png";

export default ({
  applicants,
  loading,
  changeStatus,
  status,
  filterText,
  handleChange,
  jobOptions,
  activeJob,
  updateStatus,
  loadMore,
  handleClickUpdateApplicant,
  errRemarks,
  hasNextApplicants,
}) => {
  return loading ? (
    <Loading label={"応募者一覧を取得中"} />
  ) : (
    <Wrap padding="20px">
      <ApplicantsFilterRow
        changeStatus={changeStatus}
        length={applicants.length}
        status={status}
        filterText={filterText}
        handleChange={handleChange}
        jobOptions={jobOptions}
        activeJob={activeJob}
      />
      <TitleBox title="応募者一覧">
        {applicants.length === 0 ? (
          <Typography textAlign="center" margin="20px 0 0">
            応募がありません。
          </Typography>
        ) : (
          <>
            <Table
              colWidths={["50px"]}
              tableHeads={[
                "ID",
                "応募者",
                "メールアドレス",
                "携帯電話番号",
                "希望職種",
                "応募日",
                "対応状況",
                "メモ",
              ]}
              tableDatas={tableDatas(
                applicants,
                updateStatus,
                handleClickUpdateApplicant,
                errRemarks
              )}
            />
            {hasNextApplicants && (
              <Wrap justifyContent="center" margin="15px 0 0">
                <Button onClick={loadMore}>さらに読み込む</Button>
              </Wrap>
            )}
          </>
        )}
      </TitleBox>
    </Wrap>
  );
};

const TwoStepsCell = ({ first, second, thumbnail, className }) => (
  <Wrap alignItems="center" className={className}>
    {/* サムネイルを実装する場合 */}
    {/* {thumbnail && (
      <img
        src={NoImage}
        alt="画像抜き"
        height={50}
        style={{ borderRadius: "50%", marginRight: 20 }}
      />
    )} */}
    <div>
      <Typography
        color={color.blue}
        bold
        size="16px"
        style={{ marginBottom: 5 }}
      >
        {first}
      </Typography>
      <Typography>{second}</Typography>
    </div>
  </Wrap>
);

const TwoStepsCellWithWrap = styled(TwoStepsCell)`
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
`;

const statusOptions = [
  { label: "未対応", value: "pending" },
  { label: "選考中", value: "in_progress" },
  { label: "保留", value: "on_hold" },
  { label: "採用", value: "completed" },
  { label: "不採用", value: "rejected" },
  { label: "アーカイブ", value: "archived" },
];

const tableDatas = (
  applicants,
  updateStatus,
  handleClickUpdateApplicant,
  errRemarks
) => {
  return (
    applicants &&
    applicants.map((applicant, i) => {
      const status = statusOptions.find(
        (option) => option.value === applicant.status
      );
      return [
        i + 1,
        <TwoStepsCellWithWrap
          thumbnail
          first={applicant.name}
          second={`${applicant.age} / ${applicant.currentJob} / ${applicant.address}`}
        />,
        applicant.email,
        applicant.phone,
        <TwoStepsCellWithWrap
          first={applicant.desiredJob}
          second={applicant.recruitType || "-"}
        />,
        unixtimeToDate(applicant.created_at),
        <SelectBox
          options={statusOptions}
          value={status ? status.value : "pending"}
          onChange={(e) => updateStatus(applicant.contact_idef, e.target.value)}
        />,
        <RemarksForm
          contact_idef={applicant.contact_idef}
          contact_remarks={applicant.remarks}
          handleClickUpdateApplicant={handleClickUpdateApplicant}
          errRemarks={errRemarks}
        />,
      ];
    })
  );
};

const RemarksForm = ({
  contact_idef,
  contact_remarks,
  handleClickUpdateApplicant,
  errRemarks,
}) => {
  const [open, setOpen] = useState(false);
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    setRemarks(contact_remarks || "");
  }, [contact_remarks]);
  return (
    <>
      <Modal title="メモ" show={open} close={() => setOpen(false)}>
        <Textarea
          style={{ marginBottom: 10, resize: "none" }}
          onChange={(e) => setRemarks(e.target.value)}
          value={remarks}
        />
        <div style={{ textAlign: "center" }}>
          {errRemarks.errRemarks && errRemarks.contact_idef === contact_idef ? (
            <ErrorMessage errorMessage="※正常に送信されませんでした.もう一度送信してください." />
          ) : (
            <></>
          )}
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              handleClickUpdateApplicant(contact_idef, remarks);
              setOpen(false);
            }}
          >
            {remarks ? "更新" : "作成"}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            閉じる
          </Button>
        </div>
      </Modal>
      <Button
        backgroundColor={remarks ? color.blue : color.gray}
        onClick={() => setOpen(true)}
      >
        {remarks ? "確認" : "作成"}
      </Button>
    </>
  );
};
