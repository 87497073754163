import React from "react";
import _ from "lodash";
import dayjs from "dayjs";

import postActions from "../../utils/postActions";

import PostEditor from "../templates/PostEditor";
import { noInputCheck, validDateFormatCheck, validDateByNow } from "../../utils/inputCheck";
import { getNow } from "../../utils/day";

let maxScroll = 600

export default class extends React.Component {
  state = {
    post: {},
    categories: [],
    medias: [],
    loadedPage: 1,
    selectedThumbnailId: 0,
    loading: true,
    template: "",
    seo_tag: {
      doc: {
        title: "",
        description: "",
        keywords: ""
      },
      og: {
        title: "",
        description: "",
        image: "",
        site_name: ""
      },
      twitter: {
        title: "",
        description: "",
        image: "",
        card: ""
      }
    },
    createdAt: getNow(),
    errorMessage: { title: "", slug: "", createdAt: "" }
  };

  componentDidMount = async () => {
    const { filepath } = this.props.location.state.post;
    const [post, seo_tag, createdAt] = await postActions(false).fetchPost(filepath);
    const categories = await postActions(false).fetchCategories();
    const template = await postActions(false).fetchTemplate();
    this.fetchMedias();
    window.addEventListener("scroll", this.handleScrollMedias, true);

    this.setState({ post, categories, seo_tag, template, loading: false, createdAt });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollMedias);
  }

  fetchMedias = async () => {
    const { medias, loadedPage } = this.state;
    const newMedias = await postActions(false).fetchMedias(loadedPage);

    Array.prototype.push.apply(medias, newMedias);

    this.setState({ medias });
  };

  handleChange = e => {
    const { post } = this.state;
    this.setState({ post: { ...post, [e.target.name]: e.target.value } });
  };

  handleCreatedAtChange = (newDate, newTime) => {
    this.setState({
      createdAt: {
        dateString: newDate,
        timeString: newTime
      }
    })
  }

  handleChangeSeoTag = e => {
    let { seo_tag } = this.state;
    const [seoType, property] = e.target.name.split(":");
    seo_tag[seoType][property] = e.target.value;
    this.setState({ seo_tag });
  };

  handleImageChangeSeoTag = (seoType, imageUrl) => {
    let { seo_tag } = this.state;
    seo_tag[seoType].image = imageUrl;
    this.setState({ seo_tag });
  };

  handleChangeThumbnail = (selectedThumbnailId, thumbnail_url) => {
    const { post } = this.state;
    this.setState({ selectedThumbnailId, post: { ...post, thumbnail_url } });
  };

  insertImage = imageUrl => {
    const { post } = this.state;
    // TODO: 引数 or stateでalt属性を追加したい
    const imgTag = `<img src="${imageUrl}" alt="sample" />`;
    this.setState({ post: { ...post, content: `${post.content}${imgTag}` } });
  };

  setErrorMessage = (message, target) => {
    this.setState({ errorMessage: { [target]: message } });
  };

  update = () => {
    const { post, seo_tag, createdAt } = this.state;
    const { title } = post;
    const createdAtString = `${createdAt.dateString} ${createdAt.timeString}`;

    try {
      noInputCheck(title, this.setErrorMessage, "title");
      validDateFormatCheck(createdAtString, this.setErrorMessage, "createdAt");
      validDateByNow(createdAtString, this.setErrorMessage, "createdAt"); 
    } catch (e) {
      return false;
    }

    const unixCreatedAt = dayjs(createdAtString).unix();

    postActions(false).update(post, seo_tag, unixCreatedAt);
  };

  handleScrollMedias = _.throttle(e => {
    if (e.target.scrollTop > this.state.loadedPage * maxScroll) {
      this.setState({ loadedPage: this.state.loadedPage + 1 });
      this.fetchMedias();
    }
  }, 500);

  render() {
    const { filepath } = this.props.location.state.post;

    return (
      <PostEditor
        {...this.state}
        handleChange={this.handleChange}
        handleCreatedAtChange={this.handleCreatedAtChange}
        handleChangeThumbnail={this.handleChangeThumbnail}
        submit={this.update}
        insertImage={this.insertImage}
        deletePost={() => postActions(false).delete(filepath)}
        handleChangeSeoTag={this.handleChangeSeoTag}
        handleImageChangeSeoTag={this.handleImageChangeSeoTag}
      />
    );
  }
}
