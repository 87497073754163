import React from "react";

import api from "../../api";

import Loading from "../atoms/Loading";
import Dashboard from "../templates/Dashboard";
import { isAgency } from "../../utils/localStorage";
import sitestAutoLogin from "../../utils/sitestAutoLogin";

const autoLogin = true;
const loginUrlsEnv = {
  // NOTE: `dev.sitest.jp`` has IP address based ACL.
  development: "http://dev.sitest.jp/users/teory_login",
  production: "https://sitest.jp/users/teory_login"
};
let loginUrl;
if (
  process.env.NODE_ENV === "development" ||
  window.location.host === "staging.ccmphp.com"
) {
  loginUrl = loginUrlsEnv.development;
} else {
  loginUrl = loginUrlsEnv.production;
}

export default class extends React.Component {
  state = {
    loading: true,
    notifications: [],
    gaData: {},
    loading: false,
    errorMessage: null,
    account: {
      use_sitest: null,
      sitest_shop_id: null,
      sitest_id: null
    },
    headVersion:"",
    sources:[]

  };

  fetchNotifications = async () => {
    const notifications = await api.notification
      .dashboardIndex()
      .catch(e => console.error(e));

    this.setState({ notifications, loading: false });
  };

  fetchGoogleAnalyticsData = async () => {
    await api.googleAnalytics.show()
      .then(res => {
        this.setState({ gaData: res.context })
      })
      .catch(e => {
        console.error(e);
      });
  };

  newAccount = async () => {
    this.setState({ loading: true });
    const account = await api.sitest.account().catch((e) => {
      console.error(e);
    });
    if (account.use_sitest && !account.sitest_id) {
      account = await api.sitest.create().catch((e) => {
        this.setState({
          loading: false,
          errorMessage: "問題が発生しました。運営者にお問い合わせください。"
        });
        console.error(e);
      });
    }

    this.setState({ loading: false, account });
    if (account && account.use_sitest && autoLogin) {
      sitestAutoLogin(loginUrl, account.sitest_shop_id, account.sitest_id);
    }
  };
  
  fetch = async () => {
    const sources = await api.versions.index().catch(e => {
      e.json().then(err => console.log(err));
    });
    const store = await (isAgency()
      ? api.stores.current()
      : api.user.me()
    ).catch(e => {
      e.json().then(err => console.log(err));
    });

    // const hpDomain = store && store.aws_resource.hp_domain;
    const headVersion =
      store && isAgency() ? store.head_version : store.affiliation.head_version;

    this.setState({ sources, headVersion, loading: false });
  };

  componentDidMount() {
    this.fetchNotifications();
    this.fetchGoogleAnalyticsData();
    this.fetch();
  }

  render() {
    const { loading } = this.state;
    return loading ? (
      <Loading />
    ) : (
      <Dashboard {...this.state} newAccount={this.newAccount} />
    );
  }
}
