import React from "react";
import Button from "../atoms/Button";
import Wrap from "../atoms/Wrap";
import Input from "../atoms/Input"

export default ({ update, handleTitleChange, title }) => {
  return (
    <Wrap justifyContent="flex-end" alginItems="center" margin="0 0 15px">
      <Wrap margin="auto 10px auto auto" width="100%">
        <Input
          name="title"
          placeholder="コメント（編集の概要や修正内容など）"
          onChange={handleTitleChange}
          value={title}
        />
      </Wrap>
      <Button onClick={update} style={{ marginLeft: 10 }}>
        保存
      </Button>
    </Wrap>
  );
};
