import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import color from "../../utils/color";
import { isAgency, storeId } from "../../utils/localStorage";
import route from "../../utils/route";

import TitleBox from "../atoms/TitleBox";
import Typography from "../atoms/Typography";
import Wrap from "../atoms/Wrap";
import Loading from "../atoms/Loading";
import Button from "../atoms/Button";
import FileImg from "../atoms/FileImg";
import DateTimeInput from "../molecules/DateTimeInput";
import InputLabel from "../molecules/InputLabel";
import SelectBoxLabel from "../molecules/SelectBoxLabel";
import Modal from "../molecules/Modal";
import DocumentWysiwygEditor from "../molecules/DocumentWysiwygEditor";
import CheckBoxLabel from "../molecules/CheckBoxLabel";
import SeoTagForm from "./SeoTagForm";
import Navigation from "../molecules/Navigation";
import {
  ArticlePreviewModal,
  NewsPreviewModal,
} from "../organisms/PostPreviewModal";

const Thumbnail = styled.div`
  width: 300px;
  height: 225px;
  cursor: pointer;
  transition: 0.15s;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;

  :hover {
    opacity: 0.7;
  }
`;

const ThumbnailScrollWrap = styled.div`
  overflow-y: scroll;
  height: 300px;
  width: 610px;
  margin: auto;
`;

const ThumbnailGridWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SelectThumbnailModal = ({
  loading,
  medias,
  selectedThumbnailId,
  handleChangeThumbnail,
  closeModal,
  ...props
}) => {
  return (
    <Modal {...props}>
      {loading ? (
        <Loading />
      ) : (
        <Wrap maxHeight="50vh" style={{ overflowY: "scroll" }}>
          {medias &&
            medias.map((m) => (
              <FileImg
                key={m.id}
                src={m.url}
                selected={m.id === selectedThumbnailId}
                onClick={() => handleChangeThumbnail(m.id, m.url)}
              />
            ))}
        </Wrap>
      )}
      <Wrap justifyContent="flex-end" margin="10px 0 0">
        <Button
          style={{ marginRight: 10 }}
          onClick={() => {
            closeModal();
            handleChangeThumbnail(0, "");
          }}
        >
          キャンセル
        </Button>
        <Button onClick={closeModal}>決定</Button>
      </Wrap>
    </Modal>
  );
};

const InsertImageModal = ({ medias, insertImage, closeModal, ...props }) => {
  return (
    <Modal closeModal={closeModal} {...props}>
      {medias.length !== 0 ? (
        <ThumbnailScrollWrap>
          <ThumbnailGridWrap>
            {medias.map((m, i) => (
              <Thumbnail
                onClick={() => {
                  insertImage(m.url);
                  closeModal();
                }}
                src={m.url}
                key={i}
                alt={`medias${i}`}
                style={{
                  marginBottom: "10px",
                  marginRight: i % 2 === 0 ? "10px" : 0,
                }}
              />
            ))}
          </ThumbnailGridWrap>
        </ThumbnailScrollWrap>
      ) : (
        "画像がありません。メディア管理から画像をアップロードしてください。"
      )}
      <Wrap justifyContent="center" margin="30px 0 0">
        <Button onClick={closeModal}>閉じる</Button>
      </Wrap>
    </Modal>
  );
};

const SnsCheckBox = ({ sns, provider, handleCheck }) => {
  const upperCaseProvider =
    provider.charAt(0).toUpperCase() + provider.slice(1);
  return (
    <CheckBoxLabel
      margin="15px 15px 5px 0"
      checked={sns[provider]}
      label={`${upperCaseProvider}に投稿する`}
      onClick={() => handleCheck(provider)}
    />
  );
};

const EditForm = ({
  post,
  categories,
  socialAccounts,
  handleChange,
  isCreate,
  errorMessage,
  sns,
  handleCheck,
  verifiedDomain,
  setShowImageModal,
  createdAt,
  handleCreatedAtChange,
}) => {
  return (
    <>
      <InputLabel
        required
        name="title"
        label="タイトル"
        value={post.title}
        onChange={handleChange}
        errorMessage={errorMessage.title}
      />
      <DateTimeInput
        label="投稿日時"
        dateValue={createdAt.dateString}
        timeValue={createdAt.timeString}
        handleCreatedAtChange={handleCreatedAtChange}
        errorMessage={errorMessage.createdAt}
      />
      <SelectBoxLabel
        label="カテゴリ"
        name="subcategory"
        options={categories}
        value={post.subcategory}
        onChange={handleChange}
      />
      <InputLabel
        name="slug"
        label="スラグ"
        value={post.slug}
        onChange={handleChange}
        disabled={!isCreate}
        errorMessage={errorMessage.slug}
      />
      {isCreate && verifiedDomain && socialAccounts.length > 0 && (
        <Wrap>
          <Typography>SNS投稿</Typography>
          <Wrap justifyContent="start">
            {socialAccounts.map((account, index) => (
              <SnsCheckBox
                key={index}
                sns={sns}
                provider={account.provider}
                handleCheck={handleCheck}
              />
            ))}
          </Wrap>
        </Wrap>
      )}
      <Wrap justifyContent="flex-end" margin="0 0 10px">
        <Button onClick={() => setShowImageModal(true)}>画像挿入</Button>
      </Wrap>
      <DocumentWysiwygEditor
        label="本文"
        value={post.content}
        name="content"
        onChange={handleChange}
      />
    </>
  );
};

export default ({
  post,
  medias,
  categories,
  socialAccounts,
  selectedThumbnailId,
  loading,
  handleChange,
  handleCreatedAtChange,
  handleChangeThumbnail,
  insertImage,
  submit,
  deletePost,
  isArticle,
  isCreate,
  errorMessage,
  sns,
  handleCheck,
  verifiedDomain,
  handleChangeSeoTag,
  handleImageChangeSeoTag,
  seo_tag,
  template,
  createdAt,
}) => {
  const [showThumbnailModal, setShowThumbnailModal] = React.useState(false);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [showPreviewModal, setShowPreviewModal] = React.useState(false);

  const funcName =
    (isArticle ? "記事" : "お知らせ") + (isCreate ? "新規投稿" : "編集");
  const indexPath = isArticle
    ? isAgency()
      ? route.store(storeId()).articleIndex
      : route.articleIndex
    : isAgency()
    ? route.store(storeId()).newsIndex
    : route.newsIndex;

  const PostPreviewModal = isArticle ? ArticlePreviewModal : NewsPreviewModal;

  return (
    <Wrap padding="20px">
      <SelectThumbnailModal
        loading={loading}
        medias={medias}
        selectedThumbnailId={selectedThumbnailId}
        handleChangeThumbnail={handleChangeThumbnail}
        closeModal={() => setShowThumbnailModal(false)}
        show={showThumbnailModal}
        title="サムネイル設定"
      />
      <InsertImageModal
        insertImage={insertImage}
        closeModal={() => setShowImageModal(false)}
        show={showImageModal}
        medias={medias}
        title="画像挿入"
      />
      <PostPreviewModal
        show={showPreviewModal}
        closeHandler={() => setShowPreviewModal(false)}
        template={template}
        title={post.title}
        contentString={post.content}
      />
      <Wrap>
        <Link to={indexPath}>
          <Button>一覧へ</Button>
        </Link>
      </Wrap>
      <Wrap margin="10px 0 0 0">
        {loading ? (
          <Loading />
        ) : (
          <TitleBox title={funcName}>
            <Wrap justifyContent="flex-end" margin="0 0 10px">
              <Button
                style={{ marginRight: 10 }}
                onClick={() => setShowPreviewModal(true)}
              >
                プレビュー
              </Button>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => setShowThumbnailModal(true)}
              >
                サムネイル
              </Button>
              <Button onClick={submit}>{isCreate ? "公開" : "更新"}</Button>
              {!isCreate && (
                <Button
                  style={{ marginLeft: 10 }}
                  backgroundColor={color.red}
                  onClick={deletePost}
                >
                  削除
                </Button>
              )}
            </Wrap>
            <Navigation
              navs={[
                {
                  label: "記事内容",
                  component: (
                    <EditForm
                      post={post}
                      createdAt={createdAt}
                      categories={categories}
                      socialAccounts={socialAccounts}
                      handleChange={handleChange}
                      handleCreatedAtChange={handleCreatedAtChange}
                      isCreate={isCreate}
                      errorMessage={errorMessage}
                      sns={sns}
                      handleCheck={handleCheck}
                      verifiedDomain={verifiedDomain}
                      setShowImageModal={setShowImageModal}
                    />
                  ),
                },
                {
                  label: "SEO設定",
                  component: (
                    <SeoTagForm
                      seo_tag={seo_tag}
                      medias={medias}
                      handleChange={handleChangeSeoTag}
                      handleImageChange={handleImageChangeSeoTag}
                    />
                  ),
                },
              ]}
            />
          </TitleBox>
        )}
      </Wrap>
    </Wrap>
  );
};
