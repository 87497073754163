import React from "react";
import { withRouter } from "react-router-dom";

import color from "../../utils/color";
import TitleBox from "../atoms/TitleBox";
import Button from "../atoms/Button";
import Wrap from "../atoms/Wrap";
import Note from "../atoms/Note";
import InputLabel from "../molecules/InputLabel";
import DocumentWysiwygEditor from "../molecules/DocumentWysiwygEditor";

const titleDict = {
  new: "新規お知らせ投稿",
  edit: "お知らせ編集",
  show: "お知らせ内容詳細",
};

const Notification = ({
  title,
  content,
  create,
  update,
  deleteNotification,
  handleChange,
  history,
  errorMessage,
  pageType,
}) => {
  const isShow = pageType === "show";
  const isEdit = pageType === "edit";
  return (
    <Wrap width="80%" margin="auto">
      <Wrap margin="0 0 15px">
        <Button onClick={() => history.goBack()}>戻る</Button>
      </Wrap>
      <TitleBox title={titleDict[pageType]}>
        <InputLabel
          label="タイトル"
          name="title"
          value={title}
          onChange={handleChange}
          readOnly={isShow}
        />
        <DocumentWysiwygEditor
          label="本文"
          name="content"
          value={content}
          onChange={handleChange}
          readOnly={isShow}
        />
        {!isShow && (
          <>
            <Note style={{ margin: "20px 0 10px", textAlign: "center" }}>
              {errorMessage}
            </Note>
            <Wrap justifyContent="center">
              {isEdit ? (
                <Button onClick={update}>更新</Button>
              ) : (
                <Button onClick={create}>投稿</Button>
              )}
            </Wrap>
            {isEdit && (
              <Button
                style={{ marginTop: 50 }}
                backgroundColor={color.red}
                size="small"
                onClick={deleteNotification}
              >
                このお知らせを削除する
              </Button>
            )}
          </>
        )}
      </TitleBox>
    </Wrap>
  );
};

export default withRouter(Notification);
