import React from "react";
import color from "../../utils/color";
import Wrap from "../atoms/Wrap";
import InputLabel from "../molecules/InputLabel";
import SelectBoxLabel from "../molecules/SelectBoxLabel";

const recruitTypeOptions = [
  { label: "正社員（新卒）", value: "newGraduate" },
  { label: "正社員（中途）", value: "midCareer" },
  { label: "アルバイト・パート", value: "partTimeJob" }
];

export default () => {
  return (
    <Wrap justifyContent="center">
      <form
        style={{
          width: "500px",
          border: `1px solid ${color.gray}`,
          padding: "10px 30px"
        }}>
        <InputLabel label="氏名" placeholder="山田 太郎" readOnly />
        <InputLabel label="年齢" placeholder="22" readOnly />
        <InputLabel
          label="現在の身分・職業"
          placeholder="学生、営業、経理、エンジニアなど"
          readOnly
        />
        <InputLabel
          label="メールアドレス"
          placeholder="sample@mail.com"
          readOnly
        />
        <InputLabel
          label="携帯電話番号"
          type="tel"
          placeholder="090-1234-5678"
          readOnly
        />
        <InputLabel
          label="住所"
          placeholder="福岡県福岡市博多区xx-x"
          readOnly
        />
        <SelectBoxLabel
          label="採用形態"
          options={recruitTypeOptions}
          readOnly
        />
      </form>
    </Wrap>
  );
};
