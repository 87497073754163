import React from "react";
import color from "../../utils/color";
import copyDom from "../../utils/copyDom";

import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import Modal from "../molecules/Modal";

export default ({ show, close, dom }) => {
  return (
    <Modal title="HTMLコードコピー" show={show} close={close}>
      <Typography size="large" margin="10px 0" bold color={color.black}>
        保存したフォームのHTMLコードを今すぐコピーしますか?
      </Typography>
      <Typography>
        コピーしたコードをHTMLファイルに貼り付けることでフォームを作成が完了します。
      </Typography>
      <Wrap margin="auto">
        <Wrap justifyContent="center" margin="20px 0 0">
          <Button onClick={close} backgroundColor={color.red}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              close();
              copyDom(dom);
            }}
            style={{ marginLeft: 10 }}
          >
            コピーする
          </Button>
        </Wrap>
      </Wrap>
    </Modal>
  );
};
