import React from "react";
import api from "../../api";

import SettingSitemap from "../templates/SettingSitemap";

const changeFreqOptions = [
  { label: "1時間ごと", value: "hourly" },
  { label: "毎日", value: "daily" },
  { label: "1週間ごと", value: "weekly" },
  { label: "1ヶ月ごと", value: "monthly" },
  { label: "1年ごと", value: "yearly" },
  { label: "更新しない", value: "never" },
];


export default class extends React.Component {
  state = {
    home_change_freq: changeFreqOptions[0].value,
    post_change_freq: changeFreqOptions[0].value,
    isShownUploadModal: false,
    isShownSubmitModal: false,
    uploadLoading: false,
    submitLoading: false,
    sitemapUri: "",
    uploaded: false,
    submitted: false,
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  fetchAuthUri = async () => {
    const res = await api.googleOauthUri.show()
    const auth_uri = res.context.auth_uri
    this.setState({ auth_uri });
  }

  upload = async () => {
    this.setState({ uploadLoading: true })
    const { home_change_freq, post_change_freq } = this.state;
    api.sitemap
    .create(home_change_freq, post_change_freq)
    .then(res => {
      this.setState({ uploadLoading: false, isShownUploadModal: true, uploaded: true, sitemapUri: res.sitemap_uri })
    })
    .catch(e => {
      this.setState({ uploadLoading: false, isShownUploadModal: true, uploaded: false })
      console.error(e)
    })
  }

  submit = async () => {
    this.setState({ submitLoading: true })
    api.sitemap
    .submit()
    .then(() => {
      this.setState({ submitLoading: false, isShownSubmitModal: true, submitted: true })
    })
    .catch(e => {
      this.setState({ submitLoading: false, isShownSubmitModal: true, submitted: false })
      e.json().then(err => {
        console.error(err)
      })
    })
  }

  closeModal = () => {
    this.setState({ isShownUploadModal: false, isShownSubmitModal: false, sitemap_uri: "", uploaded: false, submitted: false });
  }

  componentDidMount = () => {
    this.fetchAuthUri()
  }
  
  render() {
    return (
      <SettingSitemap
        {...this.state}
        handleChange={this.handleChange}
        options={changeFreqOptions}
        upload={this.upload}
        submit={this.submit}
        closeModal={this.closeModal}
      />
    )
  }
}