import React from "react";

import Loading from "../atoms/Loading";
import SnsCooperaion from "../molecules/SnsCooperation";

const SnsList = [
  "twitter",
  // "facebook"
  // "Instagram"
];

export default ({ sns, loading, ...props }) => {
  const { socialAccountInfos, authUris } = sns;
  return (
    <div>
      {loading.sns ? (
        <Loading />
      ) : (
        SnsList.map((sns, i) => (
          <SnsCooperaion
            key={i}
            socialAccountInfo={socialAccountInfos[sns]}
            authUri={authUris[sns]}
            kind={sns}
            {...props}
          />
        ))
      )}
    </div>
  );
};
