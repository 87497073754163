import React from "react";
import { Link, withRouter } from "react-router-dom";

import api from "../../api";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

import IconButton from "../atoms/IconButton";
import Loading from "../atoms/Loading";
import Button from "../atoms/Button";
import AccountIndex from "../templates/AccountIndex";
import route from "../../utils/route";
import { setStoreId } from "../../utils/localStorage";

class StoreIndex extends React.Component {
  state = {
    stores: [],
    loading: true,
    paginate: {
      total: 0,
      active: 1
    },
    search: ""
  };

  fetchStores = async (pageCount = 1) => {
    const { search } = this.state;
    const res = await api.stores.index(pageCount, search).catch(e => {
      console.log(e);
    });

    const total = res && res.total;
    const active = pageCount;

    const stores =
      res &&
      res.stores.map(s => [
        s.id,
        s.name,
        s.email,
        s.agency.name,
        new Date(s.updated_at).toLocaleDateString(),
        <Link to={route.store(s.id).dashboard} onClick={() => setStoreId(s.id)}>
          <IconButton icon={faSignInAlt} tip="この店舗の管理画面へ" />
        </Link>,
        s.aws_resource.hp_domain && (
          <Button
            onClick={() => window.open(`https://${s.aws_resource.hp_domain}`)}
          >
            公開中のサイトへ
          </Button>
        )
      ]);

    this.setState({ stores, loading: false, paginate: { active, total } });
  };

  componentDidMount = () => {
    this.fetchStores();
  };

  handleClickPagenate = pageCount => {
    this.fetchStores(pageCount);
  };

  handleChange = e => {
    this.setState({ search: e.target.value });
  };

  handleSearch = () => {
    this.fetchStores();
  };

  render() {
    const { stores, loading, paginate, search } = this.state;

    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <AccountIndex
            accountType="store"
            colWidths={["50px", "auto", "auto", "auto", "80px"]}
            tableHeads={[
              "店舗ID",
              "店舗名",
              "メールアドレス",
              "代理店名",
              "最終更新日",
              "",
              ""
            ]}
            tableDatas={stores}
            paginate={paginate}
            handleClickPagenate={this.handleClickPagenate}
            handleChange={this.handleChange}
            handleSearch={this.handleSearch}
            search={search}
          />
        )}
      </>
    );
  }
}

export default withRouter(StoreIndex);
