import React from "react";
import Notification from "../templates/Notification";

export default class extends React.Component {
  state = {
    title: "",
    content: ""
  };

  static getDerivedStateFromProps(props, state) {
    if (state.title === "") {
      const { title, content } = props.location.state.notification;
      return { title, content };
    }

    return;
  }

  render() {
    return (
      <div style={{ marginTop: 30 }}>
        <Notification {...this.state} pageType="show" />
      </div>
    );
  }
}
