import React from "react";
import styled from "styled-components";

import color from "../../utils/color";
import api from "../../api";
import route from "../../utils/route";
import { noInputCheck, passwordCheck } from "../../utils/inputCheck";

import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import Button from "../atoms/Button";
import InputLabel from "../molecules/InputLabel";
import Note from "../atoms/Note";
import WelcomePageBox from "../organisms/WelcomePageBox";

const Heading = styled.h1`
  font-size: 26px;
`;

export default class extends React.Component {
  state = {
    password: "",
    password_confirmation: "",
    errorMessage: {
      password: "",
      password_confirmation: "",
      message: ""
    }
  };

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = () => {
    const setErrorMessage = this.setErrorMessage;
    const { password, password_confirmation } = this.state;
    const token = this.props.match.params.token;

    try {
      noInputCheck(password, setErrorMessage, "password");
      passwordCheck(password, password_confirmation, setErrorMessage);
    } catch (e) {
      return false;
    }

    api.user.password
      .reset(token, password, password_confirmation)
      .then(res => {
        const successMessage =
          "パスワードを再設定しました。ログイン画面に移動します。";
        this.setState({ successMessage });
        setTimeout(() => {
          this.props.history.push(route.signInAgency);
        }, 2000);
      })
      .catch(e => {
        e.json().then(err => {
          const message = "更新に失敗しました。再度メールを送信してください。";
          this.setState({
            errorMessage: { message }
          });
          setTimeout(() => {
            this.props.history.push(route.passwordResetRequest);
          }, 3000);
          console.log(err);
        });
      });
  };

  render() {
    const {
      password,
      password_confirmation,
      errorMessage,
      successMessage
    } = this.state;
    return (
      <WelcomePageBox userType="common">
        <form onSubmit={e => e.preventDefault()}>
          <Heading>パスワード再設定</Heading>
          <Typography margin="5px 0 0">Reset your password</Typography>
          <Wrap padding="20px 0">
            <InputLabel
              name="password"
              type="password"
              label="新しいパスワード"
              value={password}
              onChange={this.handleChange}
              errorMessage={errorMessage.password}
            />
            <InputLabel
              name="password_confirmation"
              type="password"
              label="新しいパスワード（確認）"
              value={password_confirmation}
              onChange={this.handleChange}
              errorMessage={errorMessage.password_confirmation}
            />
          </Wrap>
          <Wrap height="30px">
            <Note>{errorMessage.message}</Note>
            <Typography color={color.black}>{successMessage}</Typography>
          </Wrap>
          <Wrap justifyContent="center">
            <Button onClick={this.submit}>変更</Button>
          </Wrap>
        </form>
      </WelcomePageBox>
    );
  }
}
