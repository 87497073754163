import React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import Wrap from "../atoms/Wrap";
import InputSearch from "../atoms/InputSearch";
import Typography from "../atoms/Typography";
import { RadiusTag } from "../atoms/Tag";
import SelectBox from "../atoms/SelectBox";

export default ({
  length,
  changeStatus,
  status,
  filterText,
  handleChange,
  jobOptions,
  activeJob
}) => {
  return (
    <Wrap justifyContent="space-between" alignItems="center" margin="0 0 10px">
      <ContactLength length={length} />
      <Wrap alignItems="center">
        <StatusFilter status={status} changeStatus={changeStatus} />
        <JobFilter
          jobOptions={jobOptions}
          activeJob={activeJob}
          handleChange={handleChange}
        />
        <InputSearch
          name="filterText"
          value={filterText}
          onChange={handleChange}
        />
      </Wrap>
    </Wrap>
  );
};

// 応募件数
const LengthNum = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: ${color.blue};
`;

const Unit = styled.span`
  font-size: 14px;
  margin-left: 5px;
`;

const ContactLength = ({ length }) => (
  <div>
    <LengthNum>{length}</LengthNum>
    <Unit>件</Unit>
  </div>
);

// 対応状況フィルター
const statusDict = [
  { label: "すべて表示", value: "all" },
  { label: "未対応", value: "pending" },
  { label: "選考中", value: "in_progress" },
  { label: "保留", value: "on_hold" },
  { label: "採用", value: "completed" },
  { label: "不採用", value: "rejected" }
];

const StatusTag = styled(RadiusTag)`
  cursor: pointer;
  transition: 0.15s;
  margin: 0 3px;
  background-color: ${props => (props.active ? color.yellow : color.gray)};

  :hover {
    opacity: 0.7;
  }
`;

const StatusFilter = ({ changeStatus, status }) => {
  return (
    <Wrap alignItems="center">
      <Typography size="12px" style={{ width: 60 }}>
        対応状況:
      </Typography>
      {statusDict.map((s, i) => (
        <StatusTag
          key={i}
          active={s.value === status}
          onClick={() => changeStatus(s.value)}
        >
          {s.label}
        </StatusTag>
      ))}
    </Wrap>
  );
};

const JobFilter = ({ jobOptions, activeJob, handleChange }) => {
  return (
    <Wrap alignItems="center" width="200px" margin="0 20px">
      <Typography size="12px" style={{ width: 60 }}>
        職種:
      </Typography>
      <SelectBox
        name="activeJob"
        options={jobOptions}
        value={activeJob}
        onChange={handleChange}
      />
    </Wrap>
  );
};
