import React from "react";
import styled from "styled-components";

import color from "../../utils/color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${color.gray};
  cursor: pointer;
  transition: 0.15s;
  padding: 10px;
  font-size: 14px;

  :hover {
    font-size: 18px;
    color: ${color.red};
  }
`;

export default ({ ...props }) => {
  return <StyledIcon icon={faTrashAlt} {...props} />;
};
