import React from "react";
import _ from "lodash";

import api from "../../api";
import { isAgency } from "../../utils/localStorage";
import postActions from "../../utils/postActions";
import { noInputCheck, validDateFormatCheck, validDateByNow } from "../../utils/inputCheck";

import PostEditor from "../templates/PostEditor";
import { getNow } from "../../utils/day";
import dayjs from "dayjs";

let maxScroll = 600;

export default class extends React.Component {
  state = {
    post: {
      title: "",
      slug: "",
      content: "",
      subcategory: "",
      thumbnail_url: ""
    },
    seo_tag: {
      doc: {
        title: "",
        description: "",
        keywords: ""
      },
      og: {
        title: "",
        description: "",
        image: "",
        site_name: ""
      },
      twitter: {
        title: "",
        description: "",
        image: "",
        card: ""
      }
    },
    sns: { twitter: false },
    categories: [],
    medias: [],
    loadedPage: 1,
    socialAccounts: [],
    selectedThumbnailId: 0,
    loading: true,
    errorMessage: { title: "", slug: "", createdAt: "" },
    verifyDomain: false,
    template: "",
    createdAt: getNow()
  };

  componentDidMount = async () => {
    const categories = await postActions(false).fetchCategories();
    const seo_tag = await postActions(true).fetchSeoPreferences();
    const template = await postActions(false).fetchTemplate();
    this.fetchMedias();

    this.verifyDomain();
    this.fetchSocialAccounts();
    this.setState({ categories, seo_tag, template, loading: false });
    window.addEventListener("scroll", this.handleScrollMedias, true);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollMedias);
  }

  fetchMedias = async () => {
    const { medias, loadedPage } = this.state;
    const newMedias = await postActions(false).fetchMedias(loadedPage);

    Array.prototype.push.apply(medias, newMedias);

    this.setState({ medias });
  };

  verifyDomain = async () => {
    const store = isAgency()
      ? await api.stores.current().catch((e) => {
          e.json().then((err) => console.log(err));
        })
      : await api.user.me().catch((e) => {
          e.json().then((err) => console.log(err));
        });

    const awsState =
      store && isAgency() ? store.aws_state : store.affiliation.aws_state;
    return (
      awsState === "setup_completed" && this.setState({ verifiedDomain: true })
    );
  };

  fetchSocialAccounts = async () => {
    const socialAccounts = await api.sns.index().catch((e) => {
      console.log(e);
    });

    return this.setState({ socialAccounts });
  };

  handleChange = (e) => {
    const { post } = this.state;
    this.setState({ post: { ...post, [e.target.name]: e.target.value } });
  };

  handleCreatedAtChange = (newDate, newTime) => {
    this.setState({
      createdAt: {
        dateString: newDate,
        timeString: newTime
      }
    })
  }

  handleChangeThumbnail = (selectedThumbnailId, thumbnail_url) => {
    const { post } = this.state;
    this.setState({ selectedThumbnailId, post: { ...post, thumbnail_url } });
  };

  handleChangeSeoTag = (e) => {
    let { seo_tag } = this.state;
    const [seoType, property] = e.target.name.split(":");
    seo_tag[seoType][property] = e.target.value;
    this.setState({ seo_tag });
  };

  handleImageChangeSeoTag = (seoType, imageUrl) => {
    let { seo_tag } = this.state;
    seo_tag[seoType].image = imageUrl;
    this.setState({ seo_tag });
  };

  insertImage = (imageUrl) => {
    const { post } = this.state;
    // TODO: 引数 or stateでalt属性を追加したい
    const imgTag = `<img src="${imageUrl}" alt="sample" />`;
    this.setState({ post: { ...post, content: `${post.content}${imgTag}` } });
  };

  setErrorMessage = (message, target) => {
    this.setState({ errorMessage: { [target]: message } });
  };

  create = () => {
    const { post, sns, seo_tag,  createdAt } = this.state;
    const { title } = post;
    const createdAtString = `${createdAt.dateString} ${createdAt.timeString}`;

    try {
      noInputCheck(title, this.setErrorMessage, "title");
      validDateFormatCheck(createdAtString, this.setErrorMessage, "createdAt");
      validDateByNow(createdAtString, this.setErrorMessage, "createdAt");
    } catch (e) {
      return false;
    }

    const unixCreatedAt = dayjs(createdAtString).unix();

    postActions(false)
      .create(post, sns, seo_tag, unixCreatedAt)
      .catch(() => this.setState({ errorMessage: "スラグが重複しています" }));
  };

  handleCheck = (name) =>
    this.setState({
      sns: { ...this.state.sns, [name]: !this.state.sns[name] }
    });

  handleScrollMedias = _.throttle((e) => {
    if (e.target.scrollTop > this.state.loadedPage * maxScroll) {
      this.setState({ loadedPage: this.state.loadedPage + 1 });
      this.fetchMedias();
    }
  }, 500);

  render() {
    return (
      <PostEditor
        {...this.state}
        handleChange={this.handleChange}
        handleCreatedAtChange={this.handleCreatedAtChange}
        handleChangeThumbnail={this.handleChangeThumbnail}
        submit={this.create}
        insertImage={this.insertImage}
        isCreate
        handleCheck={this.handleCheck}
        handleChangeSeoTag={this.handleChangeSeoTag}
        handleImageChangeSeoTag={this.handleImageChangeSeoTag}
      />
    );
  }
}
