import React from "react";
import ReactDOM from "react-dom";
import Favicon from "react-favicon";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./css/index.css";
import "./css/keyed-file-browser.css";

import Meta from "./utils/Meta";
import route from "./utils/route";
import pages from "./utils/pages";
import PrivateRoute from "./utils/PrivateRoute";
import noOuputConsoleProduction from "./utils/noOuputConsoleProduction";

import {
  AgencyContainer,
  DashboardContainer
} from "./components/organisms/Container";

const AgencyPrivateRoute = PrivateRoute("agencyToken", route.signInAgency);
const StorePrivateRoute = PrivateRoute("storeToken", route.signInStore);

const Root = () => {
  return (
    <Router history={createBrowserHistory()}>
      <Switch>
        <Redirect exact path="/" to={route.signInAgency} />

        {/* users */}
        <Route
          path="/users"
          render={() => (
            <Switch>
              {pages.users.map((r, i) => (
                <Route exact key={i} component={r.component} path={r.path} />
              ))}
            </Switch>
          )}
        />

        {/* dashboard */}
        <Route
          path="/dashboard"
          render={() => (
            <DashboardContainer>
              <Switch>
                {pages.agency.map((r, i) => (
                  <AgencyPrivateRoute
                    exact
                    key={i}
                    component={r.component}
                    path={r.path}
                  />
                ))}
                {pages.store.map((r, i) => (
                  <StorePrivateRoute
                    exact
                    key={i}
                    component={r.component}
                    path={r.path}
                  />
                ))}
              </Switch>
            </DashboardContainer>
          )}
        />

        {/* only agency */}
        <Route
          path="/"
          render={() => (
            <AgencyContainer>
              <Switch>
                {pages.agencyAdmin.map((r, i) => (
                  <AgencyPrivateRoute
                    exact
                    key={i}
                    component={r.component}
                    path={r.path}
                  />
                ))}
              </Switch>
            </AgencyContainer>
          )}
        />
      </Switch>
    </Router>
  );
};
noOuputConsoleProduction();
ReactDOM.render(
  <div>
    <Favicon />
    <Meta />
    <Root />
  </div>,
  document.getElementById("root")
);
