import React from "react";
import Label from "../atoms/Label";
import ErrorMessage from "../atoms/ErrorMessage"
import DateInput from "../atoms/DateInput";
import TimeInput from "../atoms/TimeInput";
import { getNow } from "../../utils/day";

/**
 * 日時を入力するフォーム
 * ※ 現在の年月日以降は指定できない
 * @param {string} label
 * @param {string} dateValue 年月日を表す"2020-01-12"の形式の文字列
 * @param {string} timeValue 時間を表す"10:05"の形式の文字列
 * @param {Function} handleCreatedAtChange
 */
const DateTimeInput = ({ label, dateValue, timeValue, handleCreatedAtChange, errorMessage }) => {

  const now = getNow();

  return (
    <div style={{ margin: "15px 0" }}>
      <Label>{label}</Label>
      <DateInput value={dateValue} handleChange={(value) => handleCreatedAtChange(value, timeValue)} max={now.dateString} />
      <TimeInput value={timeValue} handleChange={(value) => handleCreatedAtChange(dateValue, value)} />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  );
};

export default DateTimeInput;
