import React from "react";

import api from "../../api";

import Wrap from "../atoms/Wrap";
import Loading from "../atoms/Loading";
import Note from "../atoms/Note";

import sitestAutoLogin from "../../utils/sitestAutoLogin";

const loginUrlsEnv = {
  // NOTE: `dev.sitest.jp`` has IP address based ACL.
  development: "http://dev.sitest.jp/users/teory_login",
  production: "https://sitest.jp/users/teory_login"
};

let loginUrl;
if (
  process.env.NODE_ENV === "development" ||
  window.location.host === "staging.ccmphp.com"
) {
  loginUrl = loginUrlsEnv.development;
} else {
  loginUrl = loginUrlsEnv.production;
}

const autoLogin = true;

export default class extends React.Component {
  state = {
    loading: false,
    errorMessage: null,
    account: {
      use_sitest: null,
      sitest_shop_id: null,
      sitest_id: null
    }
  };

  componentDidMount = () => {
    this.fetchSitestAccount();
  };

  fetchSitestAccount = async () => {
    this.setState({ loading: true });
    let account = await api.sitest.account().catch(e => {
      console.error(e);
    });
    if (account.use_sitest && !account.sitest_id) {
      account = await api.sitest.create().catch(e => {
        this.setState({
          loading: false,
          errorMessage: "問題が発生しました。運営者にお問い合わせください。"
        });
        console.error(e);
        throw e;
      });
    }

    this.setState({ loading: false, account });
    if (account && account.use_sitest && autoLogin) {
      sitestAutoLogin(loginUrl, account.sitest_shop_id, account.sitest_id);
    }
  };

  renderInside = () => {
    if (this.state.loading) return <Loading label="確認中です..." />;
    if (this.state.errorMessage) return <Note>{this.state.errorMessage}</Note>;
    if (!this.state.account.use_sitest) {
      return (
        <Note>
          現在、サイト解析機能をご利用いただけません。
          <br />
          ご利用になるにはお手続きが必要です。詳しくは運営にお問い合わせください。
        </Note>
      );
    }
    return (
      <section>
        <Loading label="アクセス解析を開いています。しばらくお待ちください..." />
        <Note>
          画面が切り替わらない場合、以下ボタンからの遷移をお試しください。
        </Note>
        <form method="POST" action={loginUrl} target="analysis">
          <input
            type="hidden"
            name="shop_id"
            value={this.state.account.sitest_shop_id}
          />
          <input
            type="hidden"
            name="user_id"
            value={this.state.account.sitest_id}
          />
          <input type="submit" value="アクセス解析へ" />
        </form>
      </section>
    );
  };

  render() {
    return (
      <Wrap padding="20px">
        <div style={{ textAlign: "center" }}>{this.renderInside()}</div>
      </Wrap>
    );
  }
}
