import React from "react";
import styled from "styled-components";

import {
  faFont,
  faAlignLeft,
  faAt,
  faCaretSquareDown,
  faDotCircle,
  faCalendar,
  faCheckSquare
  // faSortNumericDown,
  // faToggleOn,
  // faPhone,
} from "@fortawesome/free-solid-svg-icons";

import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import DescriptionButton from "../molecules/DescriptionButton";

const GridWrap = styled.div`
  display: grid;
  grid-template-rows: 60px 60px 60px 60px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const event = (value, label) => {
  return {
    target: {
      name: "type",
      value,
      label
    }
  };
};

export default ({ handleChange, closeModal }) => {
  return (
    <>
      <Typography margin="0 0 10px">
        フォームの種類を選択してください
      </Typography>
      <GridWrap>
        <DescriptionButton
          icon={faFont}
          label="一行テキスト"
          description="氏名, 住所など"
          handleChange={() => handleChange(event("text", "一行テキスト"))}
        />
        <DescriptionButton
          icon={faAlignLeft}
          label="複数行テキスト"
          description="問い合わせ内容など"
          handleChange={() => handleChange(event("textarea", "複数行テキスト"))}
        />
        <DescriptionButton
          icon={faAt}
          label="メールアドレス"
          description="ユーザーのメールアドレスなど"
          handleChange={() => handleChange(event("email", "メールアドレス"))}
        />
        <DescriptionButton
          icon={faCaretSquareDown}
          label="セレクトボックス（一つのみ選択）"
          description="地域選択など"
          handleChange={() =>
            handleChange(event("selectbox", "セレクトボックス（一つのみ選択"))
          }
        />
        <DescriptionButton
          icon={faDotCircle}
          label="ラジオボタン（一つのみ選択）"
          description="アンケートなど"
          handleChange={() => handleChange(event("radio",'ラジオボタン（一つのみ選択）'))}
        />
        <DescriptionButton
          icon={faCalendar}
          label="日付"
          description="希望日時など"
          handleChange={() => handleChange(event("date", "日付"))}
        />
        <DescriptionButton
          icon={faCheckSquare}
          label="チェックボックス（複数選択）"
          description="アンケートなど"
          handleChange={() => handleChange(event("checkbox", 'チェックボックス（複数選択）'))}
        />
        {/* <DescriptionButton
        icon={faToggleOn}
        label="トグルチェック（二者択一）"
        description="自動メール配信の確認など"
        handleChange={() => handleChange(event("toggle"))}
      />
      <DescriptionButton
        icon={faSortNumericDown}
        label="数値"
        description="年数など"
        handleChange={() => handleChange(event("number"))}
      />
      <DescriptionButton
        icon={faPhone}
        label="電話番号"
        description="ユーザーの電話番号など"
        handleChange={() => handleChange(event("tel"))}
      /> */}
      </GridWrap>
      <Wrap justifyContent="center" margin="10px 0 0 0 ">
        <Button onClick={closeModal}>閉じる</Button>
      </Wrap>
    </>
  );
};
