import React from "react";
import styled from "styled-components";

import Wrap from "../atoms/Wrap";
import ErrorMessage from "../atoms/ErrorMessage";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import InputLabel from "../molecules/InputLabel";
import color from "../../utils/color";

const ScrollY = styled.div`
  height: 200px;
  overflow-y: scroll;
  border: 1px solid ${color.grayLight};
  padding: 20px 20px 50px;
`;

export default ({
  handleChange,
  add,
  label,
  type,
  options,
  resetNewFormParams,
  changeOptions,
  addOption,
  removeOption,
  errorMessage
}) => {
  return (
    <Wrap width="80%" margin="auto">
      <Typography>
        フォームの情報を入力して確定ボタンを押してください
      </Typography>
      <Wrap height="25px" margin="5px 0">
        {errorMessage.label && (
          <ErrorMessage errorMessage={errorMessage.label} />
        )}
        {errorMessage.options && (
          <ErrorMessage errorMessage={errorMessage.options} />
        )}
      </Wrap>

      <form onSubmit={e => e.preventDefault()}>
        <InputLabel
          label="フォーム名"
          name="label"
          value={label}
          onChange={handleChange}
        />
        {(type === "selectbox" || type === "radio" || type === "checkbox") && (
          <ScrollY>
            {options.map((o, i) => (
              <Wrap key={i} alignItems="center">
                <InputLabel
                  margin="5px 0"
                  label={`選択肢${i + 1}`}
                  name={i}
                  value={o}
                  onChange={changeOptions}
                  width="300px"
                  placeholder="選択肢の名前が入ります"
                />
                <Button
                  size="small"
                  onClick={removeOption}
                  style={{ margin: "28px 0 0 10px" }}
                  backgroundColor={color.red}
                >
                  削除
                </Button>
              </Wrap>
            ))}
            <Button
              size="small"
              onClick={addOption}
              style={{ marginTop: 10 }}
              backgroundColor={color.yellow}
            >
              選択肢追加
            </Button>
          </ScrollY>
        )}
      </form>
      <Wrap justifyContent="center" margin="15px 0 0">
        <Button onClick={add} style={{ marginRight: "10px" }}>
          確定
        </Button>
        <Button backgroundColor={color.gray} onClick={resetNewFormParams}>
          戻る
        </Button>
      </Wrap>
    </Wrap>
  );
};
