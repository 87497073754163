import React from "react";

import color from "../../utils/color";

import Label from "../atoms/Label";
import Input from "../atoms/Input";
import ErrorMessage from "../atoms/ErrorMessage";
import Typography from "../atoms/Typography";
import RequiredMark from "../atoms/RequiredMark";

export default ({
  required,
  label,
  width,
  margin,
  note,
  errorMessage,
  ...props
}) => {
  return (
    <div style={{ margin: margin || "15px 0", width }}>
      <Label>
        {label}
        {required && <RequiredMark />}
      </Label>
      <Input {...props} errorMessage={errorMessage} />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {note && (
        <Typography size="11px" color={color.red} style={{ marginTop: 5 }}>
          {note}
        </Typography>
      )}
    </div>
  );
};
