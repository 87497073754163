import React from "react";
import { Link } from "react-router-dom";
import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";
import color from "../../utils/color";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import InputLabel from "../molecules/InputLabel";
import SelectBoxLabel from "../molecules/SelectBoxLabel";
import Modal from "../molecules/Modal";
import TextareaLabel from "../molecules/TextareaLabel";
import JobOfferPreview from "../organisms/JobOfferPreview";

const JobOfferForms = ({
  handleChange,
  handleMessageChange,
  create,
  update,
  deleteForm,
  isEditPage,
  name,
  pub_email,
  redirect_to,
  reply_subject,
  reply_message,
  errorMessage,
  setModalShow,
  reply_type
}) => {
  return (
    <TitleBox title="求人情報入力">
      <Wrap justifyContent="center">
        <form onSubmit={(e) => e.preventDefault(e)}>
          <InputLabel
            name="name"
            width="300px"
            label="募集職種名"
            value={name}
            onChange={handleChange}
            placeholder="エンジニア、営業、経理など"
          />
          <InputLabel
            name="pub_email"
            width="300px"
            required
            label="受信用メールアドレス"
            value={pub_email}
            onChange={handleChange}
            placeholder="contact@mail.com"
            errorMessage={errorMessage.pub_email}
          />
          <InputLabel
            name="redirect_to"
            width="300px"
            required
            label="リダイレクト先URL"
            value={redirect_to}
            onChange={handleChange}
            placeholder="https://sample.com"
            errorMessage={errorMessage.redirect_to}
          />
          <SelectBoxLabel
            name="reply_type"
            label="返信方法"
            options={[
              { label: "Eメールで返信", value: "email" },
              { label: "SMSで返信", value: "sms" }
            ]}
            value={reply_type}
            onChange={handleChange}
          />
          {reply_type === "sms" ? (
            <>
              <TextareaLabel
                name="reply_message"
                label="送信メッセージ"
                placeholder="お世話になっております.この度は....."
                onChange={handleMessageChange}
                value={reply_message || ""}
                errorMessage={errorMessage.reply_message}
              />
              <Typography>※__APPLICANTS_NAME__ :応募者名</Typography>
              <Typography>
                ※ __STORE_NAME__ :店舗名として埋め込まれます
              </Typography>
            </>
          ) : (
            <>
              <InputLabel
                name="reply_subject"
                label="送信タイトル"
                placeholder="お申し込みありがとうございます"
                value={reply_subject || ""}
                onChange={handleChange}
              />
              <TextareaLabel
                name="reply_message"
                label="送信メッセージ
  "
                placeholder="お世話になっております.この度は....."
                value={reply_message || ""}
                onChange={handleMessageChange}
                errorMessage={errorMessage.reply_message}
              />
              <Typography>※__APPLICANTS_NAME__ :応募者名</Typography>
              <Typography>
                ※ __STORE_NAME__ :店舗名として埋め込まれます
              </Typography>
            </>
          )}
          <Typography
            textAlign="center"
            onClick={() => setModalShow(true)}
            style={{ margin: "35px 0 20px", cursor: "pointer" }}
            color={color.blue}
          >
            生成される応募フォームのプレビューを確認
          </Typography>
          <Wrap>
            {isEditPage ? (
              <>
                <Wrap justifyContent="center">
                  <Button onClick={update}>上書き保存</Button>
                </Wrap>
                <Wrap justifyContent="flex-end" margin="80px 0 0">
                  <Button
                    size="small"
                    onClick={deleteForm}
                    backgroundColor={color.red}
                  >
                    フォーム削除
                  </Button>
                </Wrap>
              </>
            ) : (
              <Wrap justifyContent="center">
                <Button onClick={create}>作成</Button>
              </Wrap>
            )}
          </Wrap>
        </form>
      </Wrap>
    </TitleBox>
  );
};

export default ({ ...props }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const indexPath = isAgency()
    ? route.store(storeId()).jobOfferIndex
    : route.jobOfferIndex;

  return (
    <Wrap padding="20px">
      <Modal
        title="生成される応募フォームのプレビュー"
        show={modalShow}
        close={() => setModalShow(false)}
        scroll
      >
        <JobOfferPreview />
      </Modal>
      <Wrap margin="auto" width="80%" maxWidth="500px">
        <Wrap margin="0 0 15px">
          <Link to={indexPath}>
            <Button size="small">求人票一覧へ</Button>
          </Link>
        </Wrap>
        <JobOfferForms {...props} setModalShow={setModalShow} />
      </Wrap>
    </Wrap>
  );
};
