import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

// === example ===
//  navs = [
//   { label: "hoge", component: <div>hoge</div> }
//   { label: "fuga", component: <div>huga</div> }
// ]

const NavList = styled.ul`
  display: flex;
  border-bottom: 1px solid ${color.gray};
  margin-bottom: 15px;
`;

const Tab = styled.li`
  padding: 10px 14px;
  cursor: pointer;
  border-radius: 4px 4px 0 0;

  font-size: ${props => (props.active ? "14px" : "12px")};
  font-weight: ${props => (props.active ? "bold" : "normal")};
  border: ${props => (props.active ? `1px solid ${color.gray}` : "none")};
  border-bottom: ${props => props.active && `1px solid ${color.grayDark}`};
  margin-bottom: ${props => props.active && "-1px"};
`;

export default ({ navs }) => {
  const [active, setActive] = React.useState(0);

  return (
    <div>
      <NavList>
        {navs.map((n, i) => (
          <Tab key={i} active={i === active} onClick={() => setActive(i)}>
            {n.label}
          </Tab>
        ))}
      </NavList>
      {navs.map((n, i) => active === i && <div key={i}>{n.component}</div>)}
    </div>
  );
};
