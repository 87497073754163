import styled from "styled-components";
import color from "../../utils/color";

const Tag = styled.div`
  display: inline-block;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: bold;

  color: ${props => (props.fontColor === "black" ? color.black : color.white)};

  background-color: ${props => props.color || color.red};
`;

const RadiusTag = styled(Tag)`
  padding: 4px 10px;
  border-radius: 12px;
`;

export { RadiusTag };
export default Tag;
