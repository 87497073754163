import React from "react";
import styled from "styled-components";

import color from "../../utils/color";

const Mark = styled.div`
  display: inline-block;
  color: ${color.red};
  margin-left: 5px;
  font-size: 11px;
`;

export default () => {
  return <Mark>（必須）</Mark>;
};
