import React from "react";
import api from "../../api";
import route from "../../utils/route";
import { storeId } from "../../utils/localStorage";
import SourceEdit from "../templates/SourceEdit";
import { isAgency } from "../../utils/localStorage";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      source: "",
      isLoading: true,
      errorMessage: "",
      isHTML: false,
      title: ""
    };
  }

  componentDidMount() {
    this.fetchSourceFile();
  }

  fetchSourceFile = async () => {
    const { object_hash } = this.props.match.params;
    const res = await api.source.show(object_hash).catch(e => {
      console.log(e);
    });

    const { source } = res.context;
    const { filepath } = this.props.location.state;

    this.setState({
      source,
      isHTML: filepath.match(".html"),
      isLoading: false
    });
  };

  onChangeEditor = newValue => {
    this.setState({ source: newValue });
  };

  handleTitleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = ({ isWysiwyg, editedHTML }) => {
    const { source, title } = this.state;
    const { filepath, version_hash } = this.props.location.state;
    const body = {
      source: {
        filepath,
        content: isWysiwyg ? editedHTML : source,
        version_hash,
        title
      }
    };

    api.source
      .update(body)
      .then(() => {
        this.props.history.push({
          pathname: isAgency()
            ? route.store(storeId()).versionsIndex
            : route.versionsIndex,
          state: {
            successUpdateMessage: "変更を反映しました"
          }
        });
      })
      .catch(e => {
        console.log(e);
        const errorMessage = "変更に失敗しました";
        this.setState({ errorMessage });
      });
  };

  render() {
    const fileName = this.props.location.state.filepath;

    return (
      <SourceEdit
        {...this.state}
        fileName={fileName}
        submit={this.submit}
        onChangeEditor={this.onChangeEditor}
        handleTitleChange={this.handleTitleChange}
      />
    );
  }
}
