import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import api from "../../api";
import route from "../../utils/route";
import mousedownEventListener from "../../utils/mousedownEventListener";
import { isAgency, storeId } from "../../utils/localStorage";
import defaultLogo from "../../img/logo.png";

import { HeaderLoading } from "../atoms/Loading";
import Wrap from "../atoms/Wrap";
import HeaderContainer from "../atoms/HeaderContainer";
import DropdownMenuButton from "../molecules/DropdownMenuButton";
import MenuButton from "../molecules/MenuButton";

const Header = () => {
  const [userName, setUserName] = useState("");
  const [showLogout, setLogout] = useState(false);
  const [logo, setLogo] = useState("");
  const [loading, setLoading] = useState(true);

  const logoutRef = useRef();
  mousedownEventListener(logoutRef, setLogout);

  const fetchStore = () => {
    isAgency()
      ? api.user.me().then(user => {
          setUserName(user.name);
        })
      : api.user.me().then(store => {
          setUserName(store.name);
        });
  };

  const fetchLogo = async () => {
    const logoUrl = await api.agencies.logo.show();
    setLogo(logoUrl);
    setLoading(false);
  };

  useEffect(() => {
    fetchStore();
    fetchLogo();
  }, [userName]);

  return (
    <HeaderContainer>
      <Wrap justifyContent="flex-start" alignItems="center">
        {/* <HamburgerMenu onClick={setShowSidebar} /> */}
        {loading ? (
          <HeaderLoading />
        ) : (
          <Link
            to={isAgency() ? route.store(storeId()).dashboard : route.dashboard}
          >
            <img src={logo || defaultLogo} alt="ロゴ" height={28} />
          </Link>
        )}
        {isAgency() && (
          <Wrap alignItems="center" margin="0 0 0 40px">
            <MenuButton to={route.storeIndex} label="店舗管理" />
            <MenuButton to={route.agencyIndex} label="代理店一覧" />
            <MenuButton to={route.agencyStaffIndex} label="スタッフ一覧" />
            <MenuButton to={route.notificationsIndex} label="お知らせ一覧" />
          </Wrap>
        )}
      </Wrap>
      <Wrap alignItems="center" justifyContent="flex-end" overflowNone>
        <DropdownMenuButton
          useRef={logoutRef}
          label={userName}
          show={showLogout}
          onClick={() => setLogout(!showLogout)}
          links={[
            {
              label: "アカウント情報設定",
              path: isAgency()
                ? route.settingAccountAgency
                : route.settingAccountStore
            },
            {
              label: "ログアウト",
              path: isAgency() ? route.signInAgency : route.signInStore,
              logout: () =>
                isAgency()
                  ? localStorage.removeItem("agencyToken")
                  : localStorage.removeItem("storeToken")
            }
          ]}
        />
      </Wrap>
    </HeaderContainer>
  );
};

export default withRouter(Header);
