import React from "react";
import ReactLoading from "react-loading";

import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import color from "../../utils/color";

export default ({ label, description }) => {
  return (
    <Wrap width="100%" justifyContent="center" padding="50px 0">
      <div style={{ textAlign: "center" }}>
        <Wrap justifyContent="center">
          <ReactLoading color={color.blue} type="spinningBubbles" />
        </Wrap>
        <div style={{ margin: "40px 0 15px" }}>{label}</div>
        <Typography>{description}</Typography>
      </div>
    </Wrap>
  );
};

export const HeaderLoading = () => {
  return (
    <Wrap justifyContent="center" alignItems="center" width="80px">
      <Wrap justifyContent="center">
        <ReactLoading
          color={color.blue}
          type="spinningBubbles"
          width="30px"
          height="30px"
        />
      </Wrap>
    </Wrap>
  );
};
