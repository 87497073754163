import React from "react";

import Wrap from "../atoms/Wrap";
import { Notice } from "../atoms/Notice";
import Note from "../atoms/Note";
import SourceCodeEditor from "../organisms/SourceCodeEditor";

export default ({
  postType,
  template,
  message,
  isLoading,
  onChangeEditor,
  saveTemplate
}) => {
  const postTypeClass = postType === "article" ? "ARTICLE" : "NEWS";
  const postTypeName = postType === "article" ? "記事" : "お知らせ";

  return (
    <Wrap padding="20px">
      {message.content && (
        <Notice color={message.isSuccess ? "green" : "red"} margin="0 0 10px 0">
          {message.content}
        </Notice>
      )}
      <SourceCodeEditor
        isLoading={isLoading}
        source={template}
        fileName={`${postTypeName}テンプレート設定`}
        onChangeEditor={onChangeEditor}
        submit={saveTemplate}
        useComment={false}
      />
      <Note>{"※HTMLには必ず以下のタグを含んでください。"}</Note>
      <br />
      <Note>{`1. <div class="CMS-${postTypeClass}-TITLE"></div>`}</Note>
      <Note>{`2. <div class="CMS-${postTypeClass}-CONTENT"></div>`}</Note>
    </Wrap>
  );
};
