import dayjs from "dayjs";

const noInputError = "値を入力してください。";
const passError = "パスワードが一致しません。";
const invalidEmailError = "正しい形式のメールアドレスを入力してください。";
const invalidMessageError = "500文字以内でメッセージを入力してください。";
const invaludUrlError =
  "http://またはhttps://から始まるURLを入力してください。";
const invalidDateFormat =  "年月日は 2020-07-12 時刻は 10:05 の形式で入力してください"
const invalidDate = "不正な日時です(現在時刻以前を指定してください)"

export const noInputCheck = (value, setErrorMessage, target) => {
  if (!value) {
    setErrorMessage(noInputError, target);
    throw new Error();
  }
};

export const validMessageCheck = (value, setErrorMessage, target) => {
  if (value.length >= 500) {
    setErrorMessage(invalidMessageError, target);
    throw new Error();
  }
};

export const validEmailCheck = (value, setErrorMessage, target) => {
  const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!value.match(email_regex)) {
    setErrorMessage(invalidEmailError, target);
    throw new Error();
  }
};

export const passwordCheck = (
  password,
  password_confirmation,
  setErrorMessage
) => {
  if (password !== password_confirmation) {
    setErrorMessage(passError, "password_confirmation");
    throw new Error();
  }
};

export const validUrlCheck = (value, setErrorMessage, target) => {
  const url_regex = /(http|https):\/\/.+/;
  if (!value.match(url_regex)) {
    setErrorMessage(invaludUrlError, target);
    throw new Error();
  }
};

export const validDateFormatCheck = (value, setErrorMessage, target) => {
  const validateDate = (date, format) => {
    return dayjs(date, format).format(format) === date;
  }
  
  const isValid = validateDate(value, 'YYYY-MM-DD HH:mm');
  if (!isValid) {
    setErrorMessage(invalidDateFormat, target);
    throw new Error(); 
  }
}

/**
 * 現在時刻以前かをチェックする
 */
export const validDateByNow =  (value, setErrorMessage, target) => {
  const targetUnixtime = dayjs(value).unix();
  const nowUnixtime = dayjs().unix();
  const isValid = nowUnixtime >= targetUnixtime;
  if (!isValid) {
    setErrorMessage(invalidDate, target);
    throw new Error(); 
  }
}