import React from "react";
import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";
import { noInputCheck } from "../../utils/inputCheck";
import extensionFileType from "../../utils/extensionFileType";
import api from "../../api";
import VersionsShow from "../templates/VersionsShow";

export default class extends React.Component {
  state = {
    title: "",
    message: {
      success: "",
      error: ""
    },
    errorMessage: { title: "" }
  };

  handleClickFile = (file, version_hash) => {
    const type = file.key.split(".");
    if (extensionFileType.includes(type[type.length - 1].toLowerCase())) {
      return this.setState({
        message: { error: "指定の拡張子のファイルは編集できません" }
      });
    }

    this.props.history.push({
      pathname: isAgency()
        ? route.store(storeId()).sourceEdit(file.object_hash)
        : route.sourceEdit(file.object_hash),
      state: {
        filepath: file.key,
        version_hash,
        sources: this.props.location.state.data
      }
    });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  setErrorMessage = (value, target) => {
    this.setState({ errorMessage: { [target]: value } });
  };

  update = () => {
    const { version } = this.props.location.state.data.data;
    const { title } = this.state;

    try {
      noInputCheck(title, this.setErrorMessage, "title");
    } catch (e) {
      return false;
    }

    api.versions
      .update(version, title)
      .then(() => {
        this.setState({
          message: { success: "バージョン情報を更新しました" },
          errorMessage: { title: "" }
        });
      })
      .catch(e => console.error(e));
  };

  componentDidMount() {
    const { title } = this.props.location.state.data.data;
    this.setState({ title });
  }

  render() {
    const { data } = this.props.location.state;
    const indexPath = isAgency()
      ? route.store(storeId()).versionsIndex
      : route.versionsIndex;

    return (
      <VersionsShow
        {...this.state}
        data={data}
        indexPath={indexPath}
        handleClickFile={this.handleClickFile}
        handleChange={this.handleChange}
        update={this.update}
      />
    );
  }
}
