import React from "react";

import color from "../../utils/color";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import IconButton from "../atoms/IconButton";
import Loading from "../atoms/Loading";
import Typography from "../atoms/Typography";
import Modal from "../molecules/Modal";

export default ({
  options,
  handleClickOptionsSwitch,
  loading,
  showPopUp,
  togglePopUp,
  showSitestModel,
  toggleSitestModal
}) => {
  const optionsName = {
    sitest: "サイト解析機能",
    recruit: "求人管理機能",
    tabelog: "食べログ連携機能"
  };
  const [selectOption, setSelectOption] = React.useState("");
  return (
    <div>
      <Modal show={showSitestModel} title="ご利用手順">
        <Typography margin="0 0 20px 0">
          サイト解析設定が有効になりました。
        </Typography>
        <Typography margin="0 0 20px 0">
          再度ファイルをアップロードし直し、公開済みのお知らせや記事を更新することで、全てのページに解析用のタグが自動付与され、サイト解析が開始されます。
        </Typography>
        <Wrap justifyContent="center">
          <Button onClick={toggleSitestModal}>確認しました</Button>
        </Wrap>
      </Modal>
      <Modal show={showPopUp} title="オプション有効化確認">
        <Typography margin="0 0 1rem 0">
          一度有効化されると、無効に戻すことはできません。
        </Typography>
        <Wrap alignItems="center" justifyContent="center">
          <Button
            onClick={() => handleClickOptionsSwitch(selectOption)}
            style={{ marginRight: 10 }}
          >
            有効にする
          </Button>
          <Button onClick={togglePopUp} backgroundColor={color.gray}>
            キャンセル
          </Button>
        </Wrap>
      </Modal>
      {loading.options ? (
        <Loading />
      ) : (
        Object.keys(options).map((option, i) => (
          <Wrap justifyContent="space-between" margin="10px 0 30px 0" key={i}>
            <Typography color={color.blueGray} size="24px">
              {optionsName[option]}
            </Typography>
            <IconButton
              icon={faPowerOff}
              color={options[option] ? color.green : color.red}
              onClick={() => {
                !options[option] && togglePopUp();
                setSelectOption(option);
              }}
              tip={
                options[option]
                  ? `${optionsName[option]}機能は有効です。`
                  : `${optionsName[option]}機能は無効です。`
              }
            />
          </Wrap>
        ))
      )}
    </div>
  );
};
