import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import color from "../../utils/color";

const Button = styled.button`
  background-color: ${color.white};
  border: 1px solid ${color.gray};
  border-radius: 3px;
  text-align: left;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.15s;

  :hover {
    background-color: ${color.grayLight};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${color.white};
  padding: 8px 15px;
  font-size: 16px;
  border-radius: 3px;
`;

const FormName = styled.div`
  color: ${color.black};
  font-weight: bold;
  font-size: 13px;
`;

const Description = styled.div`
  color: ${color.typoLight};
  font-size: 11px;
`;

export default ({ icon, label, description, handleChange }) => {
  return (
    <Button onClick={handleChange}>
      <Icon
        icon={icon}
        style={{ marginRight: 15, backgroundColor: color.green }}
      />
      <div>
        <FormName>{label}</FormName>
        <Description>{description}</Description>
      </div>
    </Button>
  );
};
