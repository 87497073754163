export default [
  "gif",
  "jpg",
  "jpeg",
  "jpe",
  "jfif",
  "png",
  "bmp",
  "dib",
  "rle",
  "ico",
  "svg",
  "mp3",
  "mid",
  "midi",
  "wav",
  "mov",
  "qt",
  "mpg",
  "mpeg",
  "wm",
  "wma",
  "wmv",
  "asf",
  "wax",
  "wvx",
  "asx",
  "ra",
  "rv",
  "rm",
  "ram",
  "rmm",
  "rpm",
  "swf",
  "mp4",
  "lzh",
  "zip",
  "pdf",
  "doc",
  "xls",
  "ppt",
  "pps",
  "exe",
  "dll"
];
