import React from "react";
import { withRouter } from "react-router-dom";

import api from "../../api";
import prefectures from "../../utils/prefectures";
import { noInputCheck, validEmailCheck } from "../../utils/inputCheck";

import DomainRegisterTemp from "../templates/DomainRegisterTemp";

class DomainRegister extends React.Component {
  state = {
    loading: false,

    first_name: "",
    last_name: "",
    organization_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: prefectures[0],
    country_code: "JP",
    zip_code: "",
    phone_number: "",
    email: "",
    domainContactOk: false,

    errorMessage: {
      duration: "",
      first_name: "",
      last_name: "",
      organization_name: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
      phone_number: "",
      email: ""
    }
  };

  handleChange = e => {
    const name = e.target.name;
    const type = e.target.type;
    const value = e.target.value;

    this.setState({
      [name]: type === "number" ? Number(value) : value
    });
  };

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  contactDomain = () => {
    const setErrorMessage = this.setErrorMessage;
    const { location } = this.props;
    const domain = location.state && location.state.domain;
    const {
      first_name,
      last_name,
      organization_name,
      address_line_1,
      address_line_2,
      city,
      state,
      country_code,
      zip_code,
      phone_number,
      email
    } = this.state;

    try {
      noInputCheck(first_name, setErrorMessage, "first_name");
      noInputCheck(last_name, setErrorMessage, "last_name");
      noInputCheck(organization_name, setErrorMessage, "organization_name");
      noInputCheck(address_line_1, setErrorMessage, "address_line_1");
      noInputCheck(address_line_2, setErrorMessage, "address_line_2");
      noInputCheck(city, setErrorMessage, "city");
      noInputCheck(zip_code, setErrorMessage, "zip_code");
      noInputCheck(phone_number, setErrorMessage, "phone_number");
      noInputCheck(email, setErrorMessage, "email");
      validEmailCheck(email, setErrorMessage, "email");
    } catch (e) {
      return false;
    }

    const body = {
      domain: { domain, duration: 1 },
      contact: {
        first_name,
        last_name,
        contact_type: "COMPANY",
        organization_name,
        address_line_1,
        address_line_2,
        city,
        state,
        country_code,
        zip_code,
        phone_number,
        email
      }
    };

    this.setState({ loading: true });

    api.domain
      .contact(body)
      .then(() => {
        console.log("ok");
        this.setState({ domainContactOk: true, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        e.json().then(err => console.log(err));
      });
  };

  render() {
    const domain =
      this.props.location.state && this.props.location.state.domain;

    const stateOptions = prefectures.map(p => ({ label: p, value: p }));

    return (
      <DomainRegisterTemp
        handleChange={this.handleChange}
        contactDomain={this.contactDomain}
        domain={domain}
        stateOptions={stateOptions}
        {...this.state}
      />
    );
  }
}

export default withRouter(DomainRegister);
