import React from "react";
import styled from "styled-components";

import api from "../../api";
import color from "../../utils/color";
import route from "../../utils/route";
import { noInputCheck, validEmailCheck } from "../../utils/inputCheck";

import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import Button from "../atoms/Button";
import LinkText from "../atoms/LinkText";
import InputLabel from "../molecules/InputLabel";
import WelcomePageBox from "../organisms/WelcomePageBox";

const Heading = styled.h1`
  font-size: 26px;
`;

export default class extends React.Component {
  state = {
    email: "",
    successMessage: "",
    errorMessage: ""
  };

  setErrorMessage = errorText => {
    this.setState({
      errorMessage: errorText
    });
  };

  resetRequest = e => {
    const setErrorMessage = this.setErrorMessage;
    const { email } = this.state;

    try {
      noInputCheck(email, setErrorMessage);
      validEmailCheck(email, setErrorMessage);
    } catch (e) {
      return false;
    }

    api.user.password
      .resetRequest(email)
      .then(res => {
        const successMessage = "メールを送信しました";
        this.setState({ successMessage, errorMessage: "" });
      })
      .catch(e => {
        console.log(e);
      });
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { email, successMessage, errorMessage } = this.state;
    return (
      <WelcomePageBox userType="common">
        <div>
          <form onSubmit={e => e.preventDefault()}>
            <Heading>パスワード再設定</Heading>
            <Typography margin="5px 0 0">Reset your password</Typography>
            <Wrap padding="20px 0">
              <InputLabel
                name="email"
                label="メールアドレス"
                value={email}
                onChange={this.handleChange}
                errorMessage={errorMessage}
              />
              <Wrap justifyContent="flex-end">
                <LinkText
                  to={route.signInAgency}
                  style={{ textAlign: "right" }}>
                  ログインされる方はこちら
                </LinkText>
              </Wrap>
            </Wrap>
            <Wrap height="20px">
              <Typography color={color.black}>{successMessage}</Typography>
            </Wrap>
            <Wrap justifyContent="center">
              <Button onClick={this.resetRequest}>送信</Button>
            </Wrap>
          </form>
        </div>
      </WelcomePageBox>
    );
  }
}
