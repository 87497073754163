import React from "react";

import { isAgency } from "../../utils/localStorage";
import agencyList from "../../utils/agencyList.json";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";

import Navigation from "../molecules/Navigation";
import ExternalDnsRecordSets from "../templates/ExternalDnsRecordsets";
import SiteSettingSns from "../organisms/SiteSettingSns";
import SiteSettingOptions from "../organisms/SiteSettingOptions";
import SiteSettingTabelog from "../organisms/SiteSettingTabelog";
import GoogleCoop from "../organisms/GoogleCoop";
import FaviconSetting from '../organisms/FaviconSetting'

export default ({ handleChange, externalDnsRecordset, ...props }) => {
  const navs = [
    {
      label: "SNS連携設定",
      component: <SiteSettingSns {...props} />
    },
  ];

  const hostname = window.location.hostname;

  agencyList[hostname].options.tabelog &&
    navs.push({
      label: "食べログ同期",
      component: <SiteSettingTabelog {...props} />
    });

  isAgency() &&
    navs.push({
      label: "オプション設定",
      component: <SiteSettingOptions {...props} />
    });

  isAgency() && externalDnsRecordset.isExternal &&
    navs.push({
      label: "外部レジストラをご利用の方",
      component: (
        <ExternalDnsRecordSets
          {...externalDnsRecordset}
        />
      )
    });

  isAgency() &&
    navs.push({
      label: "Google認証",
      component: <GoogleCoop {...props} />
    })

  isAgency() &&
    navs.push({
      label: "ファビコン設定",
      component: <FaviconSetting {...props}/>
    })

  return (
    <Wrap padding="20px">
      <Wrap margin="20px 0 0">
        <TitleBox title="各種設定">
          <Navigation navs={navs} />
        </TitleBox>
      </Wrap>
    </Wrap>
  );
};
