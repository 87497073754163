import api from "../api";

export const recruit = {
  check: async () => {
    return api.recruit.use.check();
  },
  use: async () => {
    await api.recruit.use.post().catch(() => {
      throw new Error("recruit use false");
    });
  }
};

export const sitest = {
  check: async () => {
    return api.sitest.account().catch(e => console.log(e));
  },
  use: async () => {
    await api.sitest.update().catch(() => {
      throw new Error("sitest use false");
    });
  }
};

export const tabelog = {
  check: async () => {
    return api.tabelog.use.check();
  },
  use: async () => {
    await api.tabelog.use.post().catch(() => {
      throw new Error("tabelog use false");
    });
  },
  sync: async url => {
    await api.tabelog.update(url);
  }
};
