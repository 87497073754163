import styled from "styled-components";
import color from "../../utils/color";

export default styled.textarea`
  width: 100%;
  background-color: ${color.white};
  color: ${color.blueGray};
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-sizing: border-box;
  min-height: 100px;

  height: ${(props) => props.height};

  ::placeholder {
    color: ${color.gray};
  }

  border: 1px solid
    ${(props) =>
      props.errorMessage === "" || !props.errorMessage
        ? color.inputBorder
        : props.errorMessage === "ok"
        ? color.green
        : color.red};
`;
