import React from "react";
import api from "../../api";
import PostTemplate from "../templates/PostTemplate";

export default class extends React.Component {
  state = {
    template: "",
    templateExist: false,
    message: {},
    isLoading: true
  };

  onChangeEditor = newValue => {
    this.setState({ template: newValue });
  };

  fetchTemplate = () => {
    api.template.show().then(res => {
      const template = res.article_template && res.article_template.content;
      this.setState({ template, templateExist: res.article_template, isLoading: false });
    });
  };

  params = () => {
    const { template } = this.state;
    const body = { template: { label: "article", text: template } };

    return { body };
  };

  setSuccessMessage = () =>
    this.setState({
      message: { isSuccess: true, content: "テンプレートを更新しました" }
    });

  setErrorMessage = () =>
    this.setState({
      message: { isSuccess: false, content: "必須のタグが含まれていません" }
    });

  saveTemplate = () => {
    const { body } = this.params();

    api.template
      .update(body)
      .then(() => {
        this.setSuccessMessage();
      })
      .catch(() => {
        this.setErrorMessage();
      });
  };

  createTemplate = () => {
    const { body } = this.params();
    api.template
      .create(body)
      .then(() => {
        this.setSuccessMessage();
      })
      .catch(e => {
        this.setErrorMessage();
      });
  };

  componentDidMount() {
    this.fetchTemplate();
  }

  render() {
    const { template, templateExist, message, isLoading } = this.state;

    return (
      <PostTemplate
        postType="article"
        template={template}
        message={message}
        isLoading={isLoading}
        onChangeEditor={this.onChangeEditor}
        saveTemplate={templateExist ? this.saveTemplate : this.createTemplate}
      />
    );
  }
}
