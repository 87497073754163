import React from "react";

import api from "../../api";
import SeoPreference from "../templates/SeoPreference";
import seoPreferencesErrors from "../../utils/seoPreferencesErrors";

export default class extends React.Component {
  state = {
    seo_preferences: {
      doc: {
        title: "",
        description: "",
        keywords: ""
      },
      og: {
        title: "",
        description: "",
        image: "",
        site_name: ""
      },
      twitter: {
        title: "",
        description: "",
        image: "",
        card: ""
      }
    },
    medias: [],
    loading: true,
    messages: []
  };

  componentDidMount = async () => {
    const seo_preferences = await api.seoPreference.index().catch(e => {
      console.log(e);
    });
    console.log(seo_preferences);
    const medias = await api.media
      .index()
      .then(res => res.images)
      .catch(e => {
        console.log(e);
      });
    console.log(medias);
    this.setState({ seo_preferences, medias, loading: false });
  };

  update = () => {
    const { seo_preferences } = this.state;
    let messages = [];
    api.seoPreference
      .create({ seo_preferences: seo_preferences })
      .then(
        this.setState({
          messages: [{ isSuccess: true, content: "更新しました" }]
        })
      )
      .catch(e => {
        e.json().then(errors => {
          seoPreferencesErrors.getErrorMessages(errors.errors).forEach(m => {
            messages.push({ isSuccess: false, content: m });
          });
          this.setState({ messages });
        });
      });
  };

  handleChange = e => {
    let { seo_preferences } = this.state;
    const [seoType, property] = e.target.name.split(":");
    seo_preferences[seoType][property] = e.target.value;
    this.setState({ seo_preferences });
  };

  handleImageChange = (seoType, imageUrl) => {
    let { seo_preferences } = this.state;
    seo_preferences[seoType].image = imageUrl;
    this.setState({ seo_preferences });
  };

  render() {
    return (
      <SeoPreference
        {...this.state}
        seo_tag={this.state.seo_preferences}
        handleChange={this.handleChange}
        handleImageChange={this.handleImageChange}
        submit={this.update}
      />
    );
  }
}
