import React from "react";
import { Link, withRouter } from "react-router-dom";

import route from "../../utils/route";
import api from "../../api";
import { noInputCheck, validEmailCheck } from "../../utils/inputCheck";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import InputLabel from "../molecules/InputLabel";

class StaffShowAgency extends React.Component {
  state = {
    name: "",
    email: "",

    errorMessage: {
      name: "",
      email: ""
    }
  };

  componentDidMount() {
    const staff = this.props.location.state.staff;
    this.setState({ name: staff.name, email: staff.email });
  }

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  saveStaff = () => {
    const setErrorMessage = this.setErrorMessage;
    const { name, email } = this.state;

    try {
      noInputCheck(name, setErrorMessage, "name");
      noInputCheck(email, setErrorMessage, "email");
      validEmailCheck(email, setErrorMessage, "email");
    } catch (e) {
      return false;
    }

    const staffId = this.props.location.state.staff.id;
    api.staff
      .update(staffId, name, email)
      .then(() => {
        window.location.href = route.agencyStaffIndex;
      })
      .catch(() => {
        console.log("staff update error");
      });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, errorMessage } = this.state;

    return (
      <div>
        <Wrap padding="20px">
          <Wrap margin="0 0 15px">
            <Link to={route.agencyStaffIndex}>
              <Button>一覧へ</Button>
            </Link>
          </Wrap>
          <TitleBox title="スタッフ情報">
            <Wrap margin="auto" width="70%">
              <form onSubmit={e => e.preventDefault()}>
                <InputLabel
                  label="名前"
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  errorMessage={errorMessage.name}
                />
                <InputLabel
                  label="メールアドレス"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  errorMessage={errorMessage.email}
                />
                <Wrap justifyContent="center" margin="15px 0 0">
                  <Button onClick={this.saveStaff}>保存</Button>
                </Wrap>
              </form>
            </Wrap>
          </TitleBox>
        </Wrap>
      </div>
    );
  }
}

export default withRouter(StaffShowAgency);
