import React from "react";

import api from "../../api";
import route from "../../utils/route";
import roles from "../../utils/roles";
import { setStoreId } from "../../utils/localStorage";
import {
  noInputCheck,
  validEmailCheck,
  passwordCheck
} from "../../utils/inputCheck";

import AccountNew from "../templates/AccountNew";

export default class extends React.Component {
  state = {
    loading: false,
    loadingMessage: "",
    name: "",
    staffName: "",
    staffEmail: "",
    password: "",
    password_confirmation: "",
    use_sitest: false,
    use_recruit: false,
    errorMessage: {
      name: "",
      staffName: "",
      staffEmail: "",
      password: "",
      password_confirmation: ""
    }
  };

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  createStore = () => {
    const setErrorMessage = this.setErrorMessage;
    const {
      name,
      staffEmail,
      staffName,
      password,
      password_confirmation,
      use_sitest
    } = this.state;

    try {
      noInputCheck(name, setErrorMessage, "name");
      noInputCheck(staffName, setErrorMessage, "staffName");
      noInputCheck(staffEmail, setErrorMessage, "staffEmail");
      validEmailCheck(staffEmail, setErrorMessage, "staffEmail");
      noInputCheck(password, setErrorMessage, "password");
      passwordCheck(password, password_confirmation, setErrorMessage);
    } catch (e) {
      return false;
    }

    const role = "normal";
    const body = {
      store: { name, email: staffEmail },
      staff: {
        name: staffName,
        email: staffEmail,
        role,
        password,
        password_confirmation
      },
      use_sitest: use_sitest
    };

    this.setState({ loading: true });

    api.stores
      .create(body)
      .then(res => {
        setStoreId(res.store.id);
        this.useRecruit();
        this.useSitest();
        setTimeout(() => {
          window.location.href = route.storeIndex;
        }, 2000);
      })
      .catch(e =>
        e.json().then(err => {
          this.setState({ loading: false });
          console.log(err.error);
          this.setState({
            errorMessage: {
              name:
                err.error.store.name && "この店舗名はすでに使用されています。",
              staffEmail:
                err.error.staff.email &&
                "このスタッフメールアドレスはすでに使用されています。"
            }
          });
          return false;
        })
      );
  };

  useSitest = () => {
    const { use_sitest } = this.state;
    if (use_sitest) {
      api.sitest
        .create()
        .then(() => {
          console.log("create sitest");
        })
        .catch(e => {
          console.error(e);
          this.setState({
            loadingMessage:
              "アクセス解析アカウントの発行に失敗しました。アクセス解析画面を利用できない場合は、運営にお問い合わせください。"
          });
        });
    }
  };
  useRecruit = () => {
    const { use_recruit } = this.state;
    if (use_recruit) {
      api.recruit.use
        .post()
        .then(() => console.log("use recruit"))
        .catch(e => console.log(e));
    }
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  handleCheck = name => this.setState({ [name]: !this.state[name] });

  render() {
    return (
      <AccountNew
        accountName="店舗"
        handleChange={this.handleChange}
        handleCheck={this.handleCheck}
        createStore={this.createStore}
        roleOptions={roles}
        {...this.state}
      />
    );
  }
}
