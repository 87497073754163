import React, { useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import color from "../../utils/color";

import Tag from "../atoms/Tag";
import Wrap from "../atoms/Wrap";

const ListItem = styled.li`
  color: ${color.white};
  font-size: 13px;
  transition: 0.2s;
  background-color: ${props => props.openSubMenu && color.blackDark};

  :hover {
    svg {
      color: ${color.white};
    }
  }

  a {
    display: block;
  }
  .main-link {
    padding: 12px 16px;
    :hover {
      background-color: ${props => !props.openSubMenu && color.blue};
    }
  }
  .sub-link {
    padding: 9px 16px;
    :hover {
      background-color: ${color.blue};
    }
  }
`;

const CaretLeftIcon = ({ openSubMenu }) => {
  return (
    <FontAwesomeIcon
      icon={faCaretLeft}
      style={{
        transform: openSubMenu && "rotate(-90deg)",
        transition: "0.15s"
      }}
    />
  );
};

const ComingSoonTag = () => (
  <Tag fontColor="black" color={color.yellow} style={{ marginLeft: 10 }}>
    開発中
  </Tag>
);

const SubListItems = styled.li`
  font-size: 11px;
  padding-left: 24px;
`;

const SubMenu = ({ subMenu }) => {
  return (
    <ul>
      {subMenu.map((m, i) => (
        <Link key={i} to={m.to} className="sub-link">
          <SubListItems>
            <Wrap justifyContent="space-between">
              {m.name}
              {m.coming && <ComingSoonTag />}
            </Wrap>
          </SubListItems>
        </Link>
      ))}
    </ul>
  );
};

export default ({ nav }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  return (
    <ListItem openSubMenu={nav.subMenu && openSubMenu}>
      <Link
        className="main-link"
        to={nav.subMenu || nav.coming ? "#" : nav.to}
        onClick={e => {
          setOpenSubMenu(!openSubMenu);
          nav.subMenu && e.preventDefault();
        }}
      >
        <Wrap justifyContent="space-between" alignItems="center">
          <div>
            <FontAwesomeIcon
              icon={nav.icon}
              color={color.typoLight}
              style={{ marginRight: 10 }}
            />
            {nav.name}
            {nav.coming && <ComingSoonTag />}
          </div>
          {nav.subMenu && <CaretLeftIcon openSubMenu={openSubMenu} />}
        </Wrap>
      </Link>
      {nav.subMenu && openSubMenu && <SubMenu subMenu={nav.subMenu} />}
    </ListItem>
  );
};
