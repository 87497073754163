import React from "react";

import api from "../../api";
import * as optionActions from "../../utils/optionActions";

import OptionUnregisterd from "../molecules/OptionUnregistered";
import JobOfferIndex from "../templates/JobOfferIndex";

export default class extends React.Component {
  state = { forms: [], loading: true, dom: "", useRecruit: false };

  currentDom = forms => {
    const { state } = this.props.location;
    if (state && state.fromDetailPage) {
      const currentForm = forms.reduce(
        (pre, cur) => (pre.id < cur.id ? cur : pre),
        { id: 0 }
      );
      const form = forms.find(f => f.id === (state.formId || currentForm.id));
      return form.dom;
    }
  };

  fetch = async () => {
    const allForms = await api.recruit.forms.index().catch(e => console.log(e));
    const recruitForms =
      allForms && allForms.forms.filter(f => f.contact_type === "recruit");

    this.setState({
      forms: recruitForms,
      loading: false,
      dom: this.currentDom(recruitForms)
    });
  };

  componentDidMount() {
    this.checkUseRecruit();
    this.fetch();
  }

  checkUseRecruit = async () => {
    const useRecruit = await optionActions.recruit.check();
    this.setState({ useRecruit });
  };

  render() {
    const { useRecruit, loading } = this.state;
    return useRecruit ? (
      <JobOfferIndex {...this.state} />
    ) : (
      <OptionUnregisterd optionName="求人機能" loading={loading} />
    );
  }
}
