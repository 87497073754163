import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  faEdit,
  faDownload,
  faCheck,
  faGlobeAsia
} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { storeId, isAgency } from "../../utils/localStorage";
import route from "../../utils/route";
import { unixtimeToDate } from "../../utils/common";
import color from "../../utils/color";
import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Loading from "../atoms/Loading";
import { Notice } from "../atoms/Notice";
import Button from "../atoms/Button";
import Tag from "../atoms/Tag";
import IconButton from "../atoms/IconButton";
import Table from "../molecules/Table";
import Input from "../atoms/Input";

const ToShowButton = ({ to, data }) => {
  return (
    <Link
      to={{
        pathname: to,
        state: { data }
      }}
    >
      <IconButton icon={faEdit} tip="バージョン情報" />
    </Link>
  );
};

const DownloadZipButton = ({ onClick, status }) => {
  return (
    <IconButton
      onClick={onClick}
      icon={status ? faCheck : faDownload}
      tip="ZIPファイルダウンロード"
      disabled={status}
    />
  );
};

const PreviewButton = ({ onClick }) => {
  return <IconButton onClick={onClick} icon={faGlobeAsia} tip="プレビュー" />;
};

const ToggleInput = ({
  title,
  onClick,
  index,
  selectedVersion,
  enterInput,
  versionsLoading,
  isError,
  enteredVersion
}) => {
  const stringTitle = new String(title);
  let errorMessage = "";

  const [tmpTitle, setTmpTitle] = useState(title);
  const handleChange = (e) => {
    if (e.target.value.length <= 30) {
      setTmpTitle(e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (!`${tmpTitle}`.trim()) {
        errorMessage = "空白のみは許可されていません.";
        return;
      }
      enterInput(tmpTitle);
    }
  };
  return selectedVersion !== index ? (
    <p onClick={onClick} style={{ width: "100%", height: "1rem" }}>
      {tmpTitle}
    </p>
  ) : (
    <div>
      <Input
        type="text"
        placeholder={tmpTitle}
        width="200px"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={tmpTitle}
        style={{ width: "calc(100% - 50px)" }}
      />
      <div style={{ display: "inline-block" }}>
        <div style={{ margin: " 0 0 0 4px" }}>
          {tmpTitle ? tmpTitle.length : 0}/30
        </div>
      </div>
      <div style={{ height: "11px" }}>
        {versionsLoading ? (
          <ReactLoading
            type="bars"
            color={color.blue}
            width="20px"
            height="35px"
          />
        ) : (
          <div></div>
        )}
      </div>
      {isError === true && enteredVersion === index ? (
        <h4>{errorMessage}</h4>
      ) : (
        <h4></h4>
      )}
    </div>
  );
};

const protocol = "https://";

export default ({
  sources,
  rollBack,
  headVersion,
  loading,
  hpDomain,
  successUpdateMessage,
  handleClickDownload,
  domainVerified,
  handleClickPreview,
  handleChange,
  enterInput,
  selectedVersion,
  enteredVersion,
  versionsLoading,
  isError
}) => {
  const newPath = isAgency()
    ? route.store(storeId()).versionsNew
    : route.versionsNew;
  const showPath = isAgency()
    ? route.store(storeId()).versionsShow
    : route.versionsShow;
  const tableDatas = (showPath, sources) =>
    sources &&
    sources.map((s, i) => {
      const { data } = s;
      const title = data.data.title;
      const version = data.data.version;
      const isHead = data.data.version === headVersion;
      const created_at = data.data.created_at;
      const [done, setDone] = React.useState(false);
      return [
        i + 1,
        <div>
          <ToggleInput
            title={title}
            index={i}
            selectedVersion={selectedVersion}
            enterInput={enterInput}
            onClick={() => handleChange(i)}
            versionsLoading={versionsLoading}
            isError={isError}
            enteredVersion={enteredVersion}
          />
        </div>,
        <>{isHead && <Tag style={{ marginLeft: 10 }}>公開中</Tag>}</>,
        data.data.metadata.committed_by,
        unixtimeToDate(data.data.created_at),
        <Wrap alignItems="center">
          <Wrap justifyContent="space-between" width="100px">
            <ToShowButton to={showPath} data={data} />
            <DownloadZipButton
              onClick={() => {
                handleClickDownload(created_at, version);
                setDone(true);
              }}
              status={done}
            />
            <PreviewButton onClick={() => handleClickPreview(version)} />
          </Wrap>
          {domainVerified && !isHead && (
            <Button
              size="small"
              style={{ marginLeft: 10 }}
              onClick={() => rollBack && rollBack(version)}
            >
              公開
            </Button>
          )}
        </Wrap>
      ];
    });

  return (
    <>
      {loading ? (
        <Loading label="バージョン一覧を取得中..." />
      ) : (
        <Wrap padding="20px">
          {successUpdateMessage && (
            <Notice color="green" margin="0 0 10px 0">
              {successUpdateMessage}
            </Notice>
          )}
          {newPath && (
            <Wrap justifyContent="flex-end" margin="0 0 10px">
              {hpDomain && (
                <a
                  href={`${protocol}${hpDomain}`}
                  style={{ marginRight: 10 }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button>公開中のサイトを別タブで開く</Button>
                </a>
              )}
              <Link to={newPath}>
                <Button>バージョン追加</Button>
              </Link>
            </Wrap>
          )}
          <TitleBox title="バージョン一覧">
            <Table
              tableHeads={[
                "ID",
                "バージョン",
                "ステータス",
                "編集者",
                "追加日",
                storeId() && ""
              ]}
              tableDatas={tableDatas(showPath, sources)}
            />
          </TitleBox>
        </Wrap>
      )}
    </>
  );
};
