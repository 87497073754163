import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import color from "../../utils/color";

const Text = styled.span`
  color: ${color.blue};
  font-size: 13px;
  margin: 5px 0;

  :hover {
    text-decoration: underline;
  }
`;

export default ({ children, to, style, ...props }) => {
  return (
    <Link to={to} {...props}>
      <Text style={style}>{children}</Text>
    </Link>
  );
};
