import React from "react";

import Label from "../atoms/Label";
import SelectBox from "../atoms/SelectBox";
import RequiredMark from "../atoms/RequiredMark";

export default ({ label, required, ...props }) => {
  return (
    <div style={{ margin: "15px 0" }}>
      <Label>
        {label}
        {required && <RequiredMark />}
      </Label>
      <SelectBox {...props} />
    </div>
  );
};
