import React from "react";

import api from "../../api";
import { isAgency } from "../../utils/localStorage";

import Progress from "../atoms/Progress";
import VersionsIndex from "../templates/VersionsIndex";

export default class extends React.Component {
  state = {
    sources: [],
    bucketName: "",
    headVersion: "",
    loading: true,
    hpDomain: "",
    domainVerified: false,
    downloading: false,
    selectedVersion: null,
    enteredVersion:null,
    title: "",
    versionsLoading: false,
    isError:false,

    storeId: null,
  };

  verifyDomain = async () => {
    const result = isAgency()
      ? await api.stores.current().catch(e => {
          e.json().then(err => console.log(err));
        })
      : await api.user.me().catch(e => {
          e.json().then(err => console.log(err));
        });

    const bucketName = result && result.aws_resource.s3_bucket;
    this.setState({ bucketName });

    const store = result && (isAgency() ? result : result.affiliation);

    const awsState = store && store.aws_state;

    if (awsState === "setup_completed") {
      this.setState({ domainVerified: true });
    }

    this.setState({ storeId: store.id })
  };

  fetch = async () => {
    const sources = await api.versions.index().catch(e => {
      e.json().then(err => console.log(err));
    });
    const store = await (isAgency()
      ? api.stores.current()
      : api.user.me()
    ).catch(e => {
      e.json().then(err => console.log(err));
    });

    const hpDomain = store && store.aws_resource.hp_domain;
    const headVersion =
      store && isAgency() ? store.head_version : store.affiliation.head_version;

    this.setState({ sources, headVersion, loading: false, hpDomain });
  };

  componentDidMount() {
    this.verifyDomain();
    this.fetch();
  }

  rollBack = version => {
    const body = { rollback: { version: version } };

    this.setState({ loading: true });

    api.versions
      .rollback(body)
      .then(() => {
        this.fetch();
      })
      .catch(e => {
        this.fetch();
        e.json().then(err => console.log(err));
      });
  };

  handleClickDownload = (filename, version_hash) => {
    this.setState({ downloading: true });

    api.versions
      .archive(version_hash)
      .then(res => {
        const uri = URL.createObjectURL(res);
        const link = document.createElement("a");
        link.download = filename;
        link.href = uri;
        link.click();
        this.setState({ downloading: false });
      })
      .catch(e => {
        this.setState({ downloading: false });
      });
  };

  handleChange = (index) => {
    this.setState({ selectedVersion: index });
  };

  enterInput = (title) => {
    const { selectedVersion } = this.state;
    this.setState({
      versionsLoading: true,
      enteredVersion:this.state.selectedVersion
    });
    this.updateTitle(title, selectedVersion);
  };

  updateTitle = async (title, selectedVersion) => {
    const sources = [...this.state.sources];
    sources[selectedVersion].data.title = title;
    await api.versions
      .update(sources[selectedVersion].data.data.version, title)
      .catch((e) => {
        e.json().then((err) => console.log(err));
        this.setState({
          isError: true,
          versionsLoading:false
        })
      });

    if (!this.state.isError) {
      this.setState({
      versionsLoading: false,
      sources: sources,
      selectedVersion: null,
      enteredVersion:null,
      isError:false
    })
    }
  };

  closeModal = () => this.setState({ showModal: false });

  handleClickPreview = versionHash => {
    const { storeId, bucketName } = this.state;
    const url = `https://${storeId}-${bucketName}-${versionHash}.${
      process.env.NODE_ENV === "development" ||
        window.location.host === "staging.ccmphp.com"
        ? "staging"
        : "production"
      }.preview.ccmphp.com/`;
    window.open(url);
  };

  render() {
    const { state } = this.props.location;
    const { downloading } = this.state;
    const successUpdateMessage =
      state !== undefined && state.successUpdateMessage;
    return (
      <>
        <VersionsIndex
          rollBack={this.rollBack}
          {...this.state}
          successUpdateMessage={successUpdateMessage}
          handleClickDownload={this.handleClickDownload}
          handleClickPreview={this.handleClickPreview}
          handleChange={this.handleChange}
          enterInput={this.enterInput}
        />
        {downloading && (
          <Progress>
            間もなくダウンロードが開始します。少々お待ち下さい...
          </Progress>
        )}
      </>
    );
  }
}
