import React from "react";
import { Link } from "react-router-dom";

import route from "../../utils/route";
import api from "../../api";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import Button from "../atoms/Button";
import IconButton from "../atoms/IconButton";
import Table from "../molecules/Table";

const tableDatas = notifications => {
  return (
    notifications &&
    notifications.map(n => {
      return [
        n.id,
        n.title,
        new Date(n.created_at).toLocaleDateString(),
        new Date(n.updated_at).toLocaleDateString(),
        <Link
          to={{
            pathname: route.notificationsEdit,
            state: { notification: n }
          }}>
          <IconButton icon={faEdit} tip="編集" />
        </Link>
      ];
    })
  );
};

export default class extends React.Component {
  state = { notifications: [] };

  fetch = async () => {
    const notifications = await api.notification.index().catch(e => {
      console.error(e);
    });

    console.log(notifications);
    this.setState({ notifications });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    const { notifications } = this.state;
    return (
      <>
        <Wrap justifyContent="flex-end" margin="0 0 10px">
          <Link to={route.notificationsNew}>
            <Button>新規お知らせ投稿</Button>
          </Link>
        </Wrap>
        <TitleBox title="店舗へのお知らせ一覧">
          {notifications.length === 0 ? (
            <Typography margin="30px 0 10px" style={{ textAlign: "center" }}>
              お知らせを追加してください
            </Typography>
          ) : (
            <Table
              tableHeads={["ID", "タイトル", "投稿日", "最終更新日", "操作"]}
              tableDatas={tableDatas(notifications)}
            />
          )}
        </TitleBox>
      </>
    );
  }
}
