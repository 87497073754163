export default {
  common: {
    password: {
      resetRequest: "/password_reset_request",
      reset: "/password_reset",
    },
  },
  agency: (storeId) => ({
    staff: {
      root: "/agency/staff",
      update: (staffId) => `/agency/staff/${staffId}`,
    },
    versions: {
      root: `/agency/stores/${storeId}/versions`,
      update: (versionHash) =>
        `/agency/stores/${storeId}/versions?version_hash=${versionHash}`,
      archive: (versionHash) =>
        `/agency/stores/${storeId}/version_archives?version_hash=${versionHash}`,
      rollbacks: `/agency/stores/${storeId}/rollbacks`,
    },
    source: {
      show: (object_hash) =>
        `/agency/stores/${storeId}/source_files?object_hash=${object_hash}`,
      update: `/agency/stores/${storeId}/source_files`,
    },
    template: `/agency/stores/${storeId}/template`,
    category: {
      root: `/agency/stores/${storeId}/subcategories`,
      article: `/agency/stores/${storeId}/subcategories?subcategory[category]=articles`,
      news: `/agency/stores/${storeId}/subcategories?subcategory[category]=news`,
    },
    article: {
      root: `/agency/stores/${storeId}/articles`,
      edit: (filepath) =>
        `/agency/stores/${storeId}/articles/edit?filepath=${filepath}`,
      delete: (filepath) =>
        `/agency/stores/${storeId}/articles?filepath=${filepath}`,
    },
    news: {
      root: `/agency/stores/${storeId}/news`,
      edit: (filepath) =>
        `/agency/stores/${storeId}/news/edit?filepath=${filepath}`,
      delete: (filepath) =>
        `/agency/stores/${storeId}/news?filepath=${filepath}`,
    },
    media: {
      index: (page) => `/agency/stores/${storeId}/images?page=${page}`,
      root: `/agency/stores/${storeId}/images`,
      delete: (id) => `/agency/stores/${storeId}/images/${id}`,
    },
    domain: {
      contact: `/agency/stores/${storeId}/domain`,
      availability: "/agency/domain_availability",
    },
    store: {
      index: (page, search) => `/agency/stores?page=${page}&search=${search}`,
      root: "/agency/stores",
      show: `/agency/stores/${storeId}`,
    },
    agency: {
      index: (page) => `/agency/agencies?page=${page}`,
      root: "/agency/agencies",
      me: "/agency/me",
    },
    signIn: "/agency/login",
    password: {
      update: "/agency/password",
    },
    contact: {
      index: (createdAt) =>
        createdAt
          ? `/agency/stores/${storeId}/common_contacts?created_at=${createdAt}`
          : `/agency/stores/${storeId}/common_contacts`,
      update: (contactId) =>
        `/agency/stores/${storeId}/common_contacts/${contactId}`,
      forms: `/agency/stores/${storeId}/contact_forms`,
      form: (formId) => `/agency/stores/${storeId}/contact_forms/${formId}`,
    },
    recruit: {
      use: `/agency/stores/${storeId}/recruit`,
      forms: `/agency/stores/${storeId}/recruit_forms`,
      form: (formId) => `/agency/stores/${storeId}/recruit_forms/${formId}`,
      applicants: (created_at) =>
        `/agency/stores/${storeId}/recruitment_applicants${
          created_at ? `?created_at=${created_at}` : ""
        }`,
    },
    notifications: {
      index: "/agency/notifications",
      show: (id) => `/agency/notifications/${id}`,
      dashboardIndex: `/agency/stores/${storeId}/notifications`,
    },
    sitest_account: {
      root: `/agency/stores/${storeId}/sitest_account`,
      update: `/agency/stores/${storeId}/sitest_account?use_sitest=true`,
    },
    sns: {
      auth: `/agency/stores/${storeId}/omniauth_uris`,
      root: `/agency/stores/${storeId}/social_accounts`,
      delete: (id) => `/agency/stores/${storeId}/social_accounts/${id}`,
    },
    seoPreference: {
      root: `/agency/stores/${storeId}/seo_preferences`,
    },
    externalDomain: {
      root: `/agency/stores/${storeId}/external_domain`,
    },
    externalDnsRecordset: {
      root: `/agency/stores/${storeId}/external_dns_recordset`,
    },
    accessKey: {
      root: `/agency/stores/${storeId}/access_key`,
    },
    tabelog: {
      use: `/agency/stores/${storeId}/tabelog`,
      update: `/agency/stores/${storeId}/tabelog_menu`,
    },
    googleOauthUri: {
      show: `/agency/stores/${storeId}/google/oauth_uri`,
    },
    sitemap: {
      create: `/agency/stores/${storeId}/sitemap`,
      submit: `/agency/stores/${storeId}/sitemap`,
    },
    googleAnalytics: {
      show: `/agency/stores/${storeId}/google_analytics`,
    },
    gaManagement: {
      root: `/agency/stores/${storeId}/ga_management`,
    },
    favicon: {
      create: `/agency/stores/${storeId}/favicon`,
    },
    applyTemplate: {
      update: `/agency/stores/${storeId}/apply_template`,
    },
  }),
  store: {
    me: "/store/me",
    signIn: "/store/login",
    staff: {
      root: "/store/staff",
      update: (staffId) => `/store/staff/${staffId}`,
    },
    versions: {
      root: `/store/versions`,
      update: (versionHash) => `/store/versions?version_hash=${versionHash}`,
      archive: (versionHash) =>
        `/store/version_archives?version_hash=${versionHash}`,
      rollbacks: `/store/rollbacks`,
    },
    source: {
      show: (object_hash) => `/store/source_files?object_hash=${object_hash}`,
      update: `/store/source_files`,
    },
    template: { root: "/store/template" },
    category: {
      root: "/store/subcategories",
      article: `/store/subcategories?subcategory[category]=articles`,
      news: `/store/subcategories?subcategory[category]=news`,
    },
    article: {
      root: "/store/articles",
      edit: (filepath) => `/store/articles/edit?filepath=${filepath}`,
      delete: (filepath) => `/store/articles?filepath=${filepath}`,
    },
    news: {
      root: "/store/news",
      edit: (filepath) => `/store/news/edit?filepath=${filepath}`,
      delete: (filepath) => `/store/news?filepath=${filepath}`,
    },
    media: {
      index: (page) => `/store/images?page=${page}`,
      root: "/store/images",
      delete: (id) => `/store/images/${id}`,
    },
    sitest_account: { root: "/store/sitest_account" },
    password: {
      update: "/store/password",
    },
    contact: {
      index: (createdAt) =>
        createdAt
          ? `/store/common_contacts?created_at=${createdAt}`
          : `/store/common_contacts`,
      forms: `/store/contact_forms`,
      form: (formId) => `/store/contact_forms/${formId}`,
    },
    recruit: {
      use: `/store/recruit`,
      forms: `/store/recruit_forms`,
      form: (formId) => `/store/recruit_forms/${formId}`,
      applicants: (created_at) =>
        `/store/recruitment_applicants${
            created_at ? `?created_at=${created_at}` : ""
          }`,
    },
    notifications: {
      index: "/agency/notifications",
      show: (id) => `/agency/notifications/${id}`,
      dashboardIndex: `/store/notifications`
    },
    sns: {
      auth: "/store/omniauth_uris",
      root: "/store/social_accounts",
      delete: (id) => `/store/social_accounts/${id}`
    },
    seoPreference: {
      root: `/store/seo_preferences`
    },
    tabelog: {
      use: "/store/tabelog",
      update: `/store/tabelog_menu`
    },
    store: {
      root: "/store/store"
    },
    googleAnalytics: {
      show: `/store/google_analytics`
    },
    gaManagement: {
      root: `/store/ga_management`
    },
    applyTemplate: {
      update: `/store/apply_template`
    },
  },
};
