import api from "../api";

const fetchForm = async (state, isRecruit) => {
  if (state && state.isEdit) {
    const { formId } = state && state;
    const res = await (isRecruit
      ? api.recruit.forms.show(formId)
      : api.contact.forms.show(formId)
    ).catch((e) => console.error(e));

    const {
      name,
      pub_email,
      id,
      schema,
      dom,
      redirect_to,
      reply_type,
      reply_subject,
      reply_message
    } = res.form;
    return {
      name,
      pub_email,
      isEditPage: true,
      formId: id,
      forms: schema,
      code: dom,
      redirect_to,
      reply_type,
      reply_subject,
      reply_message
    };
  }
};

export { fetchForm };
export default fetchForm;
