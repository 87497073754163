import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import FileBrowser from "react-keyed-file-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faFolderOpen,
  faFileAlt,
  faImage,
  faFilePdf,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import color from "../../utils/color";
import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import Label from "../atoms/Label";
import { Notice } from "../atoms/Notice";
import InputLabel from "../molecules/InputLabel";

const Container = styled(Wrap)`
  .rendered-file-browser + div {
    display: none;
  }
  .selected {
    background-color: ${color.grayLight};
  }
  input {
    width: 100%;
    background-color: ${color.white};
    color: ${color.blueGray};
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    box-sizing: border-box;
    margin: 0 !important;
  }
  .files {
    th:nth-child(2),
    td:nth-child(2) {
      display: none;
    }
  }
  .modified {
    display: none;
  }
`;

const BasicStyleIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const BlueIcon = styled(BasicStyleIcon)`
  color: ${color.blueDark};
`;

const GrayIcon = styled(BasicStyleIcon)`
  color: ${color.typoLight};
`;

export default ({
  data,
  indexPath,
  handleClickFile,
  message,
  title,
  handleChange,
  update,
  errorMessage,
}) => {
  const version_hash = data && data.data.version;
  const files =
    data &&
    data.data.filepaths.map(path => ({
      key: path,
      size: "?",
      object_hash: data.data.flatten_tree
        .find(item => item.path === path)
        .endpoint.replace("/objects/", ""),
    }));

  return (
    <Container padding="20px">
      <Wrap margin="10px 0 0">
        <TitleBox title="ソースファイル一覧" margin="10px 0 0">
          <Typography margin="0 0 30px">
            ソースファイルをクリックすると編集画面へ遷移します。
          </Typography>
          {message.error && <Notice color="red">{message.error}</Notice>}
          <Label>ファイル検索</Label>
          <FileBrowser
            files={files}
            icons={{
              File: <GrayIcon icon={faFileAlt} />,
              Image: <GrayIcon icon={faImage} />,
              PDF: <GrayIcon icon={faFilePdf} />,
              Folder: <BlueIcon icon={faFolder} />,
              FolderOpen: <BlueIcon icon={faFolderOpen} />,
              Loading: <BlueIcon icon={faSpinner} />,
            }}
            onSelectFile={file => handleClickFile(file, version_hash)}
          />
        </TitleBox>
      </Wrap>
    </Container>
  );
};
