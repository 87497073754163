import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { isAgency } from "../../utils/localStorage";
import color from "../../utils/color";
import route from "../../utils/route";
import checkContentLength from "../../utils/checkContentLength";

import TitleBox from "../atoms/TitleBox";
import Typography from "../atoms/Typography";
import Wrap from "../atoms/Wrap";
// import LinkText from "../atoms/LinkText";

const NotificationRow = styled(Wrap)`
  transition: 0.15s;
  :hover {
    background-color: ${color.grayLight};
  }
`;

const CreatedAt = styled.div`
  font-size: 12px;
  color: ${color.typoLight};
  margin-right: 15px;
  width: 60px;
`;

const NewLabel = styled.div`
  background-color: ${color.red};
  font-size: 11px;
  color: ${color.white};
  padding: 3px;
  border-radius: 3px;
  margin-left: 5px;
`;

const Title = styled.div`
  font-size: 14px;
`;

export default ({ style, notifications }) => {
  const today = new Date();
  return (
    <TitleBox
      padding="20px"
      style={style}
      title={`${isAgency() ? "店舗へ" : "代理店から"}のお知らせ`}>
      {!notifications || notifications.length === 0 ? (
        <Typography>お知らせはありません。</Typography>
      ) : (
        notifications.map((n, i) => {
          const createdAt = new Date(n.created_at);
          const createdAtDisplay = createdAt.toLocaleDateString();
          const isNew =
            today >= createdAt &&
            new Date().setDate(today.getDate() - 10) <= createdAt;
          return (
            <Link
              key={i}
              to={{
                pathname: isAgency()
                  ? route.notificationsEdit
                  : route.notificationsShow,
                state: { notification: n }
              }}>
              <NotificationRow key={i} alignItems="center" padding="7px">
                <CreatedAt>{createdAtDisplay}</CreatedAt>
                <Title>{checkContentLength(n.title, 10)}</Title>
                {isNew && <NewLabel>NEW</NewLabel>}
              </NotificationRow>
            </Link>
          );
        })
      )}
      {/* <Wrap justifyContent="center" margin="15px 0 0">
        <LinkText to="#">代理店へのお問い合わせはこちら</LinkText>
      </Wrap> */}
    </TitleBox>
  );
};
