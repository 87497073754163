import React from "react";

import route from "../../utils/route";
import api from "../../api";
import { storeId, isAgency } from "../../utils/localStorage";

import VersionsNew from "../templates/VersionsNew";

export default class extends React.Component {
  state = { zipFile: "", loading: false, title: "", errorMessage: "" };

  handleChange = e => this.setState({ zipFile: e.target.files[0] });
  dropChange = file => this.setState({ zipFile: file[0] });
  handleTitleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  uploadFile = async () => {
    const { zipFile, title } = this.state;
    this.setState({ loading: true, errorMessage: "" });

    api.versions
      .create(zipFile, title)
      .then(() => {
        window.location.href = isAgency()
          ? route.store(storeId()).versionsIndex
          : route.versionsIndex;
      })
      .catch(e => {
        this.setState({
          loading: false,
          errorMessage: "すでにアップロード済みか，不正なファイルです"
        });
        e.json()
          .then(err => console.error(err))
          .catch(() => {
            console.log("json error");
          });
      });
  };

  render() {
    return (
      <VersionsNew
        {...this.state}
        handleChange={this.handleChange}
        handleTitleChange={this.handleTitleChange}
        dropChange={this.dropChange}
        uploadFile={this.uploadFile}
      />
    );
  }
}
