import React from "react";

import api from "../../api";
import route from "../../utils/route";
import { noInputCheck, passwordCheck } from "../../utils/inputCheck";

import AccountNew from "../templates/AccountNew";

export default class extends React.Component {
  state = {
    loading: false,
    name: "",
    email: "",
    staffName: "",
    staffEmail: "",
    password: "",
    password_confirmation: "",

    errorMessage: {
      name: "",
      email: "",
      staffName: "",
      staffEmail: "",
      password: "",
      password_confirmation: ""
    }
  };

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  createAgency = () => {
    const setErrorMessage = this.setErrorMessage;
    const {
      name,
      email,
      staffName,
      staffEmail,
      password,
      password_confirmation
    } = this.state;

    try {
      noInputCheck(name, setErrorMessage, "name");
      noInputCheck(email, setErrorMessage, "email");
      noInputCheck(staffName, setErrorMessage, "staffName");
      noInputCheck(staffEmail, setErrorMessage, "staffEmail");
      noInputCheck(password, setErrorMessage, "password");
      passwordCheck(password, password_confirmation, setErrorMessage);
    } catch (e) {
      return false;
    }

    const role = "admin";
    const body = {
      agency: { name, email },
      staff: {
        name: staffName,
        email: staffEmail,
        role,
        password,
        password_confirmation
      }
    };

    this.setState({ loading: true });

    api.agencies
      .create(body)
      .then(() => {
        window.location.href = route.agencyIndex;
      })
      .catch(e =>
        e.json().then(err => {
          this.setState({ loading: false });
          console.log(err);
          this.setState({
            errorMessage: {
              name:
                err.error.agency.name &&
                "この代理店名はすでに使用されています。",
              email:
                err.error.agency.email &&
                "この代理店メールアドレスはすでに使用されています。",
              staffEmail:
                err.error.staff.email &&
                "このスタッフメールアドレスはすでに使用されています。"
            }
          });
        })
      );
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <AccountNew
        accountName="代理店"
        handleChange={this.handleChange}
        createStore={this.createAgency}
        {...this.state}
      />
    );
  }
}
