import React from "react";

import { isAgency, storeId } from "../../utils/localStorage";
import route from "../../utils/route";
import * as optionActions from "../../utils/optionActions";
import api from "../../api";
import { externalAwsStatusMessages } from "../../utils/awsState";
import SettingSite from "../templates/SettingSite";
import agencyList from "../../utils/agencyList.json";

export default class extends React.Component {
  state = {
    socialAccountInfos: { twitter: {} },
    authUris: { twitter: "" },

    externalDnsRecordset: {
      certificateMessage: "",
      publicateMessage: "",
      certificateRecordset: { name: "", type: "", value: "" },
      publicateRecordset: { name: "", type: "", value: "" },
      isExternal: false
    },
    sns: {
      socialAccountInfos: { twitter: {} },
      authUris: { twitter: "" }
    },
    options: {
      sitest: false,
      recruit: false,
      tabelog: false
    },
    loading: {
      sns: true,
      options: true
    },
    showPopUp: false,
    showSitestModel: false,
    tabelog: { message: "", input: "" },
    google: {
      authUri: "",
      updatedAt: null,
      resource: null
    },
    isShownGoogleModal: false,
    createdGaProject: false,
    gaProjectLoading: false,
    favicon: "",
    faviconMessage: { type: "", message: "" }
  };

  componentDidMount() {
    Promise.all([
      this.fetchSns(),
      this.fetchStore(),
      this.fetchDnsRecordSet(),
      this.fetchOptions(),
      this.fetchAuthUri(),
      this.fetchGaResource()
    ]);
  }

  fetchOptions = async () => {
    let options = {};
    const promiseList = [];
    const hostname = window.location.hostname;
    if (agencyList[hostname].options.recruit) {
      promiseList.push(
        optionActions.recruit
          .check()
          .then(res => {
            options.recruit = res.use_recruit;
          })
          .catch(e => console.log(e))
      );
    }
    if (agencyList[hostname].options.sitest) {
      promiseList.push(
        optionActions.sitest
          .check()
          .then(res => {
            options.sitest = res.use_sitest;
          })
          .catch(e => console.log(e))
      );
    }
    if (agencyList[hostname].options.tabelog) {
      promiseList.push(
        optionActions.tabelog
          .check()
          .then(res => {
            options.tabelog = res.use_tabelog;
          })
          .catch(e => console.log(e))
      );
    }
    Promise.all(promiseList).then(() => {
      this.setState({
        options,
        loading: { ...this.state.loading, options: false }
      });
    });
  };

  fetchAuthUri = async () => {
    api.googleOauthUri
      .show()
      .then(res => {
        console.log(res);
        this.setState({
          google: {
            authUri: res.context.auth_uri,
            updatedAt: res.context.updated_at
          }
        });
      })
      .catch(e => {
        console.error(e);
      });
  };

  fetchGaResource = async () => {
    api.gaManagement
      .show()
      .then(res => {
        console.log(res);
        const { google } = this.state;
        this.setState({ google: { resource: res, ...google } });
      })
      .catch(e => {
        console.error(e);
      });
  };

  createGaProject = async () => {
    this.setState({ gaProjectLoading: true });
    api.gaManagement
      .create()
      .then(res => {
        this.setState({
          isShownGoogleModal: true,
          createdGaProject: true,
          gaProjectLoading: false
        });
      })
      .catch(e => {
        console.log("createGaProject error");
        this.setState({
          isShownGoogleModal: true,
          createGaProject: false,
          gaProjectLoading: false
        });
      });
  };

  handleClickOptionsSwitch = async target => {
    const options = this.state.options;

    try {
      await optionActions[target].use();
      this.setState({
        options: {
          ...options,
          [target]: !options[target]
        }
      });
      this.togglePopUp();
      target === "sitest" && this.toggleSitestModal();
    } catch (e) {
      console.error(e.message);
      return false;
    }
  };

  togglePopUp = () => {
    this.setState({ showPopUp: !this.state.showPopUp });
  };

  toggleSitestModal = () => {
    this.setState({ showSitestModel: !this.state.showSitestModel });
  };

  fetchSns = async () => {
    const { authUris } = this.state;

    const socialAccountInfos = await api.sns.index().catch(e => {
      console.log(e);
    });
    const { auth_uris } = await api.sns.auth().catch(e => {
      console.log(e);
    });

    const twitter =
      socialAccountInfos.find(account => account.provider === "twitter") ||
      null;

    if (twitter) {
      twitter.profile_link = `https://twitter.com/@${twitter.name}`;
    }
    console.log(auth_uris);

    this.setState({
      sns: {
        socialAccountInfos: { twitter },
        authUris: { ...authUris, ...auth_uris }
      },
      loading: { ...this.state.loading, sns: false }
    });
  };

  updateTabelog = async () => {
    const { tabelog } = this.state;

    if (!tabelog.input) {
      return this.setState({
        tabelog: { ...tabelog, message: "URLを入力してください。" }
      });
    }

    await optionActions.tabelog
      .sync(tabelog.input)
      .then(() =>
        this.setState({
          tabelog: { ...tabelog, message: "同期が完了しました。" }
        })
      )
      .catch(() =>
        this.setState({
          tabelog: {
            ...tabelog,
            message: "同期に失敗しました。URLを確認して再度お試しください。"
          }
        })
      );
  };

  handleChangeTabelog = e => {
    this.setState({ tabelog: { input: e.target.value } });
  };

  unLinkSns = async id => {
    await api.sns
      .delete(id)
      .then(() => {
        console.log("unlink ok");
        window.location.href = isAgency()
          ? route.store(storeId()).settingSite
          : route.settingSite;
      })
      .catch(e => console.log(e));
  };

  fetchDnsRecordSet = () => {
    api.externalDnsRecordset
      .show()
      .then(resp => {
        const { externalDnsRecordset } = this.state;
        this.setState({
          externalDnsRecordset: {
            ...externalDnsRecordset,
            certificateRecordset: resp.certificate,
            publicateRecordset: resp.publicate
          }
        });
      })
      .catch(e => {
        e && e.json().then(err => console.log(err));
      });
  };

  fetchStore = async () => {
    if (!isAgency()) {
      return false;
    }
    const store = await api.stores.current().catch(e => {
      e && e.json().then(err => console.log(err));
    });
    const storeAwsState = store.aws_state;
    const resource = store.aws_resource;
    const messages = externalAwsStatusMessages(storeAwsState);
    const { externalDnsRecordset } = this.state;
    this.setState({
      externalDnsRecordset: {
        ...externalDnsRecordset,
        certificateMessage: messages.certificate,
        publicateMessage: messages.publicate,
        isExternal: resource.external_registrar
      }
    });
  };

  handleChangeFavicon = file => this.setState({ favicon: file[0] });

  updateFavicon = () => {
    api
      .faviconSetting(this.state.favicon)
      .then(res =>
        this.setState({
          faviconMessage: {
            type: "success",
            message: "ファビコンを更新しました"
          }
        })
      )
      .catch(e => {
        console.log(e);
        this.setState({
          faviconMessage: {
            type: "error",
            message: "ファビコンの更新に失敗しました"
          }
        });
      });
  };

  render() {
    return (
      <SettingSite
        {...this.state}
        updateTabelog={this.updateTabelog}
        unLinkSns={this.unLinkSns}
        handleClickOptionsSwitch={this.handleClickOptionsSwitch}
        handleChangeTabelog={this.handleChangeTabelog}
        togglePopUp={this.togglePopUp}
        toggleSitestModal={this.toggleSitestModal}
        createGaProject={this.createGaProject}
        handleChangeFavicon={this.handleChangeFavicon}
        updateFavicon={this.updateFavicon}
      />
    );
  }
}
