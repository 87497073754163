import api from "../api";
import { unixtimeToDate } from "../utils/common";

export default isArticle => ({
  fetch: async () => {
    const categories = await (isArticle
      ? api.category.index.article()
      : api.category.index.news()
    ).catch(() => {
      console.log("category fetch error");
    });

    return { categories: format(categories), category: "" };
  },
  create: async subcategory_name => {
    await api.category.create(isArticle, subcategory_name).catch(e => {
      e.json().then(err => console.log(err));
    });
  },
  update: async (subcategory_name, subcategory_value) => {
    await api.category
      .update(isArticle, subcategory_name, subcategory_value)
      .catch(e => {
        console.log("category update error");
      });
  },
  destroy: async subcategory_value => {
    if (!window.confirm("本当にこのカテゴリーを削除しますか?")) {
      return false;
    }

    await api.category.delete(isArticle, subcategory_value).catch(e => {
      e.json().then(err => console.log(err));
    });
  },
  handleChangeCategoryName: (categories, id, name) => {
    return categories.map(category =>
      category.id === id ? { ...category, name, updated: true } : category
    );
  }
});

const format = categories => {
  const formatedCategories = categories
    .sort((a, b) => {
      const pre = a.data.clean_copies.created_at;
      const cur = b.data.clean_copies.created_at;
      return pre - cur;
    })
    .map(c => {
      const { category_name, category_value, created_at } = c.data.clean_copies;
      return {
        created_at: unixtimeToDate(created_at),
        name: category_name,
        id: category_value,
        updated: false
      };
    });

  return formatedCategories;
};
