import dayjs from "dayjs";

/*
 * 現在時刻を取得する
 */
export const getNow = () => {

  const day = dayjs();

  return ({
    dateString: day.format("YYYY-MM-DD"),
    timeString: day.format("HH:mm")
  })
};

/**
 * Unixtimeを受け取り、dateStringとtimeStringを持つオブジェクトを返す
 * @param {string} unixString unixtime (e.g. "1593843836.0")
 */
export const formatUnixToCreatedAt = (unixString) => {
  const day = dayjs.unix(unixString);
  
  return ({
    dateString: day.format("YYYY-MM-DD"),
    timeString: day.format("HH:mm")
  }) 
}