import React from "react";
import { Link } from "react-router-dom";

import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Note from "../atoms/Note";
import Typography from "../atoms/Typography";
import Loading from "../atoms/Loading";
import Button from "../atoms/Button";
import FileDropArea from "../molecules/FileDropArea";

export default ({
  files,
  dropChange,
  uploadMedia,
  successMessage,
  errorMessage,
  loading,
  status,
  uploadErrorList
}) => {
  const toIndexPath = isAgency()
    ? route.store(storeId()).mediaIndex
    : route.mediaIndex;
  return (
    <div>
      {loading ? (
        <>
          <Loading />
          <Typography textAlign="center">
            画像をアップロード中です。しばらくお待ち下さい。
          </Typography>
          <Typography textAlign="center">
            {status}/{files.length}
          </Typography>
        </>
      ) : (
        <>
          <Wrap padding="20px">
            <Wrap margin="0 0 15px">
              <Link to={toIndexPath}>
                <Button>一覧へ</Button>
              </Link>
            </Wrap>
            <TitleBox title="メディア追加">
              <FileDropArea dropChange={dropChange} isMedia />
            </TitleBox>
          </Wrap>
          <Typography textAlign="center" size="20px">
            {successMessage}
          </Typography>
          <Wrap style={{ textAlign: "center" }}>
            <Note>{errorMessage}</Note>
          </Wrap>
          {uploadErrorList.length > 0 && (
            <Wrap style={{ textAlign: "center" }}>
              <Note>
                以下のファイルのアップロードに失敗しました。
                <br />
                ファイルサイズをご確認の上再度お試しください。
              </Note>
              {uploadErrorList.map((fileName, index) => (
                <Note key={index}>・{fileName}</Note>
              ))}
            </Wrap>
          )}
          {files.length > 0 && (
            <Wrap justifyContent="center" margin="20px 0 0">
              <Button onClick={uploadMedia}>アップロード</Button>
            </Wrap>
          )}
        </>
      )}
    </div>
  );
};
