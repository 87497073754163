import React from "react";
import api from "../../api";
import SettingAccount from "../templates/SettingAccount";

export default class extends React.Component {
  state = { id: "", name: "", email: "", role: "", message: { success: "" } };

  fetch = async () => {
    const account = await api.user.me().catch(() => {
      console.log("agency me fetch error");
    });
    const { id, name, email } = account;

    this.setState({ id, name, email });
  };

  update = () => {
    const { id, name, email } = this.state;
    api.staff
      .update(id, name, email)
      .then(() => {
        this.setState({
          message: { success: "アカウント情報を更新しました" }
        });
      })
      .catch(e => console.error(e));
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  componentDidMount() {
    this.fetch();
  }

  render() {
    return (
      <SettingAccount
        {...this.state}
        handleChange={this.handleChange}
        update={this.update}
      />
    );
  }
}
