import React from "react";

import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";
import InputLabel from "../molecules/InputLabel";
import Button from "../atoms/Button"
import Note from "../atoms/Note";
import Typography from "../atoms/Typography";

import color from "../../utils/color";

export default ({
  password,
  password_confirmation,
  errorMessage,
  successMessage,
  onChange,
  handleClick
}) => {
  return (
    <Wrap padding="20px">
      <Wrap width="500px" margin="auto">
        <form onSubmit={e => e.preventDefault()}>
          <TitleBox title="パスワード変更">
            <InputLabel
              name="password"
              type="password"
              label="新しいパスワード"
              value={password}
              onChange={onChange}
              errorMessage={errorMessage.password}
            />
            <InputLabel
              name="password_confirmation"
              type="password"
              label="新しいパスワード（確認）"
              value={password_confirmation}
              onChange={onChange}
              errorMessage={errorMessage.password_confirmation}
            />
            <Wrap height="20px">
              <Note>{errorMessage.message}</Note>
              <Typography color={color.black}>{successMessage}</Typography>
            </Wrap>
            <Wrap justifyContent="center">
              <Button onClick={handleClick}>更新</Button>
            </Wrap>
          </TitleBox>
        </form>
      </Wrap>
    </Wrap>
  )
}
