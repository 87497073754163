import React from "react";

import route from "../../utils/route";
import api from "../../api";

import Notification from "../templates/Notification";

export default class extends React.Component {
  state = {
    title: "",
    content: "",
    errorMessage: ""
  };

  update = () => {
    const { title, content } = this.state;
    const { id } = this.props.location.state.notification;

    if (!title) {
      return this.setState({ errorMessage: "タイトルを入力してください" });
    }
    if (!content) {
      return this.setState({ errorMessage: "本文を入力してください" });
    }

    api.notification
      .update(id, title, content)
      .then(() => {
        window.location.href = route.notificationsIndex;
      })
      .catch(e => {
        console.error(e);
      });
  };

  deleteNotification = () => {
    const { id } = this.props.location.state.notification;
    api.notification
      .delete(id)
      .then(() => {
        window.location.href = route.notificationsIndex;
      })
      .catch(e => {
        console.error(e);
      });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  UNSAFE_componentWillMount() {
    const { title, content } = this.props.location.state.notification;
    this.setState({ title, content });
  }

  render() {
    return (
      <Notification
        {...this.state}
        update={this.update}
        handleChange={this.handleChange}
        deleteNotification={this.deleteNotification}
        pageType="edit"
      />
    );
  }
}
