import React from "react";
import styled from "styled-components";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/mode/xml/xml";
import Typography from "../atoms/Typography";
import Wrap from "../atoms/Wrap";
import color from "../../utils/color";

const ScrollBox = styled.div`
  border: 1px solid ${color.grayLight};
  box-sizing: border-box;
  height: 100px;
  overflow-y: scroll;
`;

export default ({ outerHTML, setOuterHTML, active }) => {
  const options = {
    mode: "text/xml",
    lineNumbers: true,
    tabSize: 2
  };

  const activeMessage = "（こちらの変更が反映されます。）";
  return (
    <Wrap margin="20px 0">
      <Typography margin="0 0 5px" color={color.black}>
        選択中のHTMLタグ情報{active && activeMessage}
      </Typography>
      <ScrollBox>
        <CodeMirror
          value={outerHTML}
          options={options}
          onBeforeChange={(editor, data, value) => {
            if (active) {
              setOuterHTML(value);
            }
          }}
        />
      </ScrollBox>
    </Wrap>
  );
};
