import React from "react";
import styled from "styled-components";

// import route from "../../utils/route";
// import { storeId, isAgency } from "../../utils/localStorage";

// import { faPenNib, faBell, faGlobe } from "@fortawesome/free-solid-svg-icons";
import DashboardNotifications from "../molecules/DashboardNotifications";
import DashboardShortCut from "../molecules/DashboardShortCut";
// import DashboardLink from "../molecules/DashboardLink";
import GACharts from "../organisms/GACharts";
import { storeId, isAgency } from "../../utils/localStorage";
import route from "../../utils/route";
import { Link } from "react-router-dom";
import color from "../../utils/color";

const Grid = styled.div`
  display: grid;
  grid-template: 1fr 2fr / 1fr 1fr 1fr;
  grid-gap: 20px;
  min-height: calc(100% - 40px);
  margin: 20px;
`;

export default ({ newAccount, ...props }) => {
  const { notifications } = { ...props };
  // const articleNewRoute = isAgency()
  //   ? route.store(storeId()).articleNew
  //   : route.articleNew;
  let publishedData = "";
  const showPath = isAgency()
    ? route.store(storeId()).versionsShow
    : route.versionsShow;
  props.sources.map((s, i) => {
    const { data } = s;
    if (data.data.version === props.headVersion) {
      publishedData = data;
    }
  });

  return (
    <>
      <DashboardShortCut newAccount={newAccount} />
      <GACharts {...props} />
      <Grid>
        {/* <DashboardLink */}
        {/*   title="記事作成" */}
        {/*   icon={faPenNib} */}
        {/*   to={articleNewRoute} */}
        {/*   content="記事の新規作成" */}
        {/*   style={{ gridRow: "1 / 2", gridColumn: "1 / 2" }} */}
        {/* /> */}
        {/* <DashboardLink */}
        {/*   title="お知らせ作成" */}
        {/*   icon={faBell} */}
        {/*   to={articleNewRoute} */}
        {/*   content="お知らせの新規作成" */}
        {/*   style={{ gridRow: "1 / 2", gridColumn: "2 / 3" }} */}
        {/* /> */}
        {/* <DashboardLink */}
        {/*   title="サイト解析" */}
        {/*   icon={faGlobe} */}
        {/*   to={articleNewRoute} */}
        {/*   content="サイト解析" */}
        {/*   style={{ gridRow: "1 / 2", gridColumn: "3 / 4" }} */}
        {/*   external */}
        {/* /> */}
        <DashboardNotifications
          notifications={notifications}
          style={{ gridRow: "1 / 2", gridColumn: "1 / 4" }}
        />
      </Grid>
    </>
  );
};
