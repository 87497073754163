import React from "react";
import Favicon from "react-favicon";
import styled from "styled-components";

import color from "../../utils/color";

import defaultFavicon from "../../img/favicon.ico";
import defaultLogo from "../../img/logo.png";
import api from "../../api";

import Loading from "../atoms/Loading";
import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${color.grayDark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  width: 400px;
  border: 1px solid ${color.gray};
  background-color: ${color.white};
  padding: 50px 45px;

  border-radius: 3px;
  border-top: 4px solid
    ${props =>
      props.isCommon ? color.black : props.isAgency ? color.red : color.blue};
`;

export default ({ userType, children }) => {
  const isAgency = userType === "agency";
  const isCommon = userType === "common";

  const [logo, setLogo] = React.useState("");
  const [favicon, setFavicon] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const fetchAgencyData = async () => {
    const logoUrl = await api.agencies.logo.show();
    const faviconUrl = await api.agencies.favicon.show();
    setLogo(logoUrl);
    setFavicon(faviconUrl);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchAgencyData();
    return;
  }, []);

  return (
    <Background>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Favicon url={favicon || defaultFavicon} />
          <Wrap justifyContent="center">
            <img src={logo || defaultLogo} alt="サービスロゴ" height={45} />
          </Wrap>
          <Typography margin="5px 0 40px" textAlign="center">
            バージョン管理と直感的な操作でサイト制作・保守・拡張。
          </Typography>
          <Box isAgency={isAgency} isCommon={isCommon}>
            {children}
          </Box>
        </div>
      )}
    </Background>
  );
};
