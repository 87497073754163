import React from "react";

import { faClipboard } from "@fortawesome/free-solid-svg-icons";

import { isAgency } from "../../utils/localStorage";

import TitleBox from "../atoms/TitleBox";
import Note from "../atoms/Note";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import Input from "../atoms/Input";
import Label from "../atoms/Label";
import IconButton from "../atoms/IconButton";
import InputLabel from "../molecules/InputLabel";

export default props => {
  const { id, name, email, tel, accessKey, onChange, update, message } = props;
  const accessKeyRef = React.useRef();
  const [copySuccess, setCopySuccess] = React.useState("");

  const copyAccessKey = e => {
    accessKeyRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("アクセスキーをコピーしました。");
  };

  return (
    <Wrap padding="20px">
      <Wrap width="500px" margin="auto">
        <TitleBox title="店舗情報設定">
          <form onSubmit={e => e.preventDefault()}>
            <InputLabel label="店舗ID" readOnly value={id} disabled />
            <InputLabel
              name="name"
              label="店舗名"
              readOnly={isAgency()}
              disabled={isAgency()}
              value={name}
              onChange={onChange}
            />
            <InputLabel
              name="email"
              label="店舗のメールアドレス"
              readOnly={isAgency()}
              disabled={isAgency()}
              value={email}
              onChange={onChange}
            />
            <InputLabel
              name="tel"
              label="店舗の電話番号"
              readOnly={isAgency()}
              disabled={isAgency()}
              value={tel}
              onChange={onChange}
            />
            {isAgency() && (
              <>
                <Label>アクセスキー</Label>
                <Wrap alignItems="center">
                  <Input readOnly value={accessKey} ref={accessKeyRef} />
                  <IconButton
                    icon={faClipboard}
                    tip="コピーする"
                    onClick={copyAccessKey}
                  />
                </Wrap>
                <Typography size="12px">{copySuccess}</Typography>
              </>
            )}
            {!isAgency() && (
              <>
                <Typography>{message}</Typography>
                <Wrap justifyContent="center">
                  <Button onClick={update}>更新</Button>
                </Wrap>
              </>
            )}
          </form>
        </TitleBox>
        {isAgency() && (
          <Note style={{ marginTop: 10 }}>
            情報を変更することはできません。
          </Note>
        )}
      </Wrap>
    </Wrap>
  );
};
