import React from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import color from "../../utils/color";
import Label from "../atoms/Label";

const EditorWrap = styled.div`
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    border: 1px solid
      ${(props) => (props.readOnly ? color.grayLight : color.inputBorder)};
  }
`;

const toolbar =
  "undo redo | formatselect | fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | forecolor backcolor removeformat | image link | fullscreen | code ";

const plugins = ["fullscreen", "link", "table", "lists"];

export default ({ label, value, onChange, name, readOnly }) => {
  return (
    <div>
      <Label>{label}</Label>
      <EditorWrap readOnly={readOnly}>
        <Editor
          initialValue={value}
          apiKey={process.env.REACT_APP_TYNY_MCE_API_KEY}
          init={{
            height: 800,
            menubar: false,
            plugins: plugins,
            toolbar: readOnly ? "" : toolbar,
            language: "ja",
            branding: false,
          }}
          disabled={readOnly}
          onEditorChange={(e) => {
            !readOnly && onChange({ target: { name, value: e } });
          }}
        />
      </EditorWrap>
    </div>
  );
};
