import React from "react";
import { Link } from "react-router-dom";

import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";

import color from "../../utils/color";

import TitleBox from "../atoms/TitleBox";
import Loading from "../atoms/Loading";
import Wrap from "../atoms/Wrap";
import Thumbnail from "../atoms/Thumbnail";
import Button from "../atoms/Button";
import Table from "../molecules/Table";
import Paginate from "../molecules/Paginate";

const formatDate = date => new Date(date).toLocaleDateString();

const tableDatas = (media, handleClickDelete) => {
  return (
    media &&
    media.map(m => [
      m.id,
      <Thumbnail src={m.url} alt="サムネイル" />,
      formatDate(m.created_at),
      <Button
        size="small"
        onClick={() => handleClickDelete(m.id)}
        backgroundColor={color.red}>
        削除
      </Button>
    ])
  );
};

export default ({
  mediaPaginated,
  loading,
  paginate,
  handleClickPagenate,
  handleClickDelete
}) => {
  const toNewPath = isAgency()
    ? route.store(storeId()).mediaNew
    : route.mediaNew;
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Wrap padding="20px">
          <Wrap justifyContent="flex-end" margin="0 0 10px">
            <Link to={toNewPath}>
              <Button>メディア追加</Button>
            </Link>
          </Wrap>
          <TitleBox title="メディア一覧">
            <Table
              tableHeads={["ID", "サムネイル", "追加日", ""]}
              tableDatas={tableDatas(mediaPaginated, handleClickDelete)}
            />
          </TitleBox>
          <Paginate {...paginate} onClick={handleClickPagenate} />
        </Wrap>
      )}
    </div>
  );
};
