import React from "react";
import api from "../../api";
import * as optionActions from "../../utils/optionActions";

import OptionUnregisterd from "../molecules/OptionUnregistered";
import Applicants from "../templates/Applicants";

const filterKeys = [
  "address",
  "age",
  "currentJob",
  "desiredJob",
  "name",
  "recruitType",
  "status",
  "email",
  "phone"
];

const filterApplicants = (applicants, status, filterText, activeJob) => {
  const statusFilterd =
    status === "all"
      ? applicants
      : applicants.filter((a) => a.status === status);

  const jobFilterd =
    activeJob === "all"
      ? statusFilterd
      : statusFilterd.filter((a) => a.form_idef === activeJob);

  const textMatchCheck = (obj) => {
    return filterKeys.some((k) => {
      const targetText = `${obj[k]}`;
      const regexp = new RegExp(filterText, "ig");
      return targetText.match(regexp);
    });
  };

  const textFilterd =
    filterText === ""
      ? jobFilterd
      : jobFilterd.filter((a) => textMatchCheck(a));

  return textFilterd;
};

const formatApplicants = (applicants, jobOptions) => {
  return applicants.map((a) => {
    const desiredJob =
      jobOptions && jobOptions.find((o) => o.value === a.form_idef);
    return {
      ...a,
      email: a.contents["メールアドレス"] || "-",
      address: a.contents["住所"] || "-",
      age: `${a.contents["年齢"] || "-"}歳`,
      recruitType: a.contents["採用形態"] || "-",
      name: a.contents["氏名"] || "-",
      currentJob: a.contents["現在のご職業"] || "-",
      phone: a.contents["携帯電話番号"] || a.contents["電話番号"] || "-",
      desiredJob: (desiredJob && desiredJob.label) || "-"
    };
  });
};

export default class extends React.Component {
  state = {
    applicants: [],
    jobOptions: [],
    loading: true,
    status: "pending",
    activeJob: "all",
    filterText: "",
    useRecruit: false,
    hasNextApplicants: false,
    errRemarks: { contact_idef: "", errRemarks: false }
  };

  fetchApplicants = async ({ created_at, jobOptions }) => {
    const res = await api.recruit.applicants
      .index(created_at)
      .catch((e) => console.log(e));

    return res
      ? formatApplicants(res.applicants, jobOptions || this.state.jobOptions)
      : [];
  };

  fetchJobs = async () => {
    const res = await api.recruit.forms.index().catch((e) => console.log(e));
    const jobOptions = res
      ? res.forms.map((j) => ({ label: j.name, value: j.idef }))
      : [];

    return [{ label: "全て", value: "all" }, ...jobOptions];
  };

  checkHasNext = async (applicants) => {
    const lastApplicant = applicants[applicants.length - 1]
    if (lastApplicant === undefined) {
      this.setState({
        hasNextApplicants: false
      });
      return;
    }

    const lastApplicantCreatedAt = lastApplicant.created_at;
    const addedApplicants = await this.fetchApplicants({
      created_at: lastApplicantCreatedAt
    });
    if (addedApplicants.length > 0) {
      this.setState({
        hasNextApplicants: true
      });
    } else {
      this.setState({
        hasNextApplicants: false
      });
    }
  }

  fetch = async () => {
    const jobOptions = await this.fetchJobs();
    const applicants = await this.fetchApplicants({ jobOptions });

    this.setState({
      applicants,
      jobOptions,
      loading: false
    });
    this.checkHasNext(applicants);
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  changeStatus = (status) => this.setState({ status });

  updateStatus = async (contact_idef, status) => {
    this.setState({ loading: true });
    api.recruit.applicants.updateStatus(contact_idef, status).catch((e) => {
      this.setState({ loading: false });
      console.log(e);
    });

    this.fetch();
  };

  loadMore = async () => {
    this.setState({ loading: true });
    const { applicants } = this.state;
    const lastApplicantCreatedAt = applicants[applicants.length - 1].created_at;
    const addedApplicants = await this.fetchApplicants({
      created_at: lastApplicantCreatedAt
    });
    this.setState({ applicants: [...applicants, ...addedApplicants], loading: false });
    this.checkHasNext(addedApplicants);
  };

  componentDidMount() {
    this.checkUseRecruit();
    this.fetch();
  }

  checkUseRecruit = async () => {
    const useRecruit = await optionActions.recruit.check();
    this.setState({ useRecruit });
  };

  handleClickUpdateApplicant = (contact_idef, contact_remarks) => {
    api.recruit.applicants
      .updateRemarks(contact_idef, contact_remarks)
      .then((res) => {
        console.log(res);
        this.setState({
          errRemarks: { contact_idef: contact_idef, errRemarks: false }
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ contact_idef: contact_idef, errRemarks: true });
      });
  };

  render() {
    const {
      applicants,
      status,
      filterText,
      activeJob,
      useRecruit,
      loading
    } = this.state;

    return useRecruit ? (
      <Applicants
        {...this.state}
        changeStatus={this.changeStatus}
        applicants={filterApplicants(applicants, status, filterText, activeJob)}
        handleChange={this.handleChange}
        updateStatus={this.updateStatus}
        loadMore={this.loadMore}
        handleClickUpdateApplicant={this.handleClickUpdateApplicant}
      />
    ) : (
      <OptionUnregisterd optionName="求人機能" loading={loading} />
    );
  }
}
