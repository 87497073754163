import React from "react";

import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";
import FormDetail from "../templates/FormDetail";
import api from "../../api";
import {
  noInputCheck,
  validEmailCheck,
  validUrlCheck
} from "../../utils/inputCheck";
import { fetchForm } from "../../utils/formActions";

export default class extends React.Component {
  state = {
    // only edit page
    isEditPage: false,
    formId: "",
    code: "",

    // common state
    name: "",
    pub_email: "",
    redirect_to: "",
    forms: [
      {
        label: "ご用件",
        type: "radio",
        options: ["ご質問・お問い合わせ", "見積もり依頼", "求人について"]
      },
      {
        label: "会社名（法人の方のみ）",
        type: "text",
        options: [],
        required: false
      },
      {
        label: "お名前（ご担当者名）",
        type: "text",
        options: [],
        required: false
      },
      { label: "フリガナ", type: "text", options: [], required: false },
      { label: "住所", type: "text", options: [], required: false },
      { label: "電話番号", type: "text", options: [], required: false },
      { label: "メールアドレス", type: "email", options: [], required: false },
      {
        label: "お問い合わせ内容",
        type: "textarea",
        options: [],
        required: false
      }
    ],

    // new form params
    label: "",
    type: "", // text, email, selectbox, radio, textarea, date

    options: ["", "", ""], // only selectbox or radio

    // ToDo: あとで実装する可能性アリ
    // required: false,
    // defaultValue: "",
    // placeholder: "",
    // maxLength: 0,
    // minLenght: 0,
    errorMessage: {
      pub_email: "",
      redirect_to: "",
      formLabel: "",
      formOptions: ""
    }
  };

  add = closeModal => {
    const { forms, type, label, options, errorMessage } = this.state;
    let isOptionsEmpty = false;
    let filteredOptions = [];

    if (type === "selectbox" || type === "radio" || type === "checkbox") {
      filteredOptions = options.filter(option => option !== "");
      isOptionsEmpty = filteredOptions.length === 0;
    }
    if (!label || isOptionsEmpty) {
      const labelErrorMessage = "フォーム名を入力して下さい。";
      const optionsErrorMessage = "最低１つ以上のオプションを設定して下さい。";
      this.setState({
        errorMessage: {
          ...errorMessage,
          label: !label ? labelErrorMessage : "",
          options: isOptionsEmpty ? optionsErrorMessage : ""
        }
      });
      return false;
    }

    const metaData = {
      type,
      label,
      options: filteredOptions
    };
    forms.push(metaData);

    this.setState({ forms });
    closeModal();
  };

  handleChange = e => {
    const { name, value, label } = e.target;
    this.setState({ [name]: value });
    if (label) {
      this.setState({ modalTitle: label });
    }
  };

  editValue = (e, index) => {
    const { forms } = this.state;
    const { name, value } = e.target;
    forms[index][name] = value;
    this.setState({ forms });
  };

  changeOptions = e => {
    const { options } = this.state;
    const { name, value } = e.target;
    options[name] = value;
    this.setState({ options });
  };

  addOption = () => {
    const { options } = this.state;
    options.push("");
    this.setState({ options });
  };

  removeOption = index => {
    const { options } = this.state;
    options.splice(index, 1);
    this.setState({ options });
  };

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  create = () => {
    const setErrorMessage = this.setErrorMessage;
    const { name, pub_email, forms, redirect_to } = this.state;
    console.log(name, forms, pub_email, redirect_to);

    try {
      noInputCheck(pub_email, setErrorMessage, "pub_email");
      validEmailCheck(pub_email, setErrorMessage, "pub_email");
      noInputCheck(redirect_to, setErrorMessage, "redirect_to");
      validUrlCheck(redirect_to, setErrorMessage, "redirect_to");
    } catch (e) {
      return false;
    }

    api.contact.forms
      .create(name, pub_email, forms, redirect_to)
      .then(() => {
        console.log("form created");
        this.props.history.push({
          pathname: isAgency()
            ? route.store(storeId()).formIndex
            : route.formIndex,
          state: { fromDetailPage: true }
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  update = () => {
    const setErrorMessage = this.setErrorMessage;
    const { formId, name, pub_email, forms, redirect_to } = this.state;
    console.log(formId, name, pub_email, forms, redirect_to);

    try {
      noInputCheck(pub_email, setErrorMessage, "pub_email");
      validEmailCheck(pub_email, setErrorMessage, "pub_email");
      noInputCheck(redirect_to, setErrorMessage, "redirect_to");
      validUrlCheck(redirect_to, setErrorMessage, "redirect_to");
    } catch (e) {
      return false;
    }

    api.contact.forms
      .update(formId, name, pub_email, forms, redirect_to)
      .then(() => {
        console.log("form updated");
        this.props.history.push({
          pathname: isAgency()
            ? route.store(storeId()).formIndex
            : route.formIndex,
          state: { fromDetailPage: true, formId }
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  deleteForm = () => {
    const { formId } = this.state;

    if (window.confirm("本当にこのフォームを削除しますか?")) {
      api.contact.forms
        .delete(formId)
        .then(() => {
          console.log("form deleted");
          this.props.history.push(
            isAgency() ? route.store(storeId()).formIndex : route.formIndex
          );
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  swap = (x, y) => {
    const { forms } = this.state;
    forms[x] = [forms[y], (forms[y] = forms[x])][0];

    this.setState({ forms });
  };

  remove = index => {
    const { forms } = this.state;
    forms.splice(index, 1);

    this.setState({ forms });
  };

  resetNewFormParams = () => {
    const { errorMessage } = this.state;
    this.setState({
      label: "",
      type: "",
      options: ["", "", ""],
      errorMessage: { ...errorMessage, label: "", options: "" },
      modalTitle: ""
    });
  };

  componentDidMount = async () => {
    const form = await fetchForm(this.props.location.state);
    this.setState({ ...form });
  };

  handleClickCheck = index => {
    const newForms = [...this.state.forms].map((form, i) => {
      if (index === i) {
        form.required = !form.required;
      }
      return form;
    });

    this.setState({ forms: newForms });
  };

  render() {
    return (
      <FormDetail
        {...this.state}
        add={this.add}
        handleChange={this.handleChange}
        create={this.create}
        update={this.update}
        deleteForm={this.deleteForm}
        swap={this.swap}
        remove={this.remove}
        editValue={this.editValue}
        changeOptions={this.changeOptions}
        addOption={this.addOption}
        removeOption={this.removeOption}
        resetNewFormParams={this.resetNewFormParams}
        handleClickCheck={this.handleClickCheck}
      />
    );
  }
}
