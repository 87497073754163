import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

export default ({ children, newAccount }) => {
  return (
    <Button onClick={() => newAccount()}>
      <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 10 }} />
      {children}
    </Button>
  );
};

const Button = styled.button`
  border-top: 3px solid ${color.blueDark};
  padding: 1em 4.6em;
  font-weight: bold;
  font-size: 1rem;
`;
