import React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faRedo,
  faArrowUp,
  faArrowDown,
  faCopy,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

const ActionContainer = styled.div`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  width: 600px;
  height: 145px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
`;

const List = styled.li`
  cursor: pointer;
  transition: 0.3s;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: ${(props) => props.active && "#ddd"};
  padding: 0 8px;
  margin-right: 4px;
  :hover {
    background-color: ${(props) => !props.active && "#eee"};
  }
`;

const ActionHandleArea = styled.div`
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 8px;

  input {
    flex: 1;
    height: 41px;
    font-size: 16px;
  }
`;

const ActionButton = styled.button`
  height: 36px;
  line-height: 36px;
  padding: 0 8px;
  border-radius: 5px;
  border: #777 1px solid;
  font-size: 14px;
  color: #777;
  transition: 0.3s;
  cursor: pointer;
  margin-right: 4px;
  :hover {
    border-color: #333;
    color: #333;
  }
`;

const labelList = [
  "tagName",
  "id",
  "className",
  "src",
  "backgroundColor",
  "backgroundImage",
  "backgroundSize",
  "backgroundPosition",
  "background",
];

export default ({
  actionActiveNumber,
  setActionActiveNumber,
  actionBoxParams,
  setActionBoxParams,
  setParams,
  cloneElement,
  detailEdit,
  target,
  moveFocus,
  undo,
  redo,
}) => {
  const targetIsImage = target.tagName === "IMG";
  const handleChange = (e) => {
    setActionBoxParams({
      ...actionBoxParams,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <ActionContainer
      height="117px"
      top={actionBoxParams.top}
      left={actionBoxParams.left}
    >
      <div style={{ backgroundColor: color.white }} id="actionHeader">
        <div style={{ padding: 4 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <div style={{ display: "flex" }}>
              <ActionButton onClick={undo} margin="0 5px 0 0">
                <FontAwesomeIcon icon={faUndo} />
              </ActionButton>
              <ActionButton onClick={redo}>
                <FontAwesomeIcon icon={faRedo} />
              </ActionButton>
            </div>
            <div style={{ display: "flex" }}>
              <ActionButton onClick={() => moveFocus(true)}>
                <FontAwesomeIcon icon={faArrowUp} style={{ marginRight: 4 }} />
                親要素へ
              </ActionButton>
              <ActionButton onClick={() => moveFocus(false)}>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  style={{ marginRight: 4 }}
                />
                子要素へ
              </ActionButton>
              <ActionButton
                onClick={cloneElement}
                backgroundColor={color.yellow}
                style={{ marginBottom: 4 }}
              >
                <FontAwesomeIcon icon={faCopy} style={{ marginRight: 4 }} />
                要素複製
              </ActionButton>
              <ActionButton
                backgroundColor={color.grayDark}
                onClick={detailEdit}
              >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: 4 }} />
                詳細編集
              </ActionButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <ul style={{ display: "flex", overflowX: "scroll" }}>
              {labelList.map((label, i) => (
                <List
                  key={i}
                  onClick={() => setActionActiveNumber(i)}
                  active={actionActiveNumber === i}
                >
                  {label}
                </List>
              ))}
            </ul>
          </div>
        </div>
        <ActionHandleArea>
          {labelList.map((val, i) => (
            <InputRow
              key={i}
              isActive={actionActiveNumber === i}
              name={val}
              onChange={handleChange}
              value={actionBoxParams[val]}
              submit={() => setParams(val)}
            />
          ))}
        </ActionHandleArea>
      </div>
    </ActionContainer>
  );
};

const InputRow = ({ isActive, name, onChange, value, submit }) => {
  console.log({ isActive, name, onChange, value, submit });
  return (
    <div
      style={{
        width: "100%",
        display: isActive ? "flex" : "none",
        alignItems: "center",
      }}
    >
      <Input name={name} onChange={onChange} value={value} />
      <Button onClick={submit} style={{ marginLeft: 8 }}>
        値を反映
      </Button>
    </div>
  );
};
