import React from "react";
import { Helmet } from "react-helmet";
import agencyList from "./agencyList.json";

export default class extends React.Component {
  state = {
    title: ""
  };

  componentDidMount() {
    const hostname = window.location.hostname;
    if (agencyList[hostname].title) {
      const title = agencyList[hostname].title || "CCMP";
      this.setState({ title });
    }
  }

  render() {
    const { title } = this.state;
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  }
}
