import React, { useState, useCallback } from "react";
import styled from "styled-components";

import color from "../../utils/color";

import Typography from "../atoms/Typography";
import Button from "../atoms/Button";
import Wrap from "../atoms/Wrap";
import { useDropzone } from "react-dropzone";

const DashedArea = styled.div`
  border-radius: 10px;
  transition: 0.15s;
  width: 100%;
  padding: 80px 0;
  box-sizing: border-box;

  border: ${props =>
    props.isDragActive
      ? `3px solid ${color.blue}`
      : `3px dashed ${color.gray}`};
`;

export default ({ dropChange, isMedia, isFavicon }) => {
  const [fileNames, setFileNames] = useState([]);
  const onDrop = useCallback(
    acceptedFiles => {
      const fileNames = [];
      acceptedFiles.forEach(file => {
        fileNames.push(file.name);
      });
      setFileNames(fileNames);
      dropChange(acceptedFiles);
    },
    [dropChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const DropAreaMessage = isFavicon ? (
    <Typography textAlign="center" margin="0 0 20px 0">
      ファイル形式： jpg, jpeg, JPEG, png, ico
    </Typography>
  ) : isMedia ? (
    <Typography textAlign="center" margin="0 0 20px 0">
      ファイルサイズ: 10MB以下
      <br />
      ファイル形式： jpg, jpeg, JPEG, png, gif
    </Typography>
  ) : (
    <Typography textAlign="center" margin="0 0 20px 0">
      ファイル形式： zip
    </Typography>
  );

  return (
    <DashedArea isDragActive={isDragActive} {...getRootProps()}>
      <Typography textAlign="center" size="20px" margin="0 0 10px 0">
        ここにファイルをドロップ
      </Typography>
      {DropAreaMessage}
      <Wrap justifyContent="center" margin="15px 0">
        <input type="file" {...getInputProps()} style={{ display: "none" }} />
        <Button>ファイルを選択</Button>
      </Wrap>
      <Typography textAlign="center" size="20px">
        選択中のファイル
      </Typography>
      {fileNames.length > 0 ? (
        <Typography textAlign="center" size="16px">
          {fileNames.map((name, index) => (
            <p key={index}>・{name}</p>
          ))}
        </Typography>
      ) : (
        <Typography textAlign="center" size="16px">
          なし
        </Typography>
      )}
    </DashedArea>
  );
};
