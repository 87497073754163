import api from "../api";
import { storeId, isAgency } from "../utils/localStorage";
import route from "./route";
import formatPostCategories from "./formatPostCategories";
import { formatUnixToCreatedAt } from "./day";

export default isArticle => {
  const indexPath = isAgency()
    ? isArticle
      ? route.store(storeId()).articleIndex
      : route.store(storeId()).newsIndex
    : isArticle
    ? route.articleIndex
    : route.newsIndex;

  const toIndex = () => (window.location.href = indexPath);

  return {
    fetchPost: async filepath => {
      const res = await (isArticle
        ? api.article.show(filepath)
        : api.news.show(filepath)
      ).catch(e => {
        e.json().then(err => console.log(err));
      });
      console.log(res);

      const { post } = res.context;
      const { title } = res.context.post;
      const content = post.content !== null ? post.content : "";
      const slug = post.filepath.split("/")[1];
      const subcategory = post.subcategory !== null ? post.subcategory : "";
      let seo_tag = {
        doc: { title: "", description: "", keywords: "" },
        og: { title: "", description: "", image: "", site_name: "" },
        twitter: { title: "", description: "", image: "", card: "" }
      };
      const createdAt = formatUnixToCreatedAt(post.created_at);

      seo_tag =
        post.metadata && post.metadata.seo_tags
          ? post.metadata.seo_tags
          : seo_tag;
      const thumbnail_url =
        post.metadata.thumbnail_url !== null ? post.metadata.thumbnail_url : "";
      return [
        { title, content, slug, subcategory, filepath, thumbnail_url },
        seo_tag,
        createdAt
      ];
    },

    fetchCategories: async () => {
      const categories = await (isArticle
        ? api.category.index.article()
        : api.category.index.news()
      ).catch(e => console.log(e));
      const formatedCategories = formatPostCategories(categories);
      formatedCategories.unshift({ label: "なし", value: "" });

      return formatedCategories;
    },

    fetchMedias: async loadedPage => {
      const res = await api.media.index(loadedPage).catch(e => {
        e.json().then(err => console.log(err));
      });

      return res ? res.images : [];
    },

    fetchSeoPreferences: async () => {
      const seo_tag = await api.seoPreference.index().catch(e => {
        e.json().then(err => console.log(err));
      });

      return seo_tag;
    },

    fetchTemplate: async () => {
      const res = await api.template.show().catch(e => {
        e.json().then(err => console.log(err));
      });
      const template =  isArticle ? res.article_template : res.news_template;
      return template.content;
    },

    create: async (post, sns, seo_tag, unixCreatedAt) => {
      await (isArticle
        ? api.article.create({ article: { ...post, sns, created_at: unixCreatedAt }, seo_tag })
        : api.news.create({ news: { ...post, sns, created_at: unixCreatedAt }, seo_tag })
      )
        .then(() => {
          console.log("post create");
          toIndex();
        })
        .catch(e => {
          throw e;
        });
    },

    update: async (post, seo_tag, unixCreatedAt) => {
      await (isArticle
        ? api.article.update({ article: { ...post, created_at: unixCreatedAt }, seo_tag })
        : api.news.update({ news: { ...post, created_at: unixCreatedAt }, seo_tag })
      )
        .then(() => {
          console.log("update ok");
          toIndex();
        })
        .catch(e => {
          throw e;
        });
    },

    delete: filepath => {
      if (window.confirm("本当にこの記事を削除しますか?")) {
        (isArticle ? api.article.delete(filepath) : api.news.delete(filepath))
          .then(() => {
            toIndex();
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  };
};
