import React from "react";
import { Link } from "react-router-dom";
import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";

import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";
import Button from "../atoms/Button";
import Loading from "../atoms/Loading";
import FormGalleries from "../molecules/FormGalleries";
import DomCopyModal from "../organisms/DomCopyModal";

const editPath = isAgency()
  ? route.store(storeId()).jobOfferEdit
  : route.jobOfferEdit;

const newPath = isAgency()
  ? route.store(storeId()).jobOfferNew
  : route.jobOfferNew;

export default ({ forms, loading, dom }) => {
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    dom && setModal(true);
  }, [dom]);

  const closeModal = () => setModal(false);

  return loading ? (
    <Loading label="求人票一覧を取得中" />
  ) : (
    <Wrap padding="20px">
      <DomCopyModal show={modal} close={closeModal} dom={dom} />
      <Wrap justifyContent="flex-end" margin="0 0 15px">
        <Link to={newPath}>
          <Button>新規求人票作成</Button>
        </Link>
      </Wrap>
      <TitleBox title="求人票一覧">
        <FormGalleries forms={forms} editPath={editPath} />
      </TitleBox>
    </Wrap>
  );
};
