import React from "react";
import { Link } from "react-router-dom";

import route from "../../utils/route";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Table from "../molecules/Table";
import Paginate from "../molecules/Paginate";

const SearchForm = ({ search, handleChange, handleSearch }) => {
  return (
    <Wrap justifyContent="flex-end" alignItems="center" padding="20px">
      <Input
        style={{ width: "25%", marginRight: "10px" }}
        placeholder="店舗名"
        value={search}
        onChange={handleChange}
      />
      <Button onClick={handleSearch}>検索</Button>
    </Wrap>
  );
};

export default ({
  accountType,
  paginate,
  handleClickPagenate,
  search,
  handleChange,
  handleSearch,
  ...props
}) => {
  const isAgency = accountType === "agency";
  const accountName = isAgency ? "代理店" : "店舗";

  return (
    <div>
      <Wrap justifyContent="flex-end" margin="0 0 10px">
        <Link to={isAgency ? route.agencyNew : route.storeNew}>
          <Button>新規{accountName}登録</Button>
        </Link>
      </Wrap>
      <Wrap justifyContent="center">
        <TitleBox title={`${accountName}一覧`}>
          <SearchForm
            search={search}
            handleChange={handleChange}
            handleSearch={handleSearch}
          />
          <Table {...props} />
          <Paginate {...paginate} onClick={handleClickPagenate} />
        </TitleBox>
      </Wrap>
    </div>
  );
};
