import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

const SwitchContainer = styled.label`
  width: 80px;
  height: 24px;
  display: inline-block;
  z-index: 2;
`;

const Switch = styled.span`
  position: relative;
  display: block;
  transition: 0.15s ease-out;
  height: inherit;
  cursor: pointer;
  background-color: ${props => (props.checked ? color.blue : color.white)};
  border: 1px solid ${props => (props.checked ? color.blueDark : color.gray)};
  border-radius: 0.25rem;
  z-index: 1;

  :after {
    position: absolute;
    top: 50%;
    left: 22px;
    width: 50%;
    margin-top: -0.5em;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    color: ${color.gray};
    text-align: left;
    text-transform: uppercase;
    z-index: 1;

    color: ${props => (props.checked ? color.white : color.gray)};
    content: ${props => (props.checked ? `"ソース"` : `"テキスト"`)};
    transition: inherit;
  }

  :before {
    position: absolute;
    top: 2px;
    left: 2px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    content: "";
    border: 1px solid ${color.gray};
    border-radius: 0.125rem;
    z-index: 2;
    background-color: ${color.white};

    transform: ${props => props.checked && "translateX(55px)"};
    transition: 0.15s ease-out;
  }
`;

export default ({ onClick, checked }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p style={{ fontSize: "14px", color: color.blueDark }}>編集モード</p>
      <SwitchContainer onClick={onClick}>
        <Switch checked={checked} />
      </SwitchContainer>
    </div>
  );
};
