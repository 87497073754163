import React from "react";

import api from "../../api";
import route from "../../utils/route";
import {
  noInputCheck,
  validEmailCheck,
  passwordCheck
} from "../../utils/inputCheck";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Loading from "../atoms/Loading";
import Button from "../atoms/Button";
import InputLabel from "../molecules/InputLabel";
import SelectBoxLabel from "../molecules/SelectBoxLabel";

const options = [
  { label: "admin", value: "admin" },
  { label: "normal", value: "normal" }
];

export default class extends React.Component {
  state = {
    loading: false,
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    role: "",

    errorMessage: {
      name: "",
      email: "",
      password: "",
      password_confirmation: ""
    }
  };

  componentDidMount() {
    this.setState({ role: options[0].value });
  }

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  createStaff = () => {
    const setErrorMessage = this.setErrorMessage;
    const { name, email, password, password_confirmation, role } = this.state;

    try {
      noInputCheck(name, setErrorMessage, "name");
      noInputCheck(email, setErrorMessage, "email");
      validEmailCheck(email, setErrorMessage, "email");
      noInputCheck(password, setErrorMessage, "password");
      passwordCheck(password, password_confirmation, setErrorMessage);
    } catch (e) {
      return false;
    }

    this.setState({ loading: true });
    api.staff
      .create({ name, email, password, role })
      .then(() => {
        window.location.href = route.agencyStaffIndex;
      })
      .catch(e => {
        this.setState({ loading: false });
        e.json().then(err => {
          this.setState({
            errorMessage: {
              email:
                err.error.email &&
                "このメールアドレスはすでに使用されています。"
            }
          });
        });
      });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const {
      name,
      email,
      password,
      password_confirmation,
      role,
      errorMessage,
      loading
    } = this.state;

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <Wrap padding="20px" width="500px" margin="auto">
            <TitleBox title="スタッフ作成">
              <form onSubmit={e => e.preventDefault()}>
                <InputLabel
                  name="name"
                  label="名前"
                  value={name}
                  onChange={this.handleChange}
                  errorMessage={errorMessage.name}
                  required
                />
                <InputLabel
                  name="email"
                  label="メールアドレス"
                  value={email}
                  onChange={this.handleChange}
                  errorMessage={errorMessage.email}
                  required
                />
                <InputLabel
                  name="password"
                  type="password"
                  label="パスワード"
                  value={password}
                  onChange={this.handleChange}
                  errorMessage={errorMessage.password}
                  required
                />
                <InputLabel
                  name="password_confirmation"
                  type="password"
                  label="パスワード（確認）"
                  value={password_confirmation}
                  onChange={this.handleChange}
                  errorMessage={errorMessage.password_confirmation}
                  required
                />
                <SelectBoxLabel
                  name="role"
                  options={options}
                  label="権限"
                  value={role}
                  onChange={this.handleChange}
                />
                <Wrap justifyContent="center" margin="20px 0 0">
                  <Button onClick={this.createStaff}>作成</Button>
                </Wrap>
              </form>
            </TitleBox>
          </Wrap>
        )}
      </div>
    );
  }
}
