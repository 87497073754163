import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

const Container = styled.div`
  border: 1px solid ${color.gray};

  width: ${props => props.width || "100%"};
  margin: ${props => props.margin};
`;

const Heading = styled.h1`
  font-size: 13px;
  font-weight: normal;
  font-weight: bold;
`;

const HeadingBox = styled.div`
  padding: 10px 20px;
  background-color: ${color.grayLight};
  border-bottom: 1px solid ${color.gray};
`;

const Box = styled.div`
  width: 100%;
  background-color: ${color.white};
  box-sizing: border-box;
  height: calc(100% - 41px);

  padding: ${props => props.padding || "20px 40px 50px"};
  position: ${props => props.comingSoon && "relative"};
`;

export default ({ children, title, padding, comingSoon, ...props }) => {
  return (
    <Container {...props}>
      <HeadingBox>
        <Heading>{title}</Heading>
      </HeadingBox>
      <Box comingSoon={comingSoon} padding={padding}>
        {children}
      </Box>
    </Container>
  );
};
