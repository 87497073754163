import React from "react";

import { isAgency } from "../../utils/localStorage";

import Button from "../atoms/Button";
import Wrap from "../atoms/Wrap";
import Typography from "../atoms/Typography";
import InputLabel from "../molecules/InputLabel";

export default props => {
  const { updateTabelog, tabelog, handleChangeTabelog, options } = props;

  return (
    <Wrap
      justifyContent="center"
      alignItems="center"
      style={{ flexDirection: "column" }}
    >
      {options.tabelog ? (
        <>
          <InputLabel
            label="食べログ店舗 > 店舗トップ のURL"
            width="300px"
            name="tabelog"
            value={tabelog.input}
            onChange={handleChangeTabelog}
            placeholder="https://tabelog.com/.../.../..."
          />
          <Wrap margin="0 0 1rem 0">
            <Button onClick={updateTabelog}>同期する</Button>
          </Wrap>
          <Typography>{tabelog.message}</Typography>
        </>
      ) : (
        <Typography>
          {isAgency()
            ? "オプション設定タブから食べログ同期機能を有効化してください。"
            : "代理店に問い合わせて食べログ連携機能を有効にしてください。"}
        </Typography>
      )}
    </Wrap>
  );
};
