import React from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import "../../css/codemirror.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/css/css";
import "codemirror/mode/javascript/javascript";

import TitleBox from "../atoms/TitleBox";
import Loading from "../atoms/Loading";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import { isAgency } from "../../utils/localStorage";
import Input from "../atoms/Input";

const fileTypeObj = {
  html: "text/xml",
  css: "text/css",
  js: "text/javascript"
};

export default ({
  isLoading,
  onChangeEditor,
  handleTitleChange,
  fileName,
  source,
  submit,
  title,
  useComment = true,
}) => {
  const fileType = () => {
    const type = fileName.split(".");
    const typeKey = type[type.length - 1].toLowerCase();

    return fileTypeObj[typeKey] === undefined
      ? fileTypeObj["html"]
      : fileTypeObj[typeKey];
  };

  const options = {
    mode: fileType(),
    lineNumbers: true,
    tabSize: 2
  };

  return (
    <div>
      <Wrap justifyContent="flex-end" margin="0 0 15px">
        { useComment ? 
         <Wrap margin="auto 10px auto auto" width="100%">
          <Input
            name="title"
            placeholder="コメント（編集の概要や修正内容など）"
            onChange={handleTitleChange}
            value={title}
          />
        </Wrap> : null
        }
        <Button onClick={submit}>保存</Button>
      </Wrap>
      <form onSubmit={(e) => e.preventDefault()}>
        <TitleBox title={fileName} padding="0">
          {isLoading ? (
            <Loading label="ソースファイルを取得中..." />
          ) : (
            <CodeMirror
              value={source}
              options={options}
              onBeforeChange={(editor, data, value) => onChangeEditor(value)}
            />
          )}
        </TitleBox>
      </form>
    </div>
  );
};
