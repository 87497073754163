import React from "react";

import api from "../../api";
import { noInputCheck, passwordCheck } from "../../utils/inputCheck";

import PasswordUpdate from "../templates/PasswordUpdate";

export default class extends React.Component {
  state = {
    password: "",
    password_confirmation: "",
    errorMessage: {
      password: "",
      password_confirmation: "",
      message: ""
    },
    successMessage: ""
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  handleClick = () => {
    const setErrorMessage = this.setErrorMessage;
    const { password, password_confirmation } = this.state;

    this.setState({
      errorMessage: {
        password: "",
        password_confirmation: "",
        message: ""
      },
      successMessage: ""
    });

    try {
      noInputCheck(password, setErrorMessage, "password");
      passwordCheck(password, password_confirmation, setErrorMessage);
    } catch (e) {
      return false;
    }

    api.user.password
      .update(password, password_confirmation)
      .then(res => {
        const successMessage = "パスワードを更新しました";
        this.setState({ successMessage });
        this.setState({ password: "", password_confirmation: "" });
      })
      .catch(err => {
        const message = "更新に失敗しました";
        this.setState({ errorMessage: { message } });
        console.log(err);
      });
  };

  render() {
    console.log(this.state.errorMessage);
    return (
      <PasswordUpdate
        {...this.state}
        onChange={this.onChange}
        handleClick={this.handleClick}
      />
    );
  }
}
