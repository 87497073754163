import React from "react";

import api from "../../api";
import ExternalDomainForm from "../templates/ExternalDomainForm";
import { domainRegistered } from "../../utils/awsState";

export default class extends React.Component {
  state = {
    domain: "",
    hp_domain: "",
    registrar_type: "muumuu",
    messages: {},
    status: null,
    haveRegistered: false
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  fetchMe = async () => {
    const store = await api.stores.current().catch(e => {
      e && e.json().then(err => console.log(err));
    });

    const awsState = store.aws_state;
    const { domain } = store.aws_resource;
    const hp_domain = store.aws_resource.hp_domain;
    this.setState({
      domain: domain ? domain.name : "",
      hp_domain,
      haveRegistered: domainRegistered(awsState)
    });
  };

  handleDomainChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  registerDomain = async () => {
    const body = { domain: this.state };
    await api.externalDomain
      .create(body)
      .then(() => {
        this.setState({ messages: {}, status: "ok" });
      })
      .catch(e => {
        e.json().then(err => {
          console.log(err);
          this.setState({ messages: err.errors, status: "failed" });
        });
      });
  };

  componentDidMount = async () => {
    await this.fetchMe();
  };

  render() {
    const registrarTypes = [
      { label: "ムームードメイン", value: "muumuu" },
      { label: "お名前.com", value: "onamae" },
      { label: "さくらDNS", value: "sakura" },
      { label: "スタードメイン", value: "star" },
    ];
    return (
      <ExternalDomainForm
        {...this.state}
        registrarTypes={registrarTypes}
        handleChange={this.handleChange}
        handleDomainChange={this.handleDomainChange}
        registerDomainAction={this.registerDomain}
      />
    );
  }
}
