import React from "react";

import route from "../../utils/route";
import { storeId, isAgency } from "../../utils/localStorage";
import {
  noInputCheck,
  validEmailCheck,
  validUrlCheck,
  validMessageCheck
} from "../../utils/inputCheck";
import { fetchForm } from "../../utils/formActions";
import api from "../../api";
import * as optionActions from "../../utils/optionActions";

import OptionUnregisterd from "../molecules/OptionUnregistered";
import JobOfferDetail from "../templates/JobOfferDetail";

export default class extends React.Component {
  state = {
    // only edit page
    isEditPage: false,
    formId: "",

    // common state
    name: "",
    pub_email: "",
    redirect_to: "",
    reply_type: "email",
    reply_subject: "",
    reply_message: "",
    forms: [
      { label: "氏名", type: "text", placeholder: "山田太郎", options: [] },
      { label: "年齢", type: "number", placeholder: "22", options: [] },
      {
        label: "現在のご職業",
        type: "text",
        placeholder: "学生、営業、経理、エンジニアなど",
        options: []
      },
      {
        label: "メールアドレス",
        type: "email",
        placeholder: "sample@mail.com",
        options: []
      },
      {
        label: "携帯電話番号",
        type: "tel",
        placeholder: "090-1234-5678",
        options: []
      },
      {
        label: "住所",
        type: "text",
        placeholder: "福岡県福岡市博多区xx-x",
        options: []
      },
      {
        label: "採用形態",
        type: "selectbox",
        options: ["正社員（新卒）", "正社員（中途）", "アルバイト・パート"]
      }
    ],
    jobOptions: [],

    errorMessage: { pub_email: "", redirect_to: "", reply_message: "" },
    useRecruit: false,
    loading: true
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleMessageChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 500) {
      this.setState({ [name]: value });
    }
  };

  setErrorMessage = (errorText, target) => {
    this.setState({
      errorMessage: {
        [target]: errorText
      }
    });
  };

  create = () => {
    const setErrorMessage = this.setErrorMessage;
    const {
      name,
      pub_email,
      redirect_to,
      forms,
      reply_type,
      reply_subject,
      reply_message
    } = this.state;

    try {
      noInputCheck(pub_email, setErrorMessage, "pub_email");
      validEmailCheck(pub_email, setErrorMessage, "pub_email");
      noInputCheck(redirect_to, setErrorMessage, "redirect_to");
      validUrlCheck(redirect_to, setErrorMessage, "redirect_to");
      validMessageCheck(reply_message, setErrorMessage, "reply_message");
    } catch (e) {
      return false;
    }

    const contactType = "recruit";

    api.recruit.forms
      .create(
        name,
        pub_email,
        forms,
        redirect_to,
        contactType,
        reply_type,
        reply_subject,
        reply_message
      )
      .then(() => {
        console.log("form created");
        this.props.history.push({
          pathname: isAgency()
            ? route.store(storeId()).jobOfferIndex
            : route.jobOfferIndex,
          state: { fromDetailPage: true }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  update = () => {
    const setErrorMessage = this.setErrorMessage;
    const {
      formId,
      name,
      pub_email,
      forms,
      reply_type,
      redirect_to,
      reply_subject,
      reply_message
    } = this.state;

    try {
      noInputCheck(pub_email, setErrorMessage, "pub_email");
      validEmailCheck(pub_email, setErrorMessage, "pub_email");
      noInputCheck(redirect_to, setErrorMessage, "redirect_to");
      validUrlCheck(redirect_to, setErrorMessage, "redirect_to");
      validMessageCheck(reply_message, setErrorMessage, "reply_message");
    } catch (e) {
      return false;
    }

    api.recruit.forms
      .update(
        formId,
        name,
        pub_email,
        forms,
        redirect_to,
        reply_type,
        reply_subject,
        reply_message
      )
      .then(() => {
        console.log("form updated");
        this.props.history.push({
          pathname: isAgency()
            ? route.store(storeId()).jobOfferIndex
            : route.jobOfferIndex,
          state: { fromDetailPage: true, formId }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  deleteForm = () => {
    const { formId } = this.state;

    if (window.confirm("本当にこのフォームを削除しますか?")) {
      api.recruit.forms
        .delete(formId)
        .then(() => {
          console.log("form deleted");
          this.props.history.push(
            isAgency()
              ? route.store(storeId()).jobOfferIndex
              : route.jobOfferIndex
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  componentDidMount = async () => {
    this.checkUseRecruit();
    const form = await fetchForm(this.props.location.state, true);
    this.setState({ ...form });
  };

  checkUseRecruit = async () => {
    const useRecruit = await optionActions.recruit.check();
    this.setState({ useRecruit, loading: false });
  };

  render() {
    const { useRecruit, loading } = this.state;
    return useRecruit ? (
      <JobOfferDetail
        {...this.state}
        handleChange={this.handleChange}
        handleMessageChange={this.handleMessageChange}
        create={this.create}
        update={this.update}
        deleteForm={this.deleteForm}
      />
    ) : (
      <OptionUnregisterd optionName="求人機能" loading={loading} />
    );
  }
}