import React from "react";
import { Route } from "react-router-dom";
import route from "../utils/route";
import { storeId } from "../utils/localStorage";
import agencyList from "./agencyList.json";
import {
  recruitComponentPath,
  sitestComponentPath,
  // contactComponentPath  // WARN: 使っていないためコメントアウト
} from "./optionsComponentList";

// classにしてrender内でpathが引っかかったらcheckValidxxxを叩く、アウトならリダイレクト処理いいならそのままレンダリング
export default ({ component: Component, path, tokenType }) => {
  const isAgency = tokenType === "agencyToken";

  const indexPath = isAgency
    ? route.store(storeId()).versionsIndex
    : route.versionsIndex;

  const checkValidRecruit = () => {
    const hostname = window.location.hostname;
    if (!agencyList[hostname].options.recruit) {
      window.location.href = indexPath;
    }
  };

  const checkValidSitest = () => {
    const hostname = window.location.hostname;
    if (!agencyList[hostname].options.sitest) {
      window.location.href = indexPath;
    }
  };

  // WARN: 使っていないためコメントアウト
  // const checkValidContact = () => {
  //   const hostname = window.location.hostname;
  //   if (!agencyList[hostname].options.contact) {
  //     window.location.href = indexPath;
  //   }
  // };

  return (
    <Route
      render={props => {
        recruitComponentPath(isAgency).some(
          recruitPath => recruitPath === path
        ) && checkValidRecruit();
        sitestComponentPath(isAgency).some(sitestPath => sitestPath === path) &&
          checkValidSitest();
        // TODO: お問い合わせをオプション化するか後ほど検討
        // contactComponentPath(isAgency).some(
        //   contactPath => contactPath === path
        // ) && checkValidContact();

        return <Component {...props} />;
      }}
    />
  );
};
