import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  width: 200px;
  height: 30px;
  position: relative;
`;

const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 30px;
  border-radius: 15px;

  width: 100%;
  background-color: ${color.white};
  color: ${color.blueGray};
  padding: 0.375rem 0.75rem 0.375rem 35px;
  font-size: 0.75rem;
  line-height: 1.5;
  box-sizing: border-box;
  ::placeholder {
    color: ${color.gray};
  }

  border: 1px solid
    ${props =>
      props.errorMessage === "" || !props.errorMessage
        ? color.inputBorder
        : props.errorMessage === "ok"
        ? color.green
        : color.red};
  border-color: ${props => props.readOnly && color.grayLight};
  :focus {
    outline: ${props => props.readOnly && "none"};
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

export default ({ ...props }) => {
  return (
    <Container>
      <Input {...props} placeholder="応募者の氏名など" />
      <SearchIcon icon={faSearch} color={color.gray} />
    </Container>
  );
};
