import React from "react";
import styled from "styled-components";
import color from "../../utils/color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = styled.button`
  cursor: pointer;
  border: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: 0.15s;
  color: ${color.white};

  padding: ${(props) =>
    props.size === "tiny"
      ? "4px 7px"
      : props.size === "small"
      ? "6px 12px"
      : "10px 20px"};
  font-size: ${(props) =>
    props.size === "tiny" ? "10px" : props.size === "small" ? "12px" : "13px"};

  background-color: ${(props) => props.backgroundColor || color.blue};

  :hover {
    opacity: 0.8;
  }
`;

export default ({ children, icon, ...props }) => {
  return (
    <Button {...props}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          style={{ marginRight: 10 }}
          color={color.white}
        />
      )}
      {children}
    </Button>
  );
};
