import React from "react";

import styled from "styled-components";

import { Chart } from "react-google-charts";
import Wrap from "../atoms/Wrap";
import TitleBox from "../atoms/TitleBox";
import Typography from "../atoms/Typography";

const BluredWrap = styled(Wrap)`
  -ms-filter: blur(6px);
  filter: blur(6px);
`;

const sessionPerDayDataFormatter = (data) => {
  let dates = [];
  for(let offset = 0; offset <= 30; offset++) {
    const date = new Date();
    date.setDate(date.getDate() - offset);

    const year = date.getFullYear();
    const month = (date.getMonth()+1) < 10 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    const dateLabel = `${year}${month}${day}`;
    const value = Object.keys(data).includes(dateLabel) ? data[dateLabel] : 0;
    dates.unshift([dateLabel, value]);
  }

  dates.unshift(["日付", "セッション"]);

  return dates;
};

const deviceDataFormatter = (data) => {
  const dataArray = Object.entries(data);
  dataArray.unshift(["デバイス", "セッション"]);
  return dataArray;
};

const regionDataFormatter = (data) => {
  const dataArray = Object.entries(data);
  dataArray.unshift(["都道府県", "セッション"]);
  return dataArray;
};

// example
// gaData = {
//   "ga:pageTitle": ...,
//   "ga:date": ...,
//   "ga:deviceCategory": ...,
//   "ga:region": ...,
// }


export default ({
  gaData,
}) => {
  const deviceDataMock = {
    desktop: 10,
    android: 2,
    ios: 12,
  };

  const regionDataMock = {
    fukuoka: 10,
    tokyo: 130,
    aichi: 103,
    kyoto: 111,
    yamaguchi: 130,
    osaka: 19,
    nagano: 10,
  }

  const dateDataMock = {
    "20191101": 4,
    "20191103": 45,
    "20191113": 130,
    "20191121": 100,
    "20191130": 111,
  }

  const isAuthorized = Object.keys(gaData).length > 0
  
  const gaDate = isAuthorized ? gaData["ga:date"] : dateDataMock
  const gaDeviceCategory = isAuthorized ? gaData["ga:deviceCategory"] : deviceDataMock
  const gaRegion = isAuthorized ? gaData["ga:region"] : regionDataMock


  const ChartArea = ({children, ...props}) => (
    isAuthorized ? (
      <Wrap {...props}>
        {children}
      </Wrap>
    ) : (
      <BluredWrap {...props}>
        {children}
      </BluredWrap>
    )
  )

  return (
    <Wrap padding="20px">
      <TitleBox title="チャート">
        <Typography>※チャートを利用するにはGoogle認証とGoogleAnalyticsで設定を行う必要があります.</Typography>
        <ChartArea justifyContent="center" margin="20px auto">
          <Chart
            width="80%"
            height="300px"
            chartType="PieChart"
            loader={<div>Loading session per device</div>}
            data={deviceDataFormatter(gaDeviceCategory)}
            options={{
              title: "デバイス別セッション数"
            }}
          />
          <Chart
            width="80%"
            height="300px"
            chartType="Bar"
            loading={<div>Loading session per region</div>}
            data={regionDataFormatter(gaRegion)}
            options={{
              chart: {
                title: "地域別セッション数"
              }
            }}
          />
        </ChartArea>
        <ChartArea justifyContent="center">
          <Chart
            width="100%"
            height="300px"
            chartType="Line"
            data={sessionPerDayDataFormatter(gaDate)}
            options={{
              chart: {
                title: "過去30日のセッション数"
              }
            }}
          />
        </ChartArea>
      </TitleBox>
    </Wrap>
  );
}