import React from "react";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import color from "../../utils/color";
import Typography from "../atoms/Typography";
import GAInfos from "./GAInfos";
import Modal from "../molecules/Modal";
import Loading from "../atoms/Loading";

const textColor = success => {
  return success ? color.green : color.red
}

export default ({
  google,
  createGaProject,
  isShownGoogleModal,
  createdGaProject,
  gaProjectLoading,
}) => {
  // updated at はunixtimeでくる
  const {authUri, updatedAt} = google
  
  // millsec なので *1000する
  const datetime = new Date(updatedAt * 1000);
  const lastUpdatedMessage = updatedAt ?
    `最終認証日: ${datetime.toLocaleDateString()} ${datetime.toLocaleTimeString()}` : "認証していません"
  
  const handleOnClick = () => {
    window.location.href = authUri
  }

  const buttonTitle = updatedAt ? "再認証" : "認証"

  const modalTitle = createdGaProject 
    ? "GAプロジェクトを作成しました．"
    : "GAプロジェクトの作成に失敗しました．"

  const nextHintsWithSuccess = [
    "データを収集したいページにトラッキングコードを追加する必要があります.",
    "サイト管理 > 各種設定 > Google認証 のページからトラッキングコードを確認いただけます．"
  ]

  const nextHintsWithFail = [
    "GoogleAnalyticsアカウントを作成する必要があります．(https://analytics.google.com/analytics)",
    "ドメインを取得する必要があります．"
  ]

  const hintArea = () => {
    const hints = createdGaProject ? nextHintsWithSuccess : nextHintsWithFail
    return hints.map((hint, idx) => {
      return (
        <Typography key={`create-ga-modal-hint-${idx.toString()}`}>
          {hint}
        </Typography>
      )
    })
  }
  
  const onClickModalButton = () => {
    window.location.reload()
  }

  const createResultModal = () => {
    return (
      <Modal show={isShownGoogleModal}>
        <Typography
          margin="0 0 20px 0"
          color={textColor(createdGaProject)}
          size="18px"
        >
          {modalTitle}
        </Typography>
        <Wrap margin="10px auto">
          { hintArea() }
        </Wrap>
        <Wrap justifyContent="center">
          <Button onClick={onClickModalButton}>OK</Button>
        </Wrap>
      </Modal>
    )
  }
  
  
return (
    <>
    <Wrap justifyContent="space-between" margin="10px auto">
      { createResultModal() }
      <Wrap alignItems="center">
        <FontAwesomeIcon
          icon={faGoogle}
          color={color.googleBlue}
          style={{ marginRight: 10 }}
          size="3x"
        />
        <Typography color={color.blueGray} size="30px">Google</Typography>
      </Wrap>
      <Wrap alignItems="center">
        <Wrap margin="auto 10px">
          <Typography>{lastUpdatedMessage}</Typography>
        </Wrap>
        <Button onClick={handleOnClick}>{buttonTitle}</Button>
      </Wrap>
    </Wrap>
    { updatedAt && (
      <>
        { gaProjectLoading ? (<Loading/>) : null }
        <GAInfos
          gaResource={google.resource}
          createGaProject={createGaProject}
        />
      </>
    )}
    </>
  )
}