import React from "react";
import { Link } from "react-router-dom";

import { storeId, isAgency } from "../../utils/localStorage";
import route from "../../utils/route";
import color from "../../utils/color";
import { unixtimeToDate } from "../../utils/common";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import Loading from "../atoms/Loading";
import Thumbnail from "../atoms/Thumbnail";
import Modal from "../molecules/Modal";
import Table from "../molecules/Table";

const tableDatas = (posts, showPath) => {
  return (
    posts &&
    posts.map(a => [
      <Thumbnail src={a.metadata && a.metadata.thumbnail_url} />,
      a.title,
      a.subcategory || "-",
      unixtimeToDate(a.created_at),
      <Link
        to={{
          pathname: showPath,
          state: { post: a }
        }}
      >
        <Button>編集</Button>
      </Link>
    ])
  );
};

const applyTemplateState = {
  beforeRequest: 0,
  requesting: 1,
  finished: 2
}

const ApplyTemplateModalContent = ({
  state,
  setState,
  closeModalHandler,
  applyTemplateToAllPost,
  message,
  setMessage,
  funcName
}) => {
  if(state === applyTemplateState.beforeRequest) {
    const apply = async () => {
      setState(applyTemplateState.requesting)
      const message = await applyTemplateToAllPost()
      console.log(message)
      setMessage(message)
      setState(applyTemplateState.finished)
    }
    return (
      <>
        <Typography color={color.black}>
          全ての{funcName}にテンプレートを適用します。（元には戻せません）
        </Typography>
        <Wrap justifyContent="space-around" margin="29px 0 0">
          <Button onClick={closeModalHandler}>キャンセル</Button>
          <Button onClick={apply}>適用</Button>
        </Wrap>
      </>
    )
  } else if(state === applyTemplateState.requesting) {
    return (
      <>
        <Wrap justifyContent="center" margin="29px 0 0">
          <Loading/>
        </Wrap>
        <Wrap justifyContent="center" margin="29px 0 0">
          <Typography>適用中...</Typography>
        </Wrap>
      </>
    )
  } else if(state === applyTemplateState.finished) {
    const handleClickButton = () => {
      setState(applyTemplateState.beforeRequest)
      closeModalHandler()
    }
    return (
      <>
        <Wrap justifyContent="center" margin="29px 0 0">
          <Typography>{message}</Typography>
        </Wrap>
        <Wrap justifyContent="center" margin="29px 0 0">
          <Button onClick={handleClickButton}>OK</Button>
        </Wrap>
      </>
    )
  }
}

const ApplyTemplateWarningModal = ({
  funcName,
  applyTemplateToAllPost,
  closeModalHandler,
  ...props
}) => {
  const [applicateState, setApplicateState] = React.useState(applyTemplateState.beforeRequest);
  const [message, setMessage] = React.useState("")

  return (
    <Modal {...props}>
      <ApplyTemplateModalContent
        state={applicateState}
        setState={setApplicateState}
        message={message}
        setMessage={setMessage}
        funcName={funcName}
        applyTemplateToAllPost={applyTemplateToAllPost}
        closeModalHandler={closeModalHandler}
      />
    </Modal>
  );
}

const NoTemplateModal = ({ funcName, templatePath, ...props }) => {
  return (
    <Modal {...props}>
      <Typography color={color.black}>
        {funcName}機能を使用するにはテーマを設定する必要があります。
      </Typography>
      <Wrap justifyContent="center" margin="30px 0 0">
        <Link to={templatePath}>
          <Button>テーマ設定ページへ</Button>
        </Link>
      </Wrap>
    </Modal>
  );
};

export default ({
  isArticle,
  posts,
  templateExist,
  loading,
  applyTemplateToAllPost
}) => {
  // template適用するときのモーダル表示のフラグ
  const [showApplyTemplateModal, setShowApplyTemplateModal] = React.useState(false);

  const funcName = isArticle ? "記事" : "お知らせ";
  const linkCreate = () => {
    if (isArticle) {
      return isAgency()
        ? {
            new: route.store(storeId()).articleNew,
            show: route.store(storeId()).articleEdit,
            index: route.store(storeId()).articleIndex,
            template: route.store(storeId()).articleTemplate
          }
        : {
            new: route.articleNew,
            show: route.articleEdit,
            index: route.articleIndex,
            template: route.articleTemplate
          };
    } else {
      return isAgency()
        ? {
            new: route.store(storeId()).newsNew,
            show: route.store(storeId()).newsEdit,
            index: route.store(storeId()).newsIndex,
            template: route.store(storeId()).newsTemplate
          }
        : {
            new: route.newsNew,
            show: route.newsEdit,
            index: route.newsIndex,
            template: route.newsTemplate
          };
    }
  };

  const path = linkCreate();

  return (
    <Wrap padding="20px">
      {loading ? (
        <Loading />
      ) : (
        <>
          <ApplyTemplateWarningModal
            funcName={funcName}
            title="テンプレートの適用"
            show={showApplyTemplateModal}
            closeModalHandler={ () => setShowApplyTemplateModal(false) }
            applyTemplateToAllPost={applyTemplateToAllPost}
          />
          <NoTemplateModal
            funcName={funcName}
            templatePath={path.template}
            title="テーマを設定してください"
            show={!templateExist}
          />
          <Wrap justifyContent="space-between" margin="0 0 15px">
            <Button 
              onClick={ () => setShowApplyTemplateModal(true) }
              backgroundColor={color.red}>テンプレートの一括適用</Button>
            <Link to={path.new}>
              <Button>新規投稿</Button>
            </Link>
          </Wrap>
          <TitleBox title={`${funcName}一覧`}>
            <Table
              colWidths={["60px", "", "", "", "100px"]}
              tableHeads={[
                "サムネイル",
                "タイトル",
                "カテゴリー",
                "投稿日",
                ""
              ]}
              tableDatas={tableDatas(posts, path.show, path.index)}
            />
          </TitleBox>
        </>
      )}
    </Wrap>
  );
};
