import React from "react";
import { Link } from "react-router-dom";

import { storeId, isAgency } from "../../utils/localStorage";
import route from "../../utils/route";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Loading from "../atoms/Loading";
import Typography from "../atoms/Typography";
import FileDropArea from "../molecules/FileDropArea";
import Input from "../atoms/Input";
import ErrorMessage from "../atoms/ErrorMessage";

export default ({ zipFile, dropChange, handleChange, handleTitleChange, uploadFile, loading, errorMessage }) => {
  const indexPath = isAgency()
    ? route.store(storeId()).versionsIndex
    : route.versionsIndex;

  const errorMessageInfo = () => {
    if (errorMessage) {
      return <ErrorMessage errorMessage={errorMessage} />
    }
  }

  return (
    <Wrap padding="20px">
      <Wrap margin="0 0 10px">
        <Link to={indexPath}>
          <Button>戻る</Button>
        </Link>
      </Wrap>
      <TitleBox title="バージョン追加">
        <Typography margin="0 0 15px">
          ソースファイルを圧縮したzipファイルをアップロードしてください。
          <br />
          アップロードしたzipファイルは新規バージョンとしてバージョン一覧に追加されます。
        </Typography>
        {loading ? (
          <Loading label="ファイルをアップロード中..." />
        ) : (
          <>
            { errorMessageInfo() }
            <FileDropArea dropChange={dropChange} handleChange={handleChange} />
          </>
        )}
      </TitleBox>
      {zipFile && (
        <Wrap justifyContent="center" margin="20px 0 0">
          <Wrap margin="auto 10px auto auto" width="100%">
            <Input
              name="title"
              placeholder="コメント（変更の概要や修正内容など）"
              onChange={handleTitleChange}
            />
          </Wrap>
          <Button onClick={uploadFile}>アップロード</Button>
        </Wrap>
      )}
    </Wrap>
  );
};
