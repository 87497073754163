import endpoint from "./endpoint";
import { storeId, isAgency } from "./utils/localStorage";

export default {
  contact: {
    index: async (createdAt) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).contact.index(createdAt),
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.contact.index(createdAt),
            methods.get,
            true
          );
      return request(req);
    },
    forms: {
      index: async () => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).contact.forms,
              methods.get,
              true
            )
          : createRequest.store(
              endpoint.store.contact.forms,
              methods.get,
              true
            );
        return request(req);
      },
      show: async (formId) => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).contact.form(formId),
              methods.get,
              true
            )
          : createRequest.store(
              endpoint.store.contact.form(formId),
              methods.get,
              true
            );
        return request(req);
      },
      create: async (name, pub_email, form, redirect_to, contact_type) => {
        const body = {
          contact_form: {
            name: name || "名称未設定",
            pub_email,
            form,
            redirect_to,
            contact_type: contact_type || "common"
          }
        };
        console.log(body);
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).contact.forms,
              methods.post,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.contact.forms,
              methods.post,
              true,
              body
            );
        return request(req);
      },
      update: async (formId, name, pub_email, form, redirect_to) => {
        const body = {
          contact_form: {
            formId,
            name: name || "名称未設定",
            pub_email,
            form,
            redirect_to
          }
        };
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).contact.form(formId),
              methods.put,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.contact.form(formId),
              methods.put,
              true,
              body
            );
        return request(req);
      },
      delete: async (formId) => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).contact.form(formId),
              methods.delete,
              true
            )
          : createRequest.store(
              endpoint.store.contact.form(formId),
              methods.delete,
              true
            );
        return request(req);
      }
    }
  },

  recruit: {
    applicants: {
      index: async (created_at) => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.applicants(created_at),
              methods.get,
              true
            )
          : createRequest.store(
              endpoint.store.recruit.applicants(created_at),
              methods.get,
              true
            );
        return request(req);
      },
      updateStatus: async (contact_idef, contact_status) => {
        const body = {
          applicant: {
            contact_idef,
            contact_status
          }
        };
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.applicants(),
              methods.put,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.recruit.applicants(),
              methods.put,
              true,
              body
            );
        return request(req);
      },
      updateRemarks: async (contact_idef, contact_remarks) => {
        const body = {
          applicant: {
            contact_idef,
            contact_remarks
          }
        };
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.applicants(),
              methods.put,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.recruit.applicants(),
              methods.put,
              true,
              body
            );
        return request(req);
      },
      update: async (formId, name, pub_email, form, redirect_to) => {
        const body = {
          contact_form: {
            formId,
            name: name || "名称未設定",
            pub_email,
            form,
            redirect_to
          }
        };
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.applicants,
              methods.put,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.recruit.applicants,
              methods.put,
              true,
              body
            );
        return request(req);
      }
    },
    use: {
      check: async () => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.use,
              methods.get,
              true
            )
          : createRequest.store(endpoint.store.recruit.use, methods.get, true);
        return request(req);
      },
      post: async () => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.use,
              methods.post,
              true,
              {}
            )
          : createRequest.store(endpoint.store.recruit.use, methods.get, true);
        return request(req);
      }
    },
    forms: {
      index: async () => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.forms,
              methods.get,
              true
            )
          : createRequest.store(
              endpoint.store.recruit.forms,
              methods.get,
              true
            );
        return request(req);
      },
      show: async (formId) => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.form(formId),
              methods.get,
              true
            )
          : createRequest.store(
              endpoint.store.recruit.form(formId),
              methods.get,
              true
            );
        return request(req);
      },
      create: async (
        name,
        pub_email,
        form,
        redirect_to,
        contact_type,
        reply_type,
        reply_subject,
        reply_message
      ) => {
        const body = {
          contact_form: {
            name: name || "名称未設定",
            pub_email,
            form,
            redirect_to,
            contact_type: contact_type || "common",
            reply_type,
            reply_subject,
            reply_message
          }
        };
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.forms,
              methods.post,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.recruit.forms,
              methods.post,
              true,
              body
            );
        return request(req);
      },
      update: async (
        formId,
        name,
        pub_email,
        form,
        redirect_to,
        reply_type,
        reply_subject,
        reply_message
      ) => {
        const body = {
          contact_form: {
            formId,
            name: name || "名称未設定",
            pub_email,
            form,
            redirect_to,
            reply_type,
            reply_subject,
            reply_message
          }
        };
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.form(formId),
              methods.put,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.recruit.form(formId),
              methods.put,
              true,
              body
            );
        return request(req);
      },
      delete: async (formId) => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).recruit.form(formId),
              methods.delete,
              true
            )
          : createRequest.store(
              endpoint.store.recruit.form(formId),
              methods.delete,
              true
            );
        return request(req);
      }
    }
  },

  user: {
    signIn: async (userType, email, password) => {
      const body = { auth: { email, password } };
      const isAgency = userType === "agency";
      const req = isAgency
        ? createRequest.agency(
            endpoint.agency().signIn,
            methods.post,
            true,
            body
          )
        : createRequest.store(endpoint.store.signIn, methods.post, true, body);
      return request(req);
    },

    password: {
      resetRequest: async (email) => {
        const body = { email };
        const req = createRequest.common(
          endpoint.common.password.resetRequest,
          methods.post,
          false,
          body
        );
        return request(req);
      },
      reset: async (token, password, password_confirmation) => {
        const body = { token, password: { password, password_confirmation } };
        const req = createRequest.common(
          endpoint.common.password.reset,
          methods.post,
          false,
          body
        );
        return request(req);
      },
      update: async (password, password_confirmation) => {
        const body = { password: { password, password_confirmation } };
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency().password.update,
              methods.put,
              true,
              body
            )
          : createRequest.store(
              endpoint.store.password.update,
              methods.put,
              true,
              body
            );
        return request(req);
      }
    },

    me: async () => {
      const req = isAgency()
        ? createRequest.agency(endpoint.agency().agency.me, methods.get, true)
        : createRequest.store(endpoint.store.me, methods.get, true);
      return request(req);
    }
  },

  stores: {
    index: async (page, search) => {
      const req = createRequest.agency(
        endpoint.agency().store.index(page, search),
        methods.get,
        true
      );
      return request(req);
    },
    current: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).store.show,
            methods.get,
            true
          )
        : createRequest.store(endpoint.store.store.root, methods.get, true);
      return request(req);
    },
    create: async (body) => {
      const req = createRequest.agency(
        endpoint.agency().store.root,
        methods.post,
        true,
        body
      );
      return request(req);
    },
    update: async (name, email, tel) => {
      const body = { store: { name, email, tel } };
      const req = createRequest.store(
        endpoint.store.store.root,
        methods.put,
        true,
        body
      );
      return request(req);
    }
  },

  agencies: {
    index: async (page) => {
      const req = createRequest.agency(
        endpoint.agency().agency.index(page),
        methods.get,
        true
      );
      return request(req);
    },
    create: async (body) => {
      const req = createRequest.agency(
        endpoint.agency().agency.root,
        methods.post,
        true,
        body
      );
      return request(req);
    },
    logo: {
      show: async () => {
        const { hostname } = window.location;
        const url = `${getSubBaseUrl(subDomains.logo)}/${hostname}/logo.png`;
        const res = await fetch(url);
        return res.status === 404 ? null : url;
      },
      create: async () => {}
    },
    favicon: {
      show: async () => {
        const { hostname } = window.location;
        const url = `${getSubBaseUrl(subDomains.logo)}/${hostname}/favicon.ico`;
        const res = await fetch(url);
        return res.status === 404 ? null : url;
      },
      create: async () => {}
    },
    accessKey: async () => {
      const req = createRequest.agency(
        endpoint.agency(storeId()).accessKey.root,
        methods.get,
        true
      );
      return request(req);
    }
  },
  faviconSetting: async (file) => {
    const formData = new FormData();
    formData.append("favicon[file]", file);
    const req = createRequest.agency(
      endpoint.agency(storeId()).favicon.create,
      methods.post,
      true,
      formData,
      true
    );
    return request(req);
  },

  versions: {
    index: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).versions.root,
            methods.get,
            true
          )
        : createRequest.store(endpoint.store.versions.root, methods.get, true);
      return request(req);
    },
    create: async (file, title) => {
      const formData = new FormData();
      // api側でformdataを作成
      formData.append("zip[file]", file);
      formData.append("zip[title]", title);
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).versions.root,
            methods.post,
            true,
            formData,
            true
          )
        : createRequest.store(
            endpoint.store.versions.root,
            methods.post,
            true,
            formData,
            true
          );
      return request(req);
    },
    rollback: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).versions.rollbacks,
            methods.post,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.versions.rollbacks,
            methods.post,
            true,
            body
          );
      return request(req);
    },
    archive: async (versionHash) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).versions.archive(versionHash),
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.versions.archive(versionHash),
            methods.get,
            true
          );
      return request(req);
    },
    update: async (versionHash, title) => {
      const body = { version: { title } };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).versions.update(versionHash),
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.versions.update(versionHash),
            methods.put,
            true,
            body
          );
      return request(req);
    }
  },

  source: {
    show: async (object_hash) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).source.show(object_hash),
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.source.show(object_hash),
            methods.get,
            true
          );
      return request(req);
    },
    update: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).source.update,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.source.update,
            methods.put,
            true,
            body
          );
      return request(req);
    }
  },

  staff: {
    index: async () => {
      const req = createRequest.agency(
        endpoint.agency().staff.root,
        methods.get,
        true
      );
      return request(req);
    },
    create: async (body) => {
      const req = createRequest.agency(
        endpoint.agency().staff.root,
        methods.post,
        true,
        {
          ...body
        }
      );
      return request(req);
    },
    update: async (staffId, name, email, role) => {
      const body = { name, email, role };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency().staff.update(staffId),
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.staff.update(staffId),
            methods.put,
            true,
            body
          );
      return request(req);
    }
  },

  template: {
    show: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).template,
            methods.get,
            true
          )
        : createRequest.store(endpoint.store.template.root, methods.get, true);
      return request(req);
    },
    update: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).template,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.template.root,
            methods.put,
            true,
            body
          );
      return request(req);
    },
    create: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).template,
            methods.post,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.template.root,
            methods.post,
            true,
            body
          );
      return request(req);
    }
  },

  category: {
    index: {
      article: async () => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).category.article,
              methods.get,
              true
            )
          : createRequest.store(
              endpoint.store.category.article,
              methods.get,
              true
            );
        return request(req);
      },
      news: async () => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).category.news,
              methods.get,
              true
            )
          : createRequest.store(
              endpoint.store.category.news,
              methods.get,
              true
            );
        return request(req);
      }
    },
    create: async (isArticle, subcategory_name) => {
      const body = {
        subcategory: {
          category: isArticle ? "articles" : "news",
          subcategory_name
        }
      };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).category.root,
            methods.post,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.category.root,
            methods.post,
            true,
            body
          );
      return request(req);
    },
    update: async (isArticle, subcategory_name, subcategory_value) => {
      const body = {
        subcategory: {
          category: isArticle ? "articles" : "news",
          subcategory_name,
          subcategory_value
        }
      };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).category.root,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.category.root,
            methods.put,
            true,
            body
          );
      return request(req);
    },
    delete: async (isArticle, subcategory_value) => {
      const body = {
        subcategory: {
          category: isArticle ? "articles" : "news",
          subcategory_value
        }
      };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).category.root,
            methods.delete,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.category.root,
            methods.delete,
            true,
            body
          );
      return request(req);
    }
  },

  article: {
    index: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).article.root,
            methods.get,
            true
          )
        : createRequest.store(endpoint.store.article.root, methods.get, true);
      return request(req);
    },
    show: async (filepath) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).article.edit(filepath),
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.article.edit(filepath),
            methods.get,
            true
          );
      return request(req);
    },
    create: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).article.root,
            methods.post,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.article.root,
            methods.post,
            true,
            body
          );
      return request(req);
    },
    update: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).article.root,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.article.root,
            methods.put,
            true,
            body
          );
      return request(req);
    },
    delete: async (filepath) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).article.delete(filepath),
            methods.delete,
            true
          )
        : createRequest.store(
            endpoint.store.article.delete(filepath),
            methods.delete,
            true
          );
      return request(req);
    }
  },

  news: {
    index: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).news.root,
            methods.get,
            true
          )
        : createRequest.store(endpoint.store.news.root, methods.get, true);
      return request(req);
    },
    show: async (filepath) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).news.edit(filepath),
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.news.edit(filepath),
            methods.get,
            true
          );
      return request(req);
    },
    create: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).news.root,
            methods.post,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.news.root,
            methods.post,
            true,
            body
          );
      return request(req);
    },
    update: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).news.root,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.news.root,
            methods.put,
            true,
            body
          );
      return request(req);
    },
    delete: async (filepath) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).news.delete(filepath),
            methods.delete,
            true
          )
        : createRequest.store(
            endpoint.store.news.delete(filepath),
            methods.delete,
            true
          );
      return request(req);
    }
  },

  // ↑done

  media: {
    index: async (page) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).media.index(page),
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.media.index(page),
            methods.get,
            true
          );
      return request(req);
    },
    create: async (files) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).media.root,
            methods.post,
            true,
            files,
            true
          )
        : createRequest.store(
            endpoint.store.media.root,
            methods.post,
            true,
            files,
            true
          );
      return request(req);
    },
    delete: async (id) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).media.delete(id),
            methods.delete,
            true
          )
        : createRequest.store(
            endpoint.store.media.delete(id),
            methods.delete,
            true
          );
      return request(req);
    }
  },

  sitest: {
    account: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).sitest_account.root,
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.sitest_account.root,
            methods.get,
            true
          );
      return request(req);
    },
    create: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).sitest_account.root,
            methods.post,
            true
          )
        : createRequest.store(
            endpoint.store.sitest_account.root,
            methods.post,
            true
          );
      return request(req);
    },
    update: async () => {
      const req = createRequest.agency(
        endpoint.agency(storeId()).sitest_account.update,
        methods.put,
        true
      );
      return request(req);
    }
  },

  domain: {
    // store's aws resource
    // check if domain is available
    availabilityCheck: async (domain) => {
      const body = { domain: { domain } };
      const req = createRequest.agency(
        endpoint.agency().domain.availability,
        methods.post,
        true,
        body
      );
      return request(req);
    },
    // contact domain
    contact: async (body) => {
      const req = createRequest.agency(
        endpoint.agency(storeId()).domain.contact,
        methods.post,
        true,
        body
      );
      return request(req);
    }
  },

  notification: {
    index: async () => {
      const req = createRequest.agency(
        endpoint.agency().notifications.index,
        methods.get,
        true
      );
      return request(req);
    },
    dashboardIndex: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).notifications.dashboardIndex,
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.notifications.dashboardIndex,
            methods.get,
            true
          );
      return request(req);
    },
    create: async (title, content) => {
      const body = { notification: { title, content } };
      const req = createRequest.agency(
        endpoint.agency().notifications.index,
        methods.post,
        true,
        body
      );
      return request(req);
    },
    update: async (id, title, content) => {
      const body = { notification: { title, content } };
      const req = createRequest.agency(
        endpoint.agency().notifications.show(id),
        methods.put,
        true,
        body
      );
      return request(req);
    },
    delete: async (id) => {
      const req = createRequest.agency(
        endpoint.agency().notifications.show(id),
        methods.delete,
        true
      );
      return request(req);
    }
  },

  sns: {
    auth: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).sns.auth,
            methods.get,
            true
          )
        : createRequest.store(endpoint.store.sns.auth, methods.get, true);
      return request(req);
    },
    index: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).sns.root,
            methods.get,
            true
          )
        : createRequest.store(endpoint.store.sns.root, methods.get, true);
      return request(req);
    },
    delete: async (id) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).sns.delete(id),
            methods.delete,
            true
          )
        : createRequest.store(
            endpoint.store.sns.delete(id),
            methods.delete,
            true
          );
      console.log(req);
      return request(req);
    }
  },

  seoPreference: {
    index: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).seoPreference.root,
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.seoPreference.root,
            methods.get,
            true
          );
      return request(req);
    },
    create: async (body) => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).seoPreference.root,
            methods.post,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.seoPreference.root,
            methods.post,
            true,
            body
          );
      return request(req);
    }
  },

  externalDomain: {
    create: async (body) => {
      console.log(endpoint.agency(storeId()).externalDomain);
      const req = createRequest.agency(
        endpoint.agency(storeId()).externalDomain.root,
        methods.post,
        true,
        body
      );
      return request(req);
    }
  },

  externalDnsRecordset: {
    show: async () => {
      const req = createRequest.agency(
        endpoint.agency(storeId()).externalDnsRecordset.root,
        methods.get,
        true
      );
      return request(req);
    }
  },

  tabelog: {
    use: {
      check: async () => {
        const req = isAgency()
          ? createRequest.agency(
              endpoint.agency(storeId()).tabelog.use,
              methods.get,
              true
            )
          : createRequest.store(endpoint.store.tabelog.use, methods.get, true);
        return request(req);
      },
      post: async () => {
        const req = createRequest.agency(
          endpoint.agency(storeId()).tabelog.use,
          methods.post,
          true
        );
        return request(req);
      }
    },
    update: async (url) => {
      const body = { tabelog: { url } };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).tabelog.update,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.tabelog.update,
            methods.put,
            true,
            body
          );
      return request(req);
    }
  },
  googleOauthUri: {
    show: async () => {
      const req = createRequest.agency(
        endpoint.agency(storeId()).googleOauthUri.show,
        methods.get,
        true
      );
      return request(req);
    }
  },
  sitemap: {
    create: async (home_change_freq, post_change_freq) => {
      const body = {
        sitemap: {
          home_change_freq,
          post_change_freq
        }
      };
      const req = createRequest.agency(
        endpoint.agency(storeId()).sitemap.create,
        methods.post,
        true,
        body
      );
      return request(req);
    },
    submit: async () => {
      const req = createRequest.agency(
        endpoint.agency(storeId()).sitemap.submit,
        methods.put,
        true
      );
      return request(req);
    }
  },
  googleAnalytics: {
    show: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).googleAnalytics.show,
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.googleAnalytics.show,
            methods.get,
            true
          );
      return request(req);
    }
  },
  gaManagement: {
    show: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).gaManagement.root,
            methods.get,
            true
          )
        : createRequest.store(
            endpoint.store.gaManagement.root,
            methods.get,
            true
          );
      return request(req);
    },
    create: async () => {
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).gaManagement.root,
            methods.post,
            true
          )
        : createRequest.store(
            endpoint.store.gaManagement.root,
            methods.post,
            true
          );
      return request(req);
    }
  },
  applyArticleTemplate: {
    apply: async () => {
      const body = { apply_template: { category: "article" } };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).applyTemplate.update,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.applyTemplate.update,
            methods.put,
            true,
            body
          );
      return request(req);
    }
  },
  applyNewsTemplate: {
    apply: async () => {
      const body = { apply_template: { category: "news" } };
      const req = isAgency()
        ? createRequest.agency(
            endpoint.agency(storeId()).applyTemplate.update,
            methods.put,
            true,
            body
          )
        : createRequest.store(
            endpoint.store.applyTemplate.update,
            methods.put,
            true,
            body
          );
      return request(req);
    }
  }
};

const methods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE"
};

const distributeRequest = (
  userType,
  path,
  method,
  isNeedAuth = false,
  bodyObj = null,
  formData = null
) => {
  if (method === methods.get) {
    return {
      path,
      req: { method, headers: createHeaders(userType, isNeedAuth, formData) }
    };
  }

  return {
    path,
    req: {
      method,
      headers: createHeaders(userType, isNeedAuth, formData),
      body: formData ? bodyObj : JSON.stringify(bodyObj)
    }
  };
};

const createRequest = {
  common: (path, method, isNeedAuth, bodyObj = null, formData = null) =>
    distributeRequest(null, path, method, isNeedAuth, bodyObj, formData),
  agency: (path, method, isNeedAuth = false, bodyObj = null, formData = null) =>
    distributeRequest("agency", path, method, isNeedAuth, bodyObj, formData),
  store: (path, method, isNeedAuth = false, bodyObj = null, formData = null) =>
    distributeRequest("store", path, method, isNeedAuth, bodyObj, formData)
};

const createHeaders = (userType, jwt = false, formData = null) => {
  const headers = new Headers();
  !formData && headers.append("Content-Type", "application/json");
  if (jwt) {
    const token = localStorage.getItem(`${userType}Token`);
    if (token !== null) {
      headers.append("Authorization", `Bearer ${token}`);
    }
  }
  return headers;
};

/*
 * NOTE:
 * You can specify `API_BASE_URL` explicitly on your local with:
 *
 * `REACT_APP_API_BASE_URL=http://localhost:9999 yarn start`
 *
 * Or just you can make `.env.local`
 * ```
 * REACT_APP_API_BASE_URL=http://localhost:9999
 * ```
 */

const subDomains = {
  logo: "logo"
};

const getSubBaseUrl = (sub) => {
  const stagingFront = "staging.ccmphp.com";

  const staging = () => {
    return subDomains.logo ? "https://logo.staging.ccmphp.com" : "";
  };
  const production = () => {
    return subDomains.logo ? "https://logo.ccmphp.com" : "";
  };

  return process.env.NODE_ENV === "development" ||
    window.location.host === stagingFront
    ? staging()
    : production();
};

const getBaseUrl = () => {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_API_BASE_URL
  )
    return process.env.REACT_APP_API_BASE_URL;

  // const local = "http://localhost:3000";
  const stagingFront = "staging.ccmphp.com";
  const staging = "https://staging.api.ravenhp.com";
  const production = "https://api.ravenhp.com";

  return process.env.NODE_ENV === "development" ||
    window.location.host === stagingFront
    ? staging
    : production;
};

const request = (req) => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}${req.path}`;

  return fetch(url, req.req)
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      if (res.headers.get("Content-Type") === "application/zip") {
        return res.blob();
      }

      return res.json();
    })
    .catch((e) => {
      if (e.statusCode === 401) {
        console.log("unauthorized error");
      }
      throw e;
    });
};