import React from "react";

import api from "../../api";
import DomainCheck from "../templates/DomainCheck";

export default class extends React.Component {
  state = {
    domain: "",
    hp_domain: "",
    domainAvailable: "",
    domainPreview: "",
    loading: false,
    disabled: false
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  domainCheck = () => {
    const { domain } = this.state;
    this.setState({ loading: true });

    api.domain
      .availabilityCheck(this.state.domain)
      .then(res => {
        this.setState({
          domainAvailable: res.available,
          domainPreview: domain,
          loading: false
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        console.log("domain check error");
      });
  };

  componentDidMount = async () => {
    const res = await api.stores.current();
    const { domain, hp_domain } = res.aws_resource;
    domain && this.setState({ domain: domain.name, disabled: true, hp_domain });
  };

  render() {
    return (
      <DomainCheck
        {...this.state}
        handleChange={this.handleChange}
        domainCheck={this.domainCheck}
      />
    );
  }
}
