import React from "react";

import TitleBox from "../atoms/TitleBox";
import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import IconTrash from "../atoms/IconTrash";
import { Notice } from "../atoms/Notice";
import InputLabel from "../molecules/InputLabel";
import Table from "../molecules/Table";

const tableDatas = ({
  categories,
  handleChangeCategoryName,
  update,
  destroy
}) =>
  categories.map((c, index) => {
    const updateCategory = () => update(c.name, c.id);
    return [
      index + 1,
      c.created_at,
      <Input
        value={c.name}
        onChange={e => handleChangeCategoryName(c.id, e.target.value)}
        onKeyDown={e => e.keyCode === 13 && updateCategory()}
      />,
      c.updated ? <Button onClick={updateCategory}>更新</Button> : "",
      c.id,
      <IconTrash onClick={() => destroy(c.id)} />
    ];
  });

export default ({
  create,
  update,
  destroy,
  categories,
  category,
  message,
  handleChange,
  handleChangeCategoryName,
  isArticle
}) => {
  return (
    <Wrap padding="20px">
      <Wrap width="80%" margin="auto">
        {message.success && (
          <Notice color="green" margin="0 0 10px">
            {message.success}
          </Notice>
        )}
        <TitleBox title={`${isArticle ? "記事" : "お知らせ"}カテゴリ一覧`}>
          <Wrap
            justifyContent="flex-end"
            alignItems="flex-end"
            margin="0 0 30px"
          >
            <InputLabel
              name="category"
              label="カテゴリ名"
              value={category}
              onChange={handleChange}
              margin="0 10px 0 0"
            />
            <Button onClick={create}>カテゴリ追加</Button>
          </Wrap>
          <Table
            srollY
            colWidths={["60px", "", "", "100px", "70px"]}
            tableHeads={["", "作成日", "カテゴリー名", "", "カテゴリー値", "操作"]}
            tableDatas={tableDatas({
              update,
              destroy,
              categories,
              handleChangeCategoryName,
              isArticle
            })}
          />
        </TitleBox>
      </Wrap>
    </Wrap>
  );
};
