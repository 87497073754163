import React from "react";

const TimeInput = ({ value, handleChange }) => (
  <input
    type="time"
    value={value}
    placeholder="18:05"
    onChange={(e) => handleChange(e.target.value)}
  ></input>
);

export default TimeInput;
