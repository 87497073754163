import React from "react";

import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import SelectBoxLabel from "../molecules/SelectBoxLabel";
import Modal from "../molecules/Modal";
import Typography from "../atoms/Typography";
import Loading from "../atoms/Loading";
import color from "../../utils/color";
import TitleBox from "../atoms/TitleBox";

const textColor = success => {
  return success ? color.green : color.red
}

export default ({
  home_change_freq,
  post_change_freq,
  handleChange,
  options,
  upload,
  uploadLoading,
  isShownUploadModal,
  closeModal,
  sitemapUri,
  uploaded,
}) => {
  const uploadResultModal = () => {
    
    const uploadMessageText = uploaded
      ? `サイトマップをアップロードしました. ${sitemapUri}にて確認いただけます．`
      : `サイトマップのアップロードに失敗しました．運営会社にお問い合わせください`

    const nextHint = (
      <Wrap margin="20px 0 0 0">
        <Typography>アップロード後はGoogleSearchConsoleにサイトマップを提出する必要があります．</Typography>
      </Wrap>
    )

    const uploadMessage = (
      <Typography
        margin="0 0 20px 0"
        size="18px"
        color={textColor(uploaded)}
      >
        {uploadMessageText}
       </Typography>
    )

    return (
      <Modal show={isShownUploadModal}>
          {uploadMessage}
          {nextHint}
          <Wrap justifyContent="center" margin="20px 0 0 0">
            <Button onClick={closeModal}>OK</Button>
          </Wrap>
      </Modal>
    )
  }


  return (
    <TitleBox title="サイトマップの作成">
      <Wrap>
        { uploadLoading ? (<Loading/>): null }
        { uploadResultModal() }
        <SelectBoxLabel
          label="ホームページの更新頻度"
          name="home_change_freq"
          value={home_change_freq}
          options={options}
          onChange={handleChange}
        />
        <SelectBoxLabel
          label="投稿の更新頻度"
          name="post_change_freq"
          value={post_change_freq}
          options={options}
          onChange={handleChange}
        />
        <Wrap justifyContent="center" margin="20px 0 0">
          <Button onClick={upload}>サイトマップを作成</Button>
        </Wrap>
      </Wrap>
    </TitleBox>
  )
}
