import React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import NoImage from "../../img/no-image.png";

const Img = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  border: 1px solid ${color.grayLight};
`;

export default ({ src, ...props }) => {
  return (
    <Img
      src={src || NoImage}
      alt="サムネイル"
      {...props}
      onError={e => (e.target.src = NoImage)}
    />
  );
};
