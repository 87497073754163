import React from "react";

import Table from "../molecules/Table";

export default ({
  certificateMessage,
  publicateMessage,
  certificateRecordset,
  publicateRecordset
}) => {
  const recordSetTable = () => {
    const cert = certificateRecordset;
    const pub = publicateRecordset;
    const tableData = [
      [cert.name, cert.value, cert.type, certificateMessage],
      [pub.name, pub.value, pub.type, publicateMessage]
    ];
    return (
      <Table
        tableHeads={["NAME", "VALUE", "TYPE", "備考"]}
        tableDatas={tableData}
      />
    );
  };

  return <div>{recordSetTable()}</div>;
};
