import React, { useState, useEffect } from "react";
import api from "../../api";
import { isAgency } from "../../utils/localStorage";
import {
  fetchDom,
  replaceText,
  editAnchor,
  replaceOuterHTML,
  replaceImage,
  removeDOM,
  undo,
  redo,
} from "../../utils/wysiwygActions";
import Typography from "../atoms/Typography";
import WysiwygIframe from "../atoms/WysiwygIframe";
import WysiwygReplaceImageModal from "../molecules/WysiwygReplaceImageModal";
import WysiwygActionModal from "../molecules/WysiwygActionModal";
import WysiwygActionButtonRow from "../molecules/WysiwygActionButtonRow";
import WysiwygActionBox from "../molecules/WysiwygActionBox";

export default ({ source, submit, handleTitleChange, title }) => {
  const [html, setHtml] = useState("");
  const [baseTag, setBaseTag] = useState("");
  const [medias, setMedias] = useState([]);
  const [imagesModal, showImagesModal] = useState(false);
  const [actionModal, showActionModal] = useState(false);
  const [src, setSrc] = useState("");
  const [href, setHref] = useState("");
  const [target, setTarget] = useState("");
  const [text, setText] = useState("");
  const [outerHTML, setOuterHTML] = useState("");
  const [actionActiveNumber, setActionActiveNumber] = useState(0);
  const [actionBox, setActionBox] = useState(false);
  const [actionBoxParams, setActionBoxParams] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    tagName: "",
    className: "",
    id: "",
    background: "",
    backgroundColor: "",
    backgroundImage: "",
    backgroundSize: "",
    backgroundPosition: "",
    src: "",
  });
  const [previousChild, setPreviousChild] = useState();

  useEffect(() => {
    const fetchHpDomain = async () => {
      const store = await (isAgency()
        ? api.stores.current()
        : api.user.me()
      ).catch((e) => {
        e.json().then((err) => console.log(err));
      });

      const { hp_domain } = store.aws_resource;
      return hp_domain;
    };

    fetchHpDomain().then((hpDomain) => {
      const url = `https://${hpDomain}`;
      const splited = source.split("<head>");
      const base = `\n<base href="${url}">`;
      splited.splice(1, 0, `<head>${base}`);
      const joinedHTML = splited.join("");
      setHtml(joinedHTML);
      setBaseTag(base);
    });

    api.media
      .index()
      .then((res) => {
        res && setMedias(res.images);
      })
      .catch((e) => {
        e.json().then((err) => console.log(err));
      });

    const iframe = document.querySelector("#iframe");
    iframe.contentDocument.addEventListener("click", (e) => {
      e.preventDefault();
      onClick(e.target);
    });

    return () => {
      iframe.contentDocument.removeEventListener("click", (e) => {
        e.preventDefault();
        onClick(e.target);
      });
    };
  }, [html, baseTag, source]);

  const onClick = (newTarget) => {
    if (!newTarget) return;

    setPreviousChild(undefined);
    setTimeout(function () {
      newTarget.focus();
    }, 0);

    const preFrameBorder = document.getElementById("frameBorder");
    preFrameBorder && preFrameBorder.remove();

    const iframe = document.getElementById("iframe");
    const clientRect = newTarget.getBoundingClientRect();
    const iframeRect = iframe.getBoundingClientRect();

    const left = clientRect.left + iframeRect.left;
    const iframeTopOffset = iframeRect.top - 150;
    const adjustedTop = clientRect.top + 120;
    const top = adjustedTop < iframeTopOffset ? iframeTopOffset : adjustedTop;

    const targetSrc =
      newTarget.tagName === "IMG" ? newTarget.getAttribute("src") : "";
    setActionBoxParams({
      ...actionBoxParams,
      top,
      left,
      width: newTarget.clientWidth,
      height: newTarget.clientHeight,
      tagName: newTarget.tagName.toLowerCase(),
      className: newTarget.className,
      id: newTarget.id,
      background: newTarget.style.background || "",
      backgroundColor: newTarget.style.backgroundColor || "",
      backgroundImage: newTarget.style.backgroundImage || "",
      backgroundSize: newTarget.style.backgroundSize || "",
      backgroundPosition: newTarget.style.backgroundPosition || "",
      src: targetSrc,
    });
    !actionBox && setActionBox(true);

    if (target !== "") target.contentEditable = false;
    newTarget.contentEditable = true;

    setTarget(newTarget);
  };

  const detailEdit = () => {
    setText(target.innerHTML);
    target.tagName === "A" && setHref(target.href);

    showActionModal(true);
    setTarget(target);
    setOuterHTML(target.outerHTML);
  };

  const update = () => {
    target.removeAttribute("contenteditable");
    const dom = fetchDom();
    const editedHTML = dom.replace(baseTag, "");
    submit({ isWysiwyg: true, editedHTML });
  };

  const replaceElement = (source, newType) => {
    const frag = document.createDocumentFragment();
    while (source.firstChild) {
      frag.appendChild(source.firstChild);
    }
    const newElem = document.createElement(newType);
    newElem.appendChild(frag);
    source.parentNode.replaceChild(newElem, source);
  };

  const setParams = (type) => {
    switch (type) {
      case "tagName":
        const newTagName = actionBoxParams[type].toLowerCase();
        replaceElement(target, newTagName);
        break;
      case "backgroundColor":
      case "backgroundImage":
      case "backgroundSize":
      case "backgroundPosition":
      case "background":
        target.style[type] = actionBoxParams[type];
        break;
      default:
        target[type] = actionBoxParams[type];
    }
  };

  const cloneElement = () => {
    const clone = target.cloneNode(true);
    target.parentNode.insertBefore(clone, target);
  };

  const moveFocus = (toParent) => {
    if (toParent) {
      onClick(target.parentNode);
      setPreviousChild(target);
    } else {
      onClick(previousChild || target.children[0]);
    }
  };

  return (
    <div>
      <WysiwygActionModal
        show={actionModal}
        close={() => showActionModal(false)}
        target={target}
        showImagesModal={showImagesModal}
        text={text}
        setText={(value) => setText(value)}
        replaceText={() => replaceText(target, text)}
        replaceOuterHTML={() => {
          replaceOuterHTML(target, outerHTML);
        }}
        removeDOM={removeDOM}
        href={href}
        setHref={(e) => setHref(e.target.value)}
        editAnchor={() => editAnchor(target, href)}
        outerHTML={outerHTML}
        setOuterHTML={setOuterHTML}
      />
      <WysiwygReplaceImageModal
        show={imagesModal}
        close={() => {
          showImagesModal(false);
        }}
        medias={medias}
        src={src}
        setSrc={setSrc}
        replaceImage={(src) => replaceImage(target, src)}
      />
      <Typography margin="0 0 30px">
        見たまま編集では公開されているサイトを確認しながらテキスト直接編集、画像の差し替え（メディアにアップロードされているもののみ）、リンク先URLの編集を行うことができます。
      </Typography>
      <WysiwygActionButtonRow
        update={update}
        handleTitleChange={handleTitleChange}
        title={title}
      />
      <WysiwygIframe html={html} />
      {actionBox && (
        <WysiwygActionBox
          actionActiveNumber={actionActiveNumber}
          setActionActiveNumber={setActionActiveNumber}
          actionBoxParams={actionBoxParams}
          setActionBoxParams={setActionBoxParams}
          setParams={setParams}
          cloneElement={cloneElement}
          detailEdit={detailEdit}
          target={target}
          moveFocus={moveFocus}
          undo={undo}
          redo={redo}
        />
      )}
    </div>
  );
};
