import React from "react";

import FormIndex from "../templates/FormIndex";
import api from "../../api";

export default class extends React.Component {
  state = { forms: [], loading: true, dom: "" };

  currentDom = forms => {
    const { state } = this.props.location;
    if (state && state.fromDetailPage) {
      const currentForm = forms.reduce(
        (pre, cur) => (pre.id < cur.id ? cur : pre),
        { id: 0 }
      );
      const form = forms.find(f => f.id === (state.formId || currentForm.id));
      return form.dom;
    }
  };

  fetch = async () => {
    const forms = await api.contact.forms.index().catch(e => console.log(e));
    console.log(forms);

    this.setState({
      forms: forms && forms.forms,
      loading: false,
      dom: this.currentDom(forms && forms.forms)
    });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    return <FormIndex {...this.state} />;
  }
}
