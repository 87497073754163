import React from "react";
import { withRouter } from "react-router-dom";

import Wrap from "../atoms/Wrap";
import Button from "../atoms/Button";
import { Notice } from "../atoms/Notice";
import Navigation from "../molecules/Navigation";
import SourceCodeEditor from "../organisms/SourceCodeEditor";
import SourceWysiwygEditor from "../organisms/SourceWysiwygEditor";

const SourceEdit = ({
  errorMessage,
  isHTML,
  isLoading,
  source,
  history,
  submit,
  fileName,
  handleTitleChange,
  ...props
}) => {
  const CodeEditor = (
    <SourceCodeEditor
      isLoading={isLoading}
      source={source}
      fileName={fileName}
      history={history}
      submit={submit}
      handleTitleChange={handleTitleChange}
      {...props}
    />
  );

  return (
    <>
      {errorMessage && <Notice color="red">{errorMessage}</Notice>}
      <Wrap padding="20px">
        <Wrap justifyContent="space-between" margin="0 0 15px">
          <Button size="small" onClick={() => history.goBack()}>
            一覧へ
          </Button>
        </Wrap>
        {isHTML ? (
          <Navigation
            navs={[
              {
                label: "見たまま編集",
                component: (
                  <SourceWysiwygEditor
                    isLoading={isLoading}
                    source={source}
                    history={history}
                    submit={submit}
                    handleTitleChange={handleTitleChange}
                    {...props}
                  />
                )
              },
              {
                label: "ソースファイル編集",
                component: CodeEditor
              }
            ]}
          />
        ) : (
          CodeEditor
        )}
      </Wrap>
    </>
  );
};

export default withRouter(SourceEdit);
