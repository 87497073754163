export const awsState = {
  // store作成時
  store_created: 0,
  // ドメイン取得時
  domain_contacted: 1,
  // ドメイン承認後のリクエスト
  domain_verified: 2,
  // ssl証明書発行時
  ssl_requested: 3,
  // ssl検証のためのレコード追加
  verification_dns_inserted: 4,
  // 証明書認証後
  ssl_verified: 5,
  // distribution作成時
  distribution_created: 6,
  // ルーティング定義時
  setup_completed: 7
};

// 0: ドメインが未取得
// 1...7: サイト準備中

export const awsStatus = {
  noGetDomain: [0],
  ready: [1, 2, 3, 4, 5, 6],
  open: [7]
};

export const domainRegistered = state => {
  return state !== "store_created"
}

export const externalAwsStatusMessages = state => {
  switch(awsState[state]) {
    case 0:
      return {
        certificate: "ドメインが登録されていません",
        publicate: "ドメインが登録されていません"
      };
    case 1:
    case 2:
      return {
        certificate: "ドメインを検証中です．しばらくお待ちください（15分~1時間ほど）．",
        publicate: "ドメインを検証中です．しばらくお待ちください（15分~1時間ほど）．"
      };
    case 3:
      return {
        certificate: "ssl証明書を発行中です．しばらくお待ちください（15分~1時間ほど）．",
        publicate: "ssl証明書を発行中です．しばらくお待ちください（15分~1時間ほど）．"
      };
    case 4:
      return {
        certificate: "ご利用のレジストラでCNAMEレコードを追加してください",
        publicate: "ホームページ用のドメインを検証中です．しばらくお待ちください（最大で24時間ほどかかります）．",
      };
    case 5:
      return {
        certificate: "認証済み",
        publicate: "ホームページ用のドメインを検証中です．しばらくお待ちください（最大で24時間ほどかかります）．"
      };
    case 6:
      return {
        certificate: "認証済み",
        publicate: "ご利用のレジストラでCNAMEレコードを追加してください"
      }
    case 7:
      return {
        certificate: "認証済み",
        publicate: "認証済み"
      }
    default:
      throw Error("Invalid Aws State Error")
  }
}
